import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const LinearBuffer = ({ color_prog, prog }) => {
    let theme = createTheme({
        // Theme customization goes here as usual, including tonalOffset and/or
        // contrastThreshold as the augmentColor() function relies on these
      });
      
      theme = createTheme(theme, {
        // Custom colors created with augmentColor go here
        palette: {
          irvs: theme.palette.augmentColor({
            color: {
              main: color_prog,
            },
            name: 'irvs',
          }),
        },
      });

    return (
        <ThemeProvider theme={theme}>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
            <Box sx={{ width: '100%', paddingTop: 0.6 }}>
                <LinearProgress color='irvs' variant='determinate' value={prog['completition']*100} sx={{ height: 15, borderRadius: 5 }} />
            </Box>
            <Box paddingLeft='2%' width={isMobile ? '95%' : '35%'} textAlign={isMobile ? 'center' : 'left'}>
                <Typography variant="body1" color="text.secondary">{`${Math.round(prog['completition']*100,)}%`} {`(Eta: ${prog['eta']})`}</Typography>
            </Box>
        </Box>
        </ThemeProvider>
    );
}

export default LinearBuffer