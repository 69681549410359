import { createSvgIcon } from "@mui/material"


const DashboardIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M17.86,3.42a3.41,3.41,0,0,0-3.78-1.67h0A3.35,3.35,0,0,0,12,3.29,3.4,3.4,0,0,0,13.1,8a3.45,3.45,0,0,0,1.78.5,3.13,3.13,0,0,0,.8-.1A3.38,3.38,0,0,0,17.8,6.85a3.48,3.48,0,0,0,.47-1.46A15.52,15.52,0,1,1,7.43,11.67a1,1,0,0,0-.22-1.39,1,1,0,0,0-1.4.22A17.56,17.56,0,1,0,17.86,3.42ZM16.09,5.8a1.42,1.42,0,0,1-1.95.47,1.41,1.41,0,0,1-.46-1.94,1.39,1.39,0,0,1,.87-.64,1.43,1.43,0,0,1,1.71,1.05A1.37,1.37,0,0,1,16.09,5.8Z"/><path d="M20,31.87h.11a11.23,11.23,0,0,0,0-22.45H20a11.22,11.22,0,0,0,0,22.44Zm1.11-2.05V24.07h7.56A9.18,9.18,0,0,1,21.1,29.82Zm8.22-9.17a8.69,8.69,0,0,1-.12,1.42H21.1V11.48A9.24,9.24,0,0,1,29.32,20.65ZM19,11.49v5.75H11.53A9.21,9.21,0,0,1,19,11.49Zm-8,7.75h8V29.81a9.25,9.25,0,0,1-8.15-9.16A8.57,8.57,0,0,1,11,19.24Z"/>
    </svg>,
    'DashboardIcon'
)

export default DashboardIcon