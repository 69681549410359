import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { useTranslation, Trans } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const ChartGrouped = ({ data, stats }) => {
  const { t, i18n } = useTranslation(['translation']);

  return (
      <Box sx={{ width: '100%', height: '100%', padding: '5px 5px 30px 5px' }}>
          <Typography align='center' variant="h6">{t('Recommendations By Algorithm')}</Typography>
          <Bar options={options} data={data} />
      </Box>
    );
  }

export default ChartGrouped