import { createSvgIcon } from "@mui/material"


const StatsIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M34.53,10.2a3.29,3.29,0,0,0,.59-1.64,3.43,3.43,0,0,0-.81-2.49h0A3.43,3.43,0,1,0,33,11.46a15.53,15.53,0,1,1-10.52-6.8,1,1,0,0,0,.31-2A17.56,17.56,0,1,0,34.53,10.2Zm-3.89-1a1.43,1.43,0,0,1,.16-2,1.41,1.41,0,0,1,.92-.34h.11a1.35,1.35,0,0,1,1,.49h0a1.37,1.37,0,0,1,.34,1,1.45,1.45,0,0,1-.49,1A1.42,1.42,0,0,1,30.64,9.22Z"/>
        <path d="M21.44,19l-2.83-.44a2.92,2.92,0,0,0-.23-.58,2.49,2.49,0,0,0-4.57.55A2.45,2.45,0,0,0,14,20.4a1.31,1.31,0,0,0,.19.27l-1.11,2a2.5,2.5,0,0,0-2.65,1.81,2.54,2.54,0,0,0,.23,1.89,2.49,2.49,0,0,0,1.5,1.18,2.65,2.65,0,0,0,.68.09,2.53,2.53,0,0,0,1.22-.32,2.49,2.49,0,0,0,1.18-1.5,2.57,2.57,0,0,0-.23-1.9l-.19-.27,1.12-2a1.6,1.6,0,0,0,.23,0,2.53,2.53,0,0,0,1.22-.32,2.47,2.47,0,0,0,.87-.83l2.83.44a2.19,2.19,0,0,0,.23.58,2.49,2.49,0,0,0,1.5,1.18,2.57,2.57,0,0,0,.67.09A2.5,2.5,0,0,0,25.93,21a2.45,2.45,0,0,0-.23-1.89l1.09-1.43a2.8,2.8,0,0,0,.57.08,2.4,2.4,0,0,0,1.22-.33,2.48,2.48,0,0,0,1-3.39A2.49,2.49,0,0,0,28,12.85a2.45,2.45,0,0,0-1.89.23A2.49,2.49,0,0,0,25,14.58a2.45,2.45,0,0,0,.23,1.89L24.1,17.9a2.43,2.43,0,0,0-1.79.24A2.59,2.59,0,0,0,21.44,19Zm-8.09,6.28A.49.49,0,0,1,12.4,25,.49.49,0,0,1,13.35,25.26Zm3.33-6a.49.49,0,0,1-.95-.26A.49.49,0,0,1,16.68,19.31Zm10.21-4.19a.49.49,0,0,1,.94.26A.49.49,0,0,1,26.89,15.12Zm-3.83,5.06a.49.49,0,0,1,.94.27A.49.49,0,0,1,23.06,20.18Z"/>
    </svg>,
    'StatsIcon'
)

export default StatsIcon