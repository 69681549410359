import { Add } from '@mui/icons-material';
import { Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box, Container } from '@mui/system'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchMembers } from '../utilis/api';
import Header from './Header'
import TeamMembersTableBody from './TeamMembersTableBody';
import AccountIcon from '../components/icons/account';

import { useTranslation, Trans } from 'react-i18next';

const TeamMembers = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [members, setMembers] = useState([]);
    const { currentUser } = useSelector((state) => state.user);

    const removeMember = (targetId) => {
        setMembers(members.filter(member => member._id !== targetId));
    }

    useEffect(() => {
        const fetchMem = () => {
            fetchMembers(currentUser)
                .then((res) => {
                    setMembers(res.data);
                    console.log(res.data);
                })
        }

        fetchMem();
    }, [currentUser])

    var i = 0;

    return (
        <>
            <Box flex={4} p={{ xs: 0 }}>
                <Header title={t('Team members')} IconComponent={AccountIcon}/>
                <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="members table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Name')}</TableCell>
                                    <TableCell>{t('Surname')}</TableCell>
                                    <TableCell>{t('E-mail')}</TableCell>
                                    <TableCell align="center">{t('Websites & Auths')}</TableCell>
                                    <TableCell align="right">{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    members.length > 0
                                        ?
                                        members.map((member) => (
                                            <TeamMembersTableBody 
                                                row={member}
                                                removeMember={removeMember}
                                                key={i++}
                                            />
                                        ))
                                        :
                                        <Box 
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            marginTop={2}
                                            marginBottom={2}
                                        >
                                            <Typography variant="h6">
                                                {t('No team members')}
                                            </Typography>
                                        </Box>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
            <Link to='/team/new'>
                <Fab
                    sx={{ position: 'fixed', bottom: 35, right: 55 }}
                    variant="extended"
                >
                    {!isMobile ? <><Add sx={{ mr: 1 }} /> {t('New Member')}</> :
                        <Add />
                    }
                </Fab>
            </Link>
        </>
    )
}

export default TeamMembers