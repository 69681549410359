import styled from '@emotion/styled'
import { Card, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ChartBar from './ChartBar'
import ChartDoughnut from './ChartDoughnut'

import { dataRevenuesChart } from './config/revenues'
import { dataInteractionsChart } from './config/interactions'
import { dataRecommendationsChart } from './config/recommendations'
import { dataSummaryChart } from './config/summary'
import { timeRecommendations } from './config/timeRecommendations'
import ChartLine from './ChartLine'
import ChartGrouped from './ChartGrouped'
import { recbyalgo } from './config/recbyalgo'
import { Responsive, WidthProvider } from 'react-grid-layout'

const ResponsiveGridLayout = WidthProvider(Responsive);

const layout = [
    { i: "1", x: 0, y: 0, w: 1, h: 1 },
    { i: "2", x: 1, y: 0, w: 1, h: 1 },
    { i: "3", x: 2, y: 0, w: 1, h: 1 },
    { i: "4", x: 3, y: 0, w: 1, h: 1 },
    { i: "5", x: 4, y: 0, w: 1, h: 1 },
    { i: "6", x: 5, y: 0, w: 1, h: 1 }
];

const getLayouts = () => {
    const savedLayouts = localStorage.getItem("grid-layout");

    return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};

const ChartBox = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    /*backgroundColor: '#F8F3F3',*/
})

const ChartsDrag = ({ stats }) => {
    const handleLayoutChange = (layout, layouts) => {
        localStorage.setItem("grid-layout", JSON.stringify(layouts));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <ResponsiveGridLayout
                layouts={getLayouts()}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
                rowHeight={600}
                //width={900}
                onLayoutChange={handleLayoutChange}
            >
                <div key="1">
                    <ChartBox>
                        <ChartDoughnut data={dataRevenuesChart(stats)} />
                    </ChartBox>
                </div>
                <div key="2">
                    <ChartBox>
                        <ChartDoughnut data={dataInteractionsChart(stats)} />
                    </ChartBox>
                </div>
                <div key="3">
                    <ChartBox>
                        <ChartBar data={dataRecommendationsChart(stats)} />
                    </ChartBox>
                </div>
                <div key="4">
                    <ChartBox>
                        <ChartBar data={dataSummaryChart(stats)} />
                    </ChartBox>
                </div>
                <div key="5">
                    <ChartBox>
                        <ChartLine data={timeRecommendations(stats)} />
                    </ChartBox>
                </div>
                <div key="6">
                    <ChartBox>
                        <ChartGrouped data={recbyalgo(stats)} />
                    </ChartBox>
                </div>
            </ResponsiveGridLayout>
        </Box>

    )
}

export default ChartsDrag

