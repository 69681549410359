import { Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import TeamMemberForm from './Forms/TeamMemberForm'
import Header from './Header'

import { useTranslation, Trans } from 'react-i18next';

const TeamMemberCreation = () => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Box flex={4} p={{ xs: 0 }}>
            <Header title={t('Team members')} />
            <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item sm={12}>
                        <Typography variant="h4" content="h2">
                            {t('Create new member')}
                        </Typography>
                    </Grid>
                    <TeamMemberForm />
                </Grid>
            </Container>
        </Box>
    )
}

export default TeamMemberCreation