import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Header from '../components/Header'
import StatsIcon from '../components/icons/stats'

import { useTranslation, Trans } from 'react-i18next';

const LoadingStats = () => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Box flex={4} p={{ xs: 0 }}>
            <Header title={t('KPIs')} IconComponent={StatsIcon} />
            
           <Box flex={4} p={5} textAlign="center" paddingTop={15}>
                <Typography variant='h3'>
                    {t('Artificial intelligence is training on your website')}!
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }} paddingTop='5%'>
                    <CircularProgress size={145}/>
                </Box>
                <Typography variant='h6' paddingTop={5}>
                    {t('You will get your analytics tomorrow')}.
                </Typography>
           </Box>
        </Box>
    )
}

export default LoadingStats