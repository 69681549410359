import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

import { useTranslation, Trans } from 'react-i18next';

const formatPermission = (data) => {
    let permission = '';

    Object.entries(data).map((d) => {
        if(d[1]) {
            permission = permission + '1';
        } else {
            permission = permission + '0';
        }
    })

    return permission;
}

const TeamMembersPermissionElement = ({ acl, updatePermission }) => {
    const { t, i18n } = useTranslation(['translation']);

    console.log(acl[1][0]);
    const [check, setCheck] = useState({
        dashboard: acl[1][0] === '1' ? true : false,
        configuration: acl[1][1] === '1' ? true : false,
        settings: acl[1][2] === '1' ? true : false,
        recommendation: acl[1][3] === '1' ? true : false
    });

    const handleChange = (event) => {
        setCheck({
            ...check,
            [event.target.name]: event.target.checked,
        });

        const perm = {
            ...check,
            [event.target.name]: event.target.checked
        }

        updatePermission({ [acl[0]]: formatPermission(perm) })

    };

    var i = 0;

    return (
        <Grid item md={6} lg={6} xl={6}>
            <Grid item xs={12}>
                <Typography variant="h7" marginBottom={5}>
                    <b>{acl[0]}</b> {t('access')}:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormGroup sx={{ marginLeft: '10px' }}>
                    {
                        Object.keys(check).map(key =>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={check[key]}
                                        onChange={handleChange}
                                        name={key}
                                    />
                                }
                                label={key}
                                key={i++}
                            />
                        )
                    }
                </FormGroup>
            </Grid>
        </Grid>
    )
}

export default TeamMembersPermissionElement