export const planIt = {
    standard: {
        title: "Standard",
        monthlyPrice: 19.99,
        yearlyPrice: 199,
        upTo: [
            "Visual Search & Discovery",
            "Raccomandazioni Visuali",
            "Raccomandazioni di Prodotti",
            "Dashboard avanzata",
            "Supporto via ticket",
            "1K prodotti",
            "1M raccomandazioni"
        ],
        productId: {
            monthly: "prod_Hmq0PrX7kJhaJs",
            yearly: "prod_LJtBWPhjbyWbK6"
        },
        priceId: {
            monthly: "price_1M2e5UGyRFo307OA7XZGQgSC",
            // monthly: "price_1HDGNkGyRFo307OA7UuF3sCW",
            yearly: "price_1M2e6eGyRFo307OAESqwvsqB"
        }
    },
    pro: {
        title: "Pro",
        monthlyPrice: 99.99,
        yearlyPrice: 999,
        upTo: [
            "Visual Search & Discovery",
            "Raccomandazioni Visuali",
            "Raccomandazioni di Prodotti",
            "Dashboard avanzata",
            "Supporto via ticket/email",
            "5K prodotti",
            "5M raccomandazioni"
        ],
        productId: {
            monthly: "prod_Hmq1sHv5cBL98f",
            yearly: "prod_LJtCBQMhoFmSuM"
        },
        priceId: {
            monthly: "price_1M2e7tGyRFo307OAVxrTr0Z2",
            yearly: "price_1M2eAsGyRFo307OA3ed6Y6ly"
        }
    },
    corporate: {
        title: "Corporate",
        monthlyPrice: 349.99,
        yearlyPrice: 3499,
        upTo: [
            "Visual Search & Discovery",
            "Raccomandazioni Visuali",
            "Raccomandazioni di Prodotti",
            "Dashboard avanzata",
            "Supporto via ticket/email",
            "20K prodotti",
            "30M raccomandazioni"
        ],
        productId: {
            "monthly": "prod_Hmq2xLMd9nu2D5",
            "yearly": "prod_LJtCHEakBiiRRw"
        },
        priceId: {
            "monthly": "price_1M2e94GyRFo307OARqaLEHsu",
            "yearly": "price_1M2e9mGyRFo307OA2zq4IMt5"
        }
    }
}
