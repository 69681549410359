
export const recbyalgo = (data, textlabels) => {
    let labels = [];

    let userData = [];
    let itemData = [];
    let visualData = [];
    let similarityData = [];
    let popData = [];
  
    data.recommendations.timeseries.forEach((x, i) => 
      labels.push(x.date.split('T')[0])
    );
  
    data.recommendations.timeseries.forEach((x, i) => {
      userData.push(x.users);
      itemData.push(x.items);
      visualData.push(x.visualsearch);
      similarityData.push(x.similarity);
      popData.push(x.pops);
    });

    return {
        labels,
        datasets: [
            {
                label: textlabels.visualsearch,
                data: visualData,
                backgroundColor: 'rgb(250 70 22)',
                stack: 'Stack 0',
            },
            {
                label: textlabels.visualsimilarity,
                data: similarityData,
                backgroundColor: 'rgb(236, 232, 26)',
                stack: 'Stack 0',
            },
            {
                label: textlabels.itembased,
                data: itemData,
                backgroundColor: 'rgb(120, 190, 32)',
                stack: 'Stack 0',
            },
            {
                label: textlabels.collaborativefiltering,
                data: userData,
                backgroundColor: 'rgb(76, 159, 200)',
                stack: 'Stack 0',
            },
            {
                label: textlabels.popularitems,
                data: popData,
                backgroundColor: 'rgb(0, 78, 168)',
                stack: 'Stack 0',
            },
        ],
    }
};