import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import ListKpiConf from './ListKpiConf';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableRegion from './DroppableRegion';

const SidebarKpiConf = ({ open, close }) => {

    return (
        <div>
            <Drawer
                anchor='right'
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "#f3edec",
                    }
                }}
            >
                <ListKpiConf close={close} />
                {/*
                <DragDropContext
                  onDragEnd={(result) => {}}
                >
                    <DroppableRegion close={handleClose}/>
                </DragDropContext>
                */}
            </Drawer>
        </div>
    );
}

export default SidebarKpiConf