import { Cancel, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, IconButton, ListItemButton, ListItemIcon, Avatar, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react'

const ComplementaryTable = ({ state, upState }) => {
  const handleReorder = (index, direction) => {
    let tmp = state.prods

    if (direction) {
      if (index > 0) {
        [tmp[index], tmp[index - 1]] = [tmp[index - 1], tmp[index]]
      }
    } else {
      if (index < (state.prods.length - 1)) {
        [tmp[index], tmp[index + 1]] = [tmp[index + 1], tmp[index]]
      }
    }

    upState({
      prods: tmp
    })
  }

  const handleDelete = (id) => {
    upState({
      prods: state.prods.filter(prod => prod.id !== id)
    })
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {state.prods.map((prod, index) => (
            <TableRow
              key={prod.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <Box sx={{ flexDirection: 'column' }}>
                  <ListItemButton
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    disableRipple
                    onClick={() => handleReorder(index, true)}
                  >
                    <ListItemIcon>
                      <ArrowDropUp />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    disableRipple
                    onClick={() => handleReorder(index, false)}
                  >
                    <ListItemIcon>
                      <ArrowDropDown />
                    </ListItemIcon>
                  </ListItemButton>
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">
                <Grid container>
                  <Grid lg={3} xl={3} md={3}>
                    <Avatar
                      alt=""
                      src={prod.images !== null ? prod.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_-Ss-poGbiYUaFQVFP_0ZB27I2zFkClPuDgh2akMzMg&s'}
                      sx={{ width: 70, height: 70 }}
                    />
                  </Grid>
                  <Grid lg={6} xl={6} md={6} alignItems="center" paddingTop={3}>
                    {prod.name}
                  </Grid>
                  <Grid lg={3} xl={3} md={3} alignItems="center" paddingTop={3}>
                    {prod.price}€
                  </Grid>
                </Grid>

              </TableCell>

              <TableCell align="right">

                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => handleDelete(prod.id)}
                >
                  <Cancel />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ComplementaryTable