import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useTranslation, Trans } from 'react-i18next';

const WebsiteCreateStepper = ({platform}) => {
  const { t, i18n } = useTranslation(['translation']);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = {
    "shopify": [
      {
        label: t('Install the Shopify App'),
        description: t('Install the Visidea Shopify App in your website')+'.<br><br><a href="https://apps.shopify.com/visidea" target="_blank" class="button-steps">'+t('INSTALL APP')+'</a>',
      },
      {
        label: t('Choose your plan'),
        description: t('Choose your Visidea plan inside the Visidea Shopify App')+'.',
      },
      {
        label: t('Integrate'),
        description: t('Click the link in the Visidea Shopify App "If you already have an account you can follow this link to integrate Shopify and Visidea"')+'.',
      },
      {
        label: t('Connect'),
        description: t('Choose the category of your products and click Connect')+'.',
      },
    ],
    "prestashop": [
      {
        label: t('Download'),
        description: t('Download the Visidea Prestashop Module')+'.<br><br><a href="https://cdn.visidea.ai/downloads/prestashop/visidea-1.2.4.zip" target="_blank" class="button-steps">'+t('DOWNLOAD MODULE')+'</a>',
      },
      {
        label: t('Upload'),
        description: t('Upload the module in the Prestashop modules section')+'.',
      },
      {
        label: t('Integrate'),
        description: t('Click the link in the Visidea Prestashop Module "If you already have an account you can follow this link to integrate Prestashop and Visidea"')+'.',
      },
      {
        label: t('Connect'),
        description: t('Choose the category of your products and click Connect')+'.',
      },
    ],
    "magento": [
      {
        label: t('Download'),
        description: t('Buy the free module from the Magento extensions marketplace')+'.<br><br><a href="https://marketplace.magento.com/inferendo-module-visidea.html" target="_blank" class="button-steps">'+t('BUY MODULE')+'</a>',
      },
      {
        label: t('Install'),
        description: t('Install the module following the guide in our documentation website')+'.<br><br><a href="https://docs.visidea.ai/docs/plugins/magento" target="_blank" class="button-steps">'+t('INSTALL MODULE')+'</a>',
      },
      {
        label: t('Integrate'),
        description: t('Click on the link in the Visidea Magento Module "If you already have an account you can follow this link to integrate Magento and Visidea"')+'.',
      },
      {
        label: t('Connect'),
        description: t('Choose the category of your products and click Connect')+'.',
      },
    ],
    "woocommerce": [
      {
        label: t('Install'),
        description: t('Install the Visidea WordPress Plugin in your website')+'.<br><br><a href="https://wordpress.org/plugins/visidea/" target="_blank" class="button-steps">'+t('INSTALL PLUGIN')+'</a>',
      },
      {
        label: t('Integrate'),
        description: t('Click on the link in the Visidea WordPress Plugin "Integrate with Visidea"')+'.',
      },
      {
        label: t('Connect'),
        description: t('Choose the category of your products and click Connect')+'.',
      },
    ],
  };

  return (
    <Box paddingTop={5}>
      <Stepper orientation="vertical">
        {steps[platform].map((step, index) => (
          <Step key={step.label} active={true}>
            <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">{t('Last step')}</Typography>
              //   ) : null
              // }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <div dangerouslySetInnerHTML={{ __html: step.description }} />
              {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={index === steps[platform].length - 1}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps[platform].length - 1 ? t('Finish') : t('Continue')}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t('Back')}
                  </Button>
                </div>
              </Box> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>{t('All steps completed')}</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            {t('Reset')}
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export default WebsiteCreateStepper;