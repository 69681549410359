import { Home, SettingsInputComponent, Web } from '@mui/icons-material'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    List,
    ListItemAvatar,
    Avatar,
    useMediaQuery,
    SvgIcon
} from '@mui/material'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from "react-router-dom";

import { changeCurrentWebsite } from '../../redux/websiteSlice';
import SettingsIcon from '../icons/settings';
import WebsiteIcon from '../icons/website';

import { useTranslation, Trans } from 'react-i18next';

const SetIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.violet
}));

const WebIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.violet
}));

const WebsiteDialogChoose = ({ sites }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleSiteChange = (site) => {
        dispatch(changeCurrentWebsite(site))
        setOpen(false);

        navigate("/", { state: { refresh: true } });

    }

    return (
        <>
        
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(true)}>
                    <SetIcon
                        component={SettingsIcon}
                        viewBox="0 0 100 100"
                        fontSize='large'
                    />
                    <ListItemText
                        primary={t('Select website')}
                        primaryTypographyProps={{ fontSize: "19px", fontWeight: "600", marginLeft: '20px'}}
                    />
                </ListItemButton>
            </ListItem>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='dialog-title'
                aria-describedby='dialog-description'
                fullScreen={fullScreen}
            >
                <DialogTitle id='dialog-title'>{t('Select website')}</DialogTitle>
                <DialogContent>
                    <List sx={{ pt: 0 }}>
                        {sites.map((site) => (
                            <ListItem button onClick={() => handleSiteChange(site)} key={site.id}>
                                <WebIcon
                                    component={WebsiteIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                    style={{ marginRight: "15px" }}
                                />
                                <ListItemText primary={site.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('Close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default WebsiteDialogChoose