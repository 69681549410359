import { SwitchLeft, SwitchRight } from '@mui/icons-material'
import { Card, CircularProgress, Fab, Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import DashboardChart from '../components/charts/DashboardChart'
import AlgoTable from '../components/dashboard/AlgoTable'
import BestProducts from '../components/dashboard/BestProducts'
import ReccNumber from '../components/dashboard/ReccNumber'
import TopCategory from '../components/dashboard/TopCategory'
import Total from '../components/dashboard/Total'
import Users from '../components/dashboard/Users'
import Header from '../components/Header'
import DashboardIcon from '../components/icons/dashboard'
import { fetchStatBestseller, fetchStatWebsite, getInitProgress } from '../utilis/api'

import { useTranslation, Trans } from 'react-i18next';
import LinkWrapper from '../components/LinkWrapper'
import NewWebsiteLoading from '../components/Loading/NewWebsiteLoading'
import { checkModelsStatus } from '../utilis/utils'
import { useLocation } from 'react-router-dom'

const HomeDashboard = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { state, pathname } = useLocation();

  const [period, setPeriod] = useState('day');
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null)

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  console.log(currentWebsite)

  const settings = JSON.parse(currentWebsite.settings);

  if(state && state.refresh) {
    window.history.replaceState({}, '')
    window.location.reload()
  }

  useEffect(() => {
    const fetchStats = () => {
      setLoading(true);
      fetchStatBestseller(currentUser, currentWebsite.id, period)
        .then((res) => {
          setStats(res.data);
          console.log(Object.keys(res.data).length)
          setLoading(false);
        })
    }
    fetchStats();
  }, [currentWebsite, currentUser, period]);

  useEffect(() => {
    getInitProgress(currentWebsite._id, currentUser)
      .then((res) => { setProgress(res.data) })

    const intervalID = setInterval(() => {
      getInitProgress(currentWebsite._id, currentUser)
        .then((res) => { setProgress(res.data) })
    }, 10000);

    return () => clearInterval(intervalID);
  }, []);

  const handlePeriod = () => {
    if (period === 'week')
      setPeriod('day')
    else
      setPeriod('week')
  }

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title='Visidea.ai' IconComponent={DashboardIcon} />
      {
        loading ? <></>
          :
          <Container maxWidth={false}>
            {!checkModelsStatus(progress, currentWebsite) || !stats ?
              <NewWebsiteLoading progress={progress} />
              :
              <Grid
                container
                spacing={5}
                marginTop={3}
              >
                <Grid
                  item
                  xl={3}
                  lg={6}
                  sm={6}
                  md={12}
                  xs={12}
                >
                  <Total period={period} data={stats.revenues} />
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  sm={6}
                  md={12}
                  xs={12}
                >
                  <ReccNumber period={period} data={stats.recommendations} />
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  sm={6}
                  md={12}
                  xs={12}
                >
                  <Users period={period} data={stats.customers} />
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  sm={6}
                  md={12}
                  xs={12}
                >
                  <TopCategory period={period} data={stats.categories} />
                </Grid>
                <Grid
                  item
                  lg={1.5}
                  md={0}
                  xl={1.5}
                  xs={0}
                >
                </Grid>
                <Grid
                  item
                  lg={9}
                  md={10}
                  xl={9}
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  marginBottom={1}
                  marginTop={1}
                >
                  <Card>
                    <DashboardChart period={period} data={stats.revenues.timeseries} />
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={10}
                  xl={6}
                  xs={12}
                  paddingBottom={3}
                >
                  <AlgoTable settings={settings} visual={currentWebsite.has_visualseach} />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={10}
                  xl={6}
                  xs={12}
                  paddingBottom={5}
                >
                  <BestProducts period={period} data={stats.items} />
                </Grid>
              </Grid>
            }
          </Container>
      }
      {
        !checkModelsStatus(progress, currentWebsite) ?
          <></>
          :
          <Fab sx={{ position: 'fixed', bottom: 35, right: 55 }} variant="extended" onClick={handlePeriod}>
            {
              !isMobile ?
                <>

                  {
                    period === 'week' ?
                      <><SwitchLeft sx={{ mr: 1 }} />{t('week')}</>
                      :
                      <>{t('day')}<SwitchRight sx={{ ml: 1 }} /></>
                  }
                </>
                :
                <>
                  {
                    period ?
                      <SwitchLeft />
                      :
                      <SwitchRight />
                  }
                </>
            }
          </Fab>
      }
    </Box>
  )
}

export default HomeDashboard
