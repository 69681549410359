import React from 'react'
import { Category } from '@mui/icons-material'
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material'

import { useTranslation, Trans } from 'react-i18next';

const TopCategory = ({ period, data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="secondary"
                            gutterBottom
                            variant="overline"
                        >
                            { period === 'week' ? t('CATEGORY OF THE WEEK') : t('CATEGORY OF THE DAY') }
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h6"
                        >
                            {data[0] ? data[0].name : t('no category')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'rgb(250, 70, 22)',
                                height: 40,
                                width: 40
                            }}
                        >
                            <Category />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        pt: 2,
                        paddingBottom: 5
                    }}
                >
                    {/*
                    <Typography
                        variant="body2"
                        color="secondary"
                        sx={{
                            mr: 1
                        }}
                    >
                        T-shirt
                    </Typography>
                    <Typography
                        color='textSecondary'
                        variant="caption"
                    >
                        last { period === 'week' ? "week" : "day" }
                    </Typography>
                    */}
                </Box>
            </CardContent>
        </Card>
    )
}

export default TopCategory