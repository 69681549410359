import { Button, ButtonGroup, Container, Grid, Paper, Typography} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Header from '../components/Header'
import PremiumIcon from '../components/icons/premium'
import Pricing from '../components/Pricing'
import { initStorage } from '../utilis/paymentStorage'
import { planEn } from '../utilis/plansEn'
import { planIt } from '../utilis/plansIt'

import { useTranslation, Trans } from 'react-i18next';
import { format } from 'date-fns'

const parseDate = (date) => {
  date = Date.parse(date.split("T")[0])
  return format(date, 'dd-MM-yyyy')
}

const PremiumPlan = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [period, setPeriod] = useState(true)

  const { currentUser } = useSelector((state) => state.user);

  const handleChangePeriod = (p) => {
    setPeriod(p);
  }

  initStorage();

  let standard = planEn.standard;
  let pro = planEn.pro;
  let corporate = planEn.corporate;
  const lng = localStorage.getItem("lng");
  if (lng.substring(0, 2) === 'it') {
    standard = planIt.standard;
    pro = planIt.pro;
    corporate = planIt.corporate;
  }

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Premium plan')} IconComponent={PremiumIcon} />
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px" }}>
        <Typography variant="h5" paddingBottom={3} align="center">
          {t('Current plan')} <b>{currentUser.plan}</b>, 
          {t('expiration')} <b>{
            currentUser.planEnds ? 
              parseDate(currentUser.planEnds) 
              :  
              parseDate(currentUser.trialEnds) 
          }</b>
        </Typography>
        <Grid container spacing={{xs: 0, md: 3, lg:6}} direction='row' sx={{marginBottom: "75px" }}>
          <Grid item sm={4} />
          <Grid item xs={12} sm={4}>
            <ButtonGroup size="large">
              <Button onClick={() => handleChangePeriod(true)}>{t('MONTHLY')}</Button>
              <Button onClick={() => handleChangePeriod(false)}>{t('YEARLY')}</Button>
            </ButtonGroup>
          </Grid>
          <Grid item sm={4} />
        </Grid>
        <Grid container spacing={{xs: 0,md: 3, lg:6}} direction='row' sx={{ marginBottom: "50px" }}>
          <Grid item sm={4} xs={12}>
            <Paper>
              <Pricing plan={standard} period={period} />
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper>
              <Pricing plan={pro} period={period} />
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper>
              <Pricing plan={corporate} period={period} />
            </Paper>
          </Grid>
        </Grid>
        { /*
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginTop: '10px' }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Link to='/checkout' state={{ price: plan.price }}>
            <Button>
              Next
            </Button>
          </Link>
        </Box>
        */}
      </Container>
    </Box>
  )
}

export default PremiumPlan