import { List, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

const flag = (permission) => {
    let bool = [];

    permission.split('').map((p) => {
        if(p === '1') {
            bool.push(true);
        } else {
            bool.push(false);
        }
    })

    return bool;
}

const SubList = ({ closeMenu, permission, user, website }) => {
    const { t, i18n } = useTranslation(['translation']);

    const access = user.plan !== 'team' ? flag('1111') : flag(JSON.parse(permission)[website.name]);

    console.log(access)

    return (
        <List component="div" disablePadding>
            <Link 
                to={access[0] ? '/website/kpi' : '#'}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                    <ListItemButton 
                        onClick={closeMenu} 
                        disabled={access[0] ? false : true}
                    >
                        <ListItemText
                            primary={t('KPIs')}
                            primaryTypographyProps={{ fontSize: "15px", fontWeight: "500" }}
                        />
                    </ListItemButton>
            </Link>
            <Link 
                to={access[1] ? '/website/configurations' : '#'}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                    <ListItemButton 
                        onClick={closeMenu}
                        disabled={access[1] ? false : true}
                    >
                        <ListItemText
                            primary={t('Configuration')}
                            primaryTypographyProps={{ fontSize: "15px", fontWeight: "500" }}
                        />
                    </ListItemButton>
            </Link>
            <Link 
                to={access[2] ? '/website/settings' : '#'}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                    <ListItemButton 
                        onClick={closeMenu}
                        disabled={access[2] ? false : true}
                    >
                        <ListItemText
                            primary={t('Settings')}
                            primaryTypographyProps={{ fontSize: "15px", fontWeight: "500" }}
                        />
                    </ListItemButton>
            </Link>
             
            <Link  
                to={access[3] ? '/website/custom' : '#'}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                    <ListItemButton 
                        onClick={closeMenu}
                        disabled={access[3] ? false : true}
                    >
                        <ListItemText
                            primary={t('Custom recommendations')}
                            primaryTypographyProps={{ fontSize: "15px", fontWeight: "500" }}
                        />
                    </ListItemButton>
            </Link>
            
            <Link  
                to={access[3] ? '/website/vs-photos' : '#'}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                    <ListItemButton 
                        onClick={closeMenu}
                        disabled={access[3] ? false : true}
                    >
                        <ListItemText
                            primary={t('Visual search photos')}
                            primaryTypographyProps={{ fontSize: "15px", fontWeight: "500" }}
                        />
                    </ListItemButton>
            </Link>
        </List>
    )
}

export default SubList