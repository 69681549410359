import styled from '@emotion/styled'
import { ExpandLess, ExpandMore, Home, Inbox } from '@mui/icons-material'
import { CardMedia, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Collapse, SvgIcon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DashboardIcon from '../icons/dashboard'
import SubList from './SubList'
import { fetchWebsites, fetchWebsitesFirst } from '../../utilis/api';
import WebsiteDialogChoose from './WebsiteDialogChoose';
import WebsiteIcon from '../icons/website'
import AddIcon from '../icons/add'

import { useTranslation, Trans } from 'react-i18next';

const UpperBox = styled(Box)({
    position: 'absolute',
    width: '100%',
    bottom: '305px',
    top: "5px",
    overflow: 'auto',
    fontWeight: "bold",
})

const DashIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.lightgreen
}));

const WebIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.darkgreen
}));

const AdIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.blue
}));

const Upper = ({ closeMenu, theme }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [open, setOpen] = useState(true);
    const [sites, setSites] = useState([]);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const dispatch = useDispatch();

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchSites = () => {
            if (currentWebsite) {
                fetchWebsites(currentUser)
                    .then((res) => {
                        setSites(res.data);
                    })
            } else {
                fetchWebsitesFirst(currentUser, dispatch)
                    .then((res) => {
                        setSites(res.data);
                    })
            }
        };
        fetchSites();
    }, [currentWebsite, currentUser, dispatch]);

    return (
        <UpperBox>
            <Link to="/" style={{ textDecoration: "none", color: "inherit", width: '100%' }}>
                <CardMedia
                    component="img"
                    width="100%"
                    image="../../img/logo_positive.png"
                    alt="visidea"
                />
            </Link>
            <Divider />
            <List style={{ padding: "0" }}>
                <ListItem disablePadding>
                    <Link to="/" style={{ textDecoration: "none", color: "inherit", width: '100%' }}>
                        <ListItemButton onClick={closeMenu}>
                            <ListItemIcon>
                                <DashIcon
                                    component={DashboardIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Dashboard')}
                                primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                            />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItemButton onClick={handleClick} disabled={!currentWebsite}>
                    <ListItemIcon>
                        <WebIcon
                            component={WebsiteIcon}
                            viewBox="0 0 100 100"
                            fontSize='large'
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={currentWebsite ? currentWebsite.name : t('no websites')}
                        primaryTypographyProps={{ fontSize: "19px", fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
                    />
                    {currentWebsite?
                        <>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>
                    :
                        <></>
                    }
                </ListItemButton>
                {
                    currentWebsite
                        ?
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <SubList 
                                closeMenu={closeMenu} 
                                permission={currentUser.acl}
                                user={currentUser} 
                                website={currentWebsite}
                            />
                        </Collapse>
                        : 
                        <></>
                }
                <Divider />
                <WebsiteDialogChoose sites={sites} />
                <ListItem disablePadding>
                    <Link 
                        to={ currentUser.plan !== 'team' ? "/newWebsite" : '#'} 
                        style={{ textDecoration: "none", color: "inherit", width: '100%' }}
                    >
                        <ListItemButton 
                            onClick={closeMenu}
                            disabled={currentUser.plan !== 'team' ? false : true}
                        >
                            <ListItemIcon>
                                <AdIcon
                                    component={AddIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('New Website')}
                                primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                            />
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
            <Divider />
        </UpperBox>
    )
}

export default Upper