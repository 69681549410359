import styled from '@emotion/styled'
import { Save } from '@mui/icons-material'
import { Alert, Button, Grid, Snackbar } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { updateUserInformations } from '../../utilis/api'
import sha512 from '../../utilis/sha512'

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
    background: "linear-gradient(45deg, #C10016 30%, #004EA8 90%)"
}));

const ProfileChangePassword = ({ user }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [open, setOpen] = useState(false);
    const [blockSub, setBlockSub] = useState(true);
    const [creation, setCreation] = useState(false);

    const handleClose = () => setOpen(false);

    const handleCreation = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCreation(false);
    };

    const dispatch = useDispatch();

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);

        if(values.newPassword === values.confirmPassword) {
            
            const data = {
                password: await sha512(values.newPassword)
            }
    
            updateUserInformations(data, user, dispatch)
                .then((res) => {
                    setOpen(true);
                })
        }

        setSubmitting(false);
    }

    return (
        <>
            <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validate={values => {
                if (values.newPassword === values.confirmPassword) {
                    setBlockSub(false);
                } else {
                    setBlockSub(true);
                }
            }}
            onSubmit={onSubmitHook}
        >
            {({ isSubmitting }) => (
                <Form style={{
                    width: '100%',
                    //maxWidth: maxWidth,
                    margin: '24px 8px 0px 8px'
                }}>


                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="password"
                                name="newPassword"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                id="newPassword"
                                label={t('New Password')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="password"
                                name="confirmPassword"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                id="confirmPassword"
                                label={t('Confirm Password')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Submit
                                type="submit"
                                fullWidth
                                disabled={blockSub}
                                variant="contained"
                                color="primary"
                                style={{color: 'white'}}
                                startIcon={<Save />}
                                onClick={() => setCreation(true)}
                            >
                                {t('CHANGE PASSWORD')}
                            </Submit>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
        <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={creation}
                autoHideDuration={5000}
                onClose={handleCreation}
                message={t('Password updated')}
            >
                <Alert onClose={handleCreation} severity="success" sx={{ width: '100%' }}>
                    {t('Password updated')}
                </Alert>
            </Snackbar>
        </>

    )
}

export default ProfileChangePassword