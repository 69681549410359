import styled from '@emotion/styled'
import { Save } from '@mui/icons-material'
import { Button, Grid } from '@mui/material';
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchWebsites, updateMember } from '../../utilis/api';
import Header from '../Header'
import TeamMembersPermissionElement from './TeamMembersPermissionElement';

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
    background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
}));

const TeamMemberConfigurationPermission = () => {
    const { t, i18n } = useTranslation(['translation']);

    const location = useLocation();
    const { data } = location.state;

    console.log(data);

    const [acls, setAcls] = useState(data['acl'] !== '' ? JSON.parse(data['acl']) : null);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    let navigate = useNavigate();

    const updatePermission = (perm) => {
        let tmp = acls;
        tmp[Object.keys(perm)[0]] = Object.values(perm)[0];

        setAcls(tmp);
    }

    const handleUpdatePermission = () => {
        data['acl'] = JSON.stringify(acls);
        const dataUpdate = data;

        updateMember(data['_id'], dataUpdate, currentUser)
            .then((res) => {
                console.log(res.data);
                navigate('/team');
            })
    }

    useEffect(() => {
        const fetchSites = () => {
            if (currentWebsite) {
                fetchWebsites(currentUser)
                    .then((res) => {
                        if (acls === null) {
                            let aclstate = {}
                            res.data.map((site) => {
                                aclstate = {
                                    ...aclstate,
                                    [site.name]: '0000'
                                }
                            })

                            setAcls(aclstate);
                        }
                    })
            }
        };
        fetchSites();
    }, [currentWebsite, currentUser, acls]);

    return (
        <Box flex={4} p={{ xs: 0 }}>
            <Header title='Websites & Auths' />
            <Container component="section" maxWidth="lg">
                <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                    {
                        acls ?
                            Object.entries(acls).map((acl) =>
                                <TeamMembersPermissionElement
                                    acl={acl}
                                    updatePermission={updatePermission}
                                />
                            )
                            : <></>

                    }
                </Grid>

                <Grid container xs={12} spacing={2} sx={{ marginTop: "10px", marginBottom: "50px" }}>
                    <Grid item xs={12} sm={6}>
                        <Link to='/team'>
                            <Button sx={{ mr: 1 }}>
                                {t('Back')}
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Submit
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            onClick={handleUpdatePermission}
                        >
                            {t('SAVE')}
                        </Submit>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    )
}

export default TeamMemberConfigurationPermission