
import {
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@mui/material';

import { useTranslation, Trans } from 'react-i18next';

import { topProducts } from '../../utilis/utils';

const BestProducts = ({ period, data }) => {
    const { t, i18n } = useTranslation(['translation']);

    let products = topProducts(data, 5);

    return (
        <Card> 
            <CardHeader
                subtitle={`${products.length} ${t('in total')}`}
                title={`${period === 'week' ? t('Products of week') : t('Products of day')} ${t('AI-recommended')}`}
            />
            <List>
                {
                    products.length > 0 ?
                    products.map((product, i) => (
                        <ListItem
                            divider={i < products.length - 1}
                            key={product.item_id}
                        >
                            <ListItemAvatar>
                                <img
                                    alt={product.name}
                                    src={product.image}
                                    style={{
                                        height: 75,
                                        width: 95
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={product.name}
                                sx={{ paddingLeft: '10px', display: { xs: 'none', sm: 'block' }  }}
                            />
                            <ListItemText 
                                style={{display:'flex', justifyContent:'flex-end'}} 
                                secondary={`Total: ${product.rank}€`}
                            />
                          
                        </ListItem>
                    )) :
                    <ListItem> {t('No products currently purchased via AI')} </ListItem>
                }
            </List>
            <Divider />
    
        </Card>
    );
}

export default BestProducts