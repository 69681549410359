import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

const firebaseConfig = {

    apiKey: "AIzaSyAKy3bArziVwtumNpNQSVEzefHxT4JI0go",
  
    authDomain: "visidea-4c421.firebaseapp.com",
  
    projectId: "visidea-4c421",
  
    storageBucket: "visidea-4c421.appspot.com",
  
    messagingSenderId: "60167369857",
  
    appId: "1:60167369857:web:4b4247316cef7979200980",
  
    measurementId: "G-J0N8ETVD5M"
  
  };
  


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const goProvider = new GoogleAuthProvider();
export const fbProvider = new FacebookAuthProvider();
export const apProvider = new OAuthProvider('apple.com');
apProvider.addScope('email');
apProvider.addScope('name');

export default app;