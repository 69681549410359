import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

import Upper from './Menus/Upper';
import Footer from './Menus/Footer';
import { CardMedia, Divider } from '@mui/material';
import styled from '@emotion/styled';

const drawerWidth = 350;

const UpperBox = styled(Box)({
  position: 'absolute',
  width: '100%',
  bottom: '260px',
  top: "5px",
  overflow: 'auto',
  fontWeight: "bold",

})

const Sidebar = ({ plan }) => {

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box>
        <AppBar position="fixed"
          color="primary"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' }, paddingLeft: "15px" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Visidea.ai
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Box
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {
            plan ?
              <>
                <Upper closeMenu={handleDrawerToggle} />
                <Footer closeMenu={handleDrawerToggle} />
              </>
              :
              <>
                <UpperBox>
                  <CardMedia
                    component="img"
                    width="100%"
                    image="../img/logo_positive.png"
                    alt="visidea"
                  />
                </UpperBox>
                <Footer closeMenu={handleDrawerToggle} />
              </>

          }
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {
            plan ?
              <>
                <Upper />
                <Footer />
              </>
              :
              <>
                <UpperBox>
                  <CardMedia
                    component="img"
                    width="100%"
                    image="../img/logo_positive.png"
                    alt="visidea"
                  />
                  <Divider />
                </UpperBox>
                <Footer />
              </>
          }
        </Drawer>
      </Box>

    </>

  );
}

export default Sidebar;
