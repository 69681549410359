import styled from '@emotion/styled'
import { Card, Grid } from '@mui/material'

import React from 'react'
import { useTranslation } from 'react-i18next'
import WidgetInteractionsType from '../widgets/WidgetInteractionsType'
import WidgetRecType from '../widgets/WidgetRecType'
import WidgetWebsiteDatasets from '../widgets/WidgetWebsiteDatasets'
import ChartBar from './ChartBar'
import ChartDoughnut from './ChartDoughnut'
import ChartGrouped from './ChartGrouped'
import ChartLine from './ChartLine'
import { dataInteractionsChart } from './config/interactions'
import { recbyalgo } from './config/recbyalgo'
import { dataRecommendationsChart } from './config/recommendations'
import { dataRevenuesChart } from './config/revenues'
import { dataSummaryChart } from './config/summary'
import { timeRecommendations } from './config/timeRecommendations'

const ChartBox = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    /*backgroundColor: '#F8F3F3',*/
})

const ChartSelection = ({ chartId, stats }) => {
    const { t, i18n } = useTranslation(['translation']);
    console.log(stats.items);
    console.log(stats.users);
    console.log(stats.interactions);

    return (
        <>
            {(() => {
                switch (chartId) {
                    case '1':
                        return (
                            <Grid item xs={12} md={12} lg={12}>
                                <ChartBox>
                                    <WidgetInteractionsType data={stats.interactions} />
                                </ChartBox>
                            </Grid>
                        )
                    case '2':
                        return (
                            <Grid item xs={12} md={12} lg={12}>
                                <ChartBox>
                                    <WidgetRecType data={stats.recommendations} />
                                </ChartBox>
                            </Grid>
                        )
                    case '3':
                        return (
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartDoughnut data={dataRevenuesChart(stats, t('Revenues summary'))} />
                                </ChartBox>
                            </Grid>
                        )
                    case '4':
                        return (
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartDoughnut data={dataInteractionsChart(stats, { views: t('Views'), carts: t('Carts'), purchases: t('Purchases'), aiinteractions: t('AI Interactions') })} />
                                </ChartBox>
                            </Grid>
                        )
                    case '5':
                        return (
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartBar data={dataRecommendationsChart(stats, { visualsearch: t('Visual Search'), visualsimilarity: t('Visual similarity'), itembased: t('Item based'), collaborativefiltering: t('Collaborative filtering'), popularitems: t('Popular items'), recommendationsbyalgorithm: t('Recommendations By Algorithm') })} />
                                </ChartBox>
                            </Grid>
                        )
                    /*
                    case '6':
                        return (
                            
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartDoughnut data={dataInteractionsChart(stats, { views: t('Views'), carts: t('Carts'), purchases: t('Purchases'), aiinteractions: t('AI Interactions') })} />
                                </ChartBox>
                            </Grid>
                        
                        )
                    */
                    case '7':
                        return (
                            <Grid item xs={12} md={12} lg={12}>
                                <ChartBox>
                                    <WidgetWebsiteDatasets data={stats} />
                                </ChartBox>
                            </Grid>
                        )
                    case '8':
                        return (
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartGrouped data={recbyalgo(stats, { visualsearch: t('Visual Search'), visualsimilarity: t('Visual similarity'), itembased: t('Item based'), collaborativefiltering: t('Collaborative filtering'), popularitems: t('Popular items') })} />
                                </ChartBox>
                            </Grid>
                        )
                    case '9':
                        return (
                            <Grid item xs={12} md={8} lg={6}>
                                <ChartBox>
                                    <ChartLine data={timeRecommendations(stats, t('Visidea recommendations'))} />
                                </ChartBox>
                            </Grid>
                        )
                    default:
                        return null
                }
            })()}
        </>
    )
}

export default ChartSelection