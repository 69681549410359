import { Avatar, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchProduct } from '../utilis/api';

const CustomRuleAvatar = ({ prod }) => {
    const [product, setProduct] = useState(null);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);
    
    useEffect(() => {
        const fetchProductCompl = () => {
            fetchProduct(currentWebsite.id, prod, currentUser)
                .then((res) => {
                    setProduct(res.data)
                })
        };
        fetchProductCompl();
    }, [currentUser, currentWebsite, prod]);

    return (

        product ?
            <Tooltip title={product.name}>
                <Avatar 
                    alt={product.name} 
                    src={product.images !== null ? product.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_-Ss-poGbiYUaFQVFP_0ZB27I2zFkClPuDgh2akMzMg&s'}
                    sx={{ width: 55, height: 55 }}
                />
            </Tooltip>
            :
            <Avatar 
                alt='...' 
                src=''
            />
        
    )
}

export default CustomRuleAvatar