import styled from '@emotion/styled'
import { ContentCopy, Save } from '@mui/icons-material'
import { Alert, Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import { createWebsite, validateWebsite } from '../../utilis/api'
import { labelsEn } from '../../utilis/labelsEn'
import { labelsIt } from '../../utilis/labelsIt'

import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

const Submit = styled(Button)(({ theme }) => ({
  // marginTop: '10px',
  // width: isMobile ? "50%" : "25%",
  // left: isMobile ? "50%" : "50%",
  background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
}));

// const CreationPaper = styled(Paper)({
//   marginLeft: '10%',
//   marginTop: '24px',
//   padding: '8px',
//   width: '80%',
//   background: 'rgba(120,190,32,0.2)',
// });

const TokenControl = styled(FormControl)({
  left: '25%',
  width: '50%'
})

const SuccessButton = styled(Button)({
  left: '50%',
  backgroundColor: '#78BE20',
  '&:hover': {
    backgroundColor: '#257226',
  }

})

const WebsiteNewForm = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [errorUrl, setErrorUrl] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [creationAnswer, setCreationAnswer] = useState(undefined)
  const [open, setOpen] = useState(false)
  let navigate = useNavigate();

  let stateLab = {}

  var labels = labelsEn;
  const lng = localStorage.getItem("lng");
  if (lng.substring(0, 2) === 'it')
    labels = labelsIt;

  labels.map((lab) => {
    stateLab[lab] = false;
  })

  const [check, setCheck] = useState(stateLab);

  const getLabels = () => {
    let labels = []
    for (let [key, value] of Object.entries(check)) {
      if (value) {
        labels.push(key);
      }
    }

    return labels;
  }

  const handleChange = (event) => {
    setCheck({
      ...check,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/", { state: { refresh: true } });
  };

  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const onSubmitHook = (values, { setSubmitting }) => {
    setSubmitting(true);
    setErrorUrl(false);
    setErrorName(false)

    let hostname = values.name;
    if (hostname.includes('http'))
      hostname = new URL(values.name).hostname;

    var labelsJson = JSON.stringify(getLabels());
    if (lng.substring(0, 2) === 'it') {
      for (var i = labelsEn.length - 1; i >= 0; i--) {
        labelsJson = labelsJson.replace(labelsIt[i], labelsEn[i]);
      }
    }
    var labelsNew = JSON.parse(labelsJson);

    const data = {
      name: hostname,
      type: 'custom',
      visualsearch_classes: labelsNew,
      interactions_url: values.inters,
      users_url: values.users,
      items_url: values.items
    }

    if (isValidURL(values.name) && isValidURL(values.inters) && isValidURL(values.users) && isValidURL(values.items)) {
      validateWebsite(hostname, currentUser)
        .then((res) => {
          if (res.data.unique) {
            createWebsite(currentUser, data, dispatch)
              .then((res) => {
                setCreationAnswer(res.data)
                setOpen(true)
              });
          } else {
            setErrorName(true)
          }
        })
    } else {
      setErrorUrl(true);
    }

    setSubmitting(false);
  }

  const handleCopy = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      // handle firefox compatibility
      return document.execCommand('copy', true, text);
    }
  }

  const isValidURL = (urlString) => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  return (
    <Box paddingTop={7}>
      <Formik
        enableReinitialize={true}
        initialValues={{ name: '' }}
        onSubmit={onSubmitHook}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Grid container spacing={2} alignItems="stretch">

              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('Connect a new website to Visidea')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h7">
                  {t('Start by inserting your website\'s URL')}:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="name"
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label={t('Website URL')}
                  placeholder="https://www.sitename.domain"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7">
                  {t('Enter the url of your items file')}:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="items"
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="items"
                  label={t('Items file URL')}
                  placeholder="https://www.ecommerce.com/items.csv"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7">
                  {t('Enter the url of your users file')}:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="users"
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="users"
                  label={t('Users file URL')}
                  placeholder="https://www.ecommerce.com/users.csv"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7">
                  {t('Enter the url of your interactions file')}:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="inters"
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="inters"
                  label={t('Interactions file URL')}
                  placeholder="https://www.ecommerce.com/interactions.csv"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" marginBottom={5}>
                  {t('Select one or more label')}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>

                <FormGroup>
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} sm={6} lg={6}>

                      {
                        Object.keys(check).slice(0, Object.keys(check).length / 2).map(key =>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check[key]}
                                onChange={handleChange}
                                name={key}
                                key={key}
                              />
                            }
                            label={key}
                          />
                        )
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>

                      {
                        Object.keys(check).slice(Object.keys(check).length / 2, Object.keys(check).length).map(key =>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check[key]}
                                onChange={handleChange}
                                name={key}
                                key={key}
                              />
                            }
                            label={key}
                          />
                        )
                      }
                    </Grid>

                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Submit
                  type="submit"
                  fullWidth
                  variant="contained"
                  startIcon={<Save />}
                >
                  {t('Connect')}
                </Submit>
              </Grid>

            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <div style={{ visibility: errorUrl ? 'visible' : 'hidden', color: 'red', paddingTop: 10 }}>
                  <Alert severity="error">
                    {errors ? t('Invalid URL') : ""}
                  </Alert>
                </div>
                <div style={{ visibility: errorName ? 'visible' : 'hidden', color: 'red' }}>
                  <Alert severity="error">
                    {errors ? t('There is already a website registered under this name') : ""}
                  </Alert>
                </div>
              </Grid>
            </Grid>

          </Form>
        )}
      </Formik>
      <div>
        {
          creationAnswer ?
            <Dialog open={open} maxWidth={"md"}>
              <Grid container spacing={2} padding={3} sx={{ background: 'rgba(120,190,32,0.2)' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t('Creation successfull')}!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t('Go back to your e-commerce platform, and finish the configuration steps')}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7">
                    {t('You can configure the widgets in the "Configure" panel in the left menu when you complete all the steps in your e-commerce')}.
                  </Typography>
                </Grid>
                <Box textAlign='right' justifyContent='right'>
                  <DialogActions>
                    <Button onClick={handleClose}>{t('Go to the dashboard!')}</Button>
                  </DialogActions>
                </Box>
              </Grid>
            </Dialog>
            :
            <div />
        }

      </div>

    </Box>

  )
}

export default WebsiteNewForm