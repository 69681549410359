import styled from '@emotion/styled'
import { Save } from '@mui/icons-material'
import { Search } from '@mui/icons-material'
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React, { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { addRule, updatesRule } from '../utilis/api'
import ComplementaryTable from './ComplementaryTable'
import ProductCard from './ConfigurationCards/ProductCard'
import Header from './Header'
import SelectComplementaryDialog from './SelectComplementaryDialog'
import SelectProductsDialog from './SelectProductsDialog'

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
    background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
}));

const SelectComplementaryproducts = () => {
    const { t, i18n } = useTranslation(['translation']);

    const location = useLocation();
    const { target } = location.state;
    const { compl } = location.state;
    const { ruleId } = location.state

    const [open, setOpen] = useState(false);
    const [updateMode, setUpdateMode] = useState(target ? true : false);
    const [targetProd, setTargetProd] = useState(target);
    const [complProds, setComplProds] = useState(compl);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    let navigate = useNavigate();

    const initialState = {
        prods: complProds
    };

    const [state, updateState] = useReducer(
        (state, updates) => ({ ...state, ...updates }),
        initialState
    );

    const handleClose = () => {
        setOpen(false);
    }

    const handleTargetSelection = (target) => {
        setTargetProd(target);
        console.log(target);
    }

    const handleComplementarySelection = (compl) => {
        let tmp = complProds;
        compl.map((c) => {
            if (tmp.filter(t => t.id === c.id).length === 0) {
                tmp.push(c);
            }
        })
        setComplProds(tmp);
        console.log(complProds);
    }

    const handleTargetElimination = () => {
        setTargetProd(null);
    }

    const handleSumbitRecommends = () => {
        const item = {
            items: complProds.map((c) => { return c._id })
        }

        const data = {
            item_id: targetProd.id,
            rule: JSON.stringify(item)
        }

        console.log(data);

        addRule(currentWebsite.id, data, currentUser)
            .then((res) => {
                console.log(res);
                navigate('/website/custom');
            })
    }

    const handleSumbitUpdates = () => {
        const item = {
            items: state.prods.map((c) => { return c._id })
        }

        const data = {
            item_id: targetProd.id,
            rule: JSON.stringify(item)
        }

        console.log(data);

        updatesRule(currentWebsite.id, ruleId, data, currentUser)
            .then((res) => {
                console.log(res);
                navigate('/website/custom');
            })
    }

    return (
        <>
            <Box flex={4} p={{ xs: 0 }}>
                <Header title={t('Add recommendations')} />
                <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                    <Stack spacing={2}>
                        <Paper sx={{ padding: "10px 10px 10px 10px" }}>
                            <Typography sx={{ paddingBottom: "10px" }}>
                                {t('Select main product')}
                            </Typography>
                            {targetProd ?
                                <Grid container>
                                    <Grid item lg={6} xl={6}>
                                        <ProductCard cancel={handleTargetElimination} prod={targetProd} />
                                    </Grid>
                                </Grid>
                                :
                                <>
                                    <Typography
                                        color="textSecondary"
                                        variant="caption"
                                        sx={{ paddingBottom: "10px" }}
                                    >
                                        {t('Select the product you want to setup custom recommendations for')} {targetProd ? targetProd.name : ''}
                                    </Typography>
                                    <FormControl fullWidth variant="outlined" sx={{ marginTop: '10px' }}>
                                        <InputLabel>{t('Search')}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-target"
                                            type='text'
                                            value=''
                                            onFocus={() => setOpen(true)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton

                                                        //onClick={handleClickShowPassword}
                                                        //onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t('Search target')}
                                        />
                                    </FormControl>
                                </>

                            }
                        </Paper>
                        <Paper sx={{ padding: "10px 10px 10px 10px" }}>
                            <Typography sx={{ paddingBottom: "10px" }}>
                                {t('Add recommendations')}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                variant="caption"
                                sx={{ paddingBottom: "10px" }}
                            >
                                {t('Select the products to recommend')}
                            </Typography>
                            <FormControl fullWidth variant="outlined" sx={{ marginTop: '10px' }}>
                                <InputLabel>{t('Search')}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-complementary"
                                    type='text'
                                    value=''
                                    disabled={targetProd === null ? true : false}
                                    onClick={() => setOpen(true)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton

                                                //onClick={handleClickShowPassword}
                                                //onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t('Search complementary')}
                                />
                            </FormControl>
                            {
                                complProds.length > 0
                                    ?
                                    <ComplementaryTable
                                        state={state}
                                        upState={updateState}
                                    />
                                    :
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        minHeight="25vh"
                                    >
                                        <Typography>
                                            {t('No recommendations')}
                                        </Typography>
                                    </Box>
                            }
                        </Paper>
                    </Stack>

                    <Grid container spacing={2} sx={{ marginTop: "10px", marginBottom: "50px" }}>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {updateMode ?
                                <Submit
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Save />}
                                    onClick={handleSumbitUpdates}
                                >
                                    {t('update')}
                                </Submit>
                                :
                                <Submit
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Save />}
                                    onClick={handleSumbitRecommends}
                                >
                                    {t('create')}
                                </Submit>
                            }

                        </Grid>
                    </Grid>



                </Container>
            </Box>
            {
                targetProd
                    ?
                    <SelectComplementaryDialog
                        open={open}
                        complSel={handleComplementarySelection}
                        targetId={targetProd.id}
                        handleClose={handleClose}
                    />
                    :
                    <SelectProductsDialog
                        open={open}
                        targetSel={handleTargetSelection}
                        handleClose={handleClose}
                    />
            }
        </>

    )
}

export default SelectComplementaryproducts
