export const dataRevenuesChart = (data, label) => {
  return {
    labels: ['E-commerce', 'Visidea'],
    datasets: [
      {
        label: label,
        data: [
          (data.revenues.total - data.revenues.recommendify), 
          data.revenues.recommendify
        ],
        backgroundColor: [
          'rgba(120, 190, 32, 1)',
          'rgba(76, 159, 200, 1)',
        ],
        borderColor: [
          'rgba(120, 190, 32, 1)',
          'rgba(76, 159, 200, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
};
