import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StepperLoad from './Stepper';

const LinearBufferIrVs = ({ color_prog, prog }) => {
    //const [stats, SetStats] = useState({step: 0, eta: 0, completition: 0})

    let theme = createTheme({
        // Theme customization goes here as usual, including tonalOffset and/or
        // contrastThreshold as the augmentColor() function relies on these
    });

    theme = createTheme(theme, {
        // Custom colors created with augmentColor go here
        palette: {
            irvs: theme.palette.augmentColor({
                color: {
                    main: color_prog,
                },
                name: 'irvs',
            }),
        },
    });

    let step = 1
    let completition = 0
    let eta = 0
    let complete = false

    for (let key in prog) {
        if(prog[key]['completition'] >= 1) {
            if(step < 3) {
                step += 1
            }
        } else {
            completition = prog[key]['completition']
            eta = prog[key]['eta']
            break
        }
    }

    if((step === 3) && (prog['vs']['completition'] === 1)) {
        completition = 1
        eta = 0
        complete = true
    }

    return (
        <ThemeProvider theme={theme}>
            {/* <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <Box sx={{ width: '100%', paddingTop: 0.6 }}>
                    <LinearProgress color='irvs' variant='determinate' value={(33.33*(step))} sx={{ height: 15, borderRadius: 5 }} />
                </Box>
                <Box paddingLeft='2%' width={'35%'} textAlign={'left'}>
                    <Typography variant="body1" color="text.secondary">{`${Math.round(((33.33*(step))),)}%`} </Typography>
                </Box>
            </Box> */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', paddingLeft:{xs: '0', md: '1%'}, paddingRight:{xs: '0', md: '1%'}, paddingTop:'3.5%', paddingBottom:'2%' }}>
                <StepperLoad step={step} complete={complete}/>
            </Box>
            <Typography textAlign={'left'} variant='h6' sx={{ fontSize: 15.5 }}>
                step {step}/3
            </Typography>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                <Box sx={{ width: '100%', paddingTop: 0.6 }}>
                    <LinearProgress color='irvs' variant='determinate' value={completition * 100} sx={{ height: 15, borderRadius: 5 }} />
                </Box>
                <Box paddingLeft='2%' width={isMobile ? '95%' : '35%'} textAlign={isMobile ? 'center' : 'left'}>
                    <Typography variant="body1" color="text.secondary">{`${Math.round(completition * 100,)}%`} {`(Eta: ${eta})`} </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default LinearBufferIrVs