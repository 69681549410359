import { LocalGroceryStore, Psychology, SettingsSuggest, Shop } from '@mui/icons-material'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { numberWithCommas } from '../../utilis/utils'

import { useTranslation, Trans } from 'react-i18next';

const ReccNumber = ({ period, data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Card
            sx={{ height: '100%' }}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="secondary"
                            gutterBottom
                            variant="overline"
                        >
                            { period === 'week' ? t('WEEKLY RECOMMENDS') : t('DAILY RECOMMENDS') }
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h4"
                        >
                            { numberWithCommas(data.total) }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'rgb(120, 190, 32)',
                                height: 40,
                                width: 40
                            }}
                        >
                            <Psychology />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <SettingsSuggest fontSize="small" color="success" sx={{ marginBottom: 1 }}/>
                    <Typography
                        color="success"
                        sx={{
                            mr: 1,
                            ml: 1
                        }}
                        variant="body2"
                    >
                        {(data.total / (period === 'week' ? 7 : 24)).toFixed(0)}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        { period === 'week' ? t('recommends per day') : t('recommends per hour') }
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ReccNumber