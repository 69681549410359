import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentWebsite: null,
    loading: false,
    error: false,
};

export const websiteSlice = createSlice({
    name: "website",
    initialState,
    reducers: {
        fetchStart: (state) => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.currentWebsite = action.payload;
        },
        fetchFailure: (state) => {
            state.loading = false;
            state.error = true;
        },
        changeCurrentWebsite: (state, action) => {
            state.currentWebsite = action.payload;
        },
        clearWebsiteState: (state) => {
            state.currentWebsite = initialState;
        }
    },
});

export const { fetchStart, fetchSuccess, fetchFailure, changeCurrentWebsite, clearWebsiteState } =
    websiteSlice.actions;

export default websiteSlice.reducer;