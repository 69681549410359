import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fecthDispWebsite } from '../../utilis/api';

const AdvancedOptionCard = ({ title, src, desc, css }) => {
  const { t, i18n } = useTranslation(['translation']);

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);
  const dispatch = useDispatch();

  const reloadConfiguration = () => {
    fecthDispWebsite(currentWebsite.id, currentUser, dispatch)
      .then((res) => {})
  }

  return (
    <Card>
      <CardMedia
        component="img"
        src={src}
        alt="adv"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </CardContent>
      <CardActions>
        {
          css ?
            <Button
              size="small"
              component={Link}
              to={`/website/advancedConfigurations/css`}
              onClick={() => reloadConfiguration()}
            >
             setup
            </Button> 
            :
              <Button
                size="small"
                component={Link}
                to={`/website/advancedConfigurations/visual`}
                onClick={() => reloadConfiguration()}
              >{t('setup')}</Button>
        }
             
      </CardActions>
    </Card>
  )
}

export default AdvancedOptionCard