import React from 'react'
import Stepper from '@mui/material/Stepper';
import { Box, Step, StepLabel } from '@mui/material';

const steps = [
  'Download products images',
  'Train AI for image similarity recommendations',
  'Train AI for visual search',
];

const styles = {
  "& .MuiStepLabel-root .Mui-completed": {
      color: "#78BE20"
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
      color: "#78BE20"
  },
  "& .MuiStepLabel-root .Mui-active": {
      color: "#1D252DCC"
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      color: "#1D252DCC"
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: "white"
  }
}

const StepperLoad = ({ step, complete }) => {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={complete ? 3 : step-1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} sx={styles}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default StepperLoad