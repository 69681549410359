import styled from '@emotion/styled';
import { Alert, Button, Grid, Snackbar } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import {TextField} from 'formik-mui';
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import sha512 from '../../utilis/sha512';
import { login } from '../../utilis/api';
import { useDispatch } from 'react-redux';

const Submit = styled(Button)({
    margin: "24px 0px 16px",
    width: '50%',
    left: '50%',
});

const LoginForm = () => {
    const { t, i18n } = useTranslation(['translation']);

    //axios.defaults.headers.post['Content-Type'] = 'application/json';
    const [errors, setErrors] = useState(false);
    const dispatch = useDispatch();
    //const { error } = useSelector((state) => state.user);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setErrors(false);
    };

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);

        const data = {
            ...values,
            password: await sha512(values.password),
        }

        setErrors(await login(data, dispatch));
    
        setSubmitting(false);
    }

    return (
        <Formik
            initialValues={{ username: '', password: '' }}
            validate={values => {
                // Your client-side validation logic
            }}
            onSubmit={onSubmitHook}
        >
            {({ isSubmitting }) => (
                <Form style={{
                    width: '100%',
                    margin: '24px 0 0 0'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                type="email"
                                name="username"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label={t('E-mail Address')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                type="password"
                                name="password"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="password"
                                label={t('Password')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="left" sx={{marginTop: "8px"}}>
                        <Grid item>
                            <Link to="/recover" style={{ color: "inherit" }}>
                                {t('Forgot your password?')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Submit
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            background: "linear-gradient(45deg, #78BE20 30%, #257226 90%)"
                        }}
                    >
                        {t('Sign In')}
                    </Submit>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={errors}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        message={t('Incorrect e-mail or password')}
                    >
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {t('Incorrect e-mail or password')}
                        </Alert>
                    </Snackbar>

                </Form>
            )}
        </Formik>
    )
}

export default LoginForm