import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

import { useTranslation, Trans } from 'react-i18next';

const VisualSearchCard = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Card>
      <CardMedia
        component="img"
        src="https://storage.googleapis.com/twg-content/images/MarketingResources_Thumbnail_Search.width-1200.jpg"
        alt={t('visidea visual search')}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t('General settings')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('Read our guide to learn all about the general settings')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
          size="small" 
          href='https://docs.visidea.ai/docs/app/configuration/#configuration-sections'
          target="_blank"
        >
          {t('Learn More')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default VisualSearchCard