import { Box, Container } from '@mui/system'
import React from 'react'
import Header from '../components/Header'

import HelpIcon  from '../components/icons/help'

import { useTranslation, Trans } from 'react-i18next';

const Error404 = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Page not found')} IconComponent={HelpIcon} />
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        
      </Container>
    </Box>
  )
}

export default Error404