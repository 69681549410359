import { AddAPhoto, Delete, Settings } from '@mui/icons-material'
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchProduct, deleteRule, fetchComplProducts } from '../utilis/api'
import CustomRuleTableAvatar from './CustomRuleTableAvatar'

import { useTranslation, Trans } from 'react-i18next';

const CustomRuleTableBody = ({ rule, handleRule }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [product, setProduct] = useState(null);
    const [compls, setCompls] = useState([])
    const [open, setOpen] = useState(false);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const handleDeleteRule = () => {
        deleteRule(currentWebsite.id, rule._id, currentUser)
            .then(() => {
                setProduct(null);
                setCompls([]);
                handleRule(rule._id);
                setOpen(false);
            })
    }

    useEffect(() => {
        const fetchProductTarget = () => {
            fetchProduct(currentWebsite.id, rule.item_id, currentUser)
                .then((res) => {
                    setProduct(res.data)
                })
        };

        const fetchCompls = () => {
            fetchComplProducts(currentWebsite.id, JSON.parse(rule.rule).items, currentUser)
                .then(ress => {
                    let tmp = [];
                    ress.map(p => tmp.push(p.data));
                    setCompls(tmp);
                })
        }
        
        fetchProductTarget();
        fetchCompls();
    }, [currentUser, currentWebsite, rule]);

    return (
        <>
            <TableBody>
                {
                    product?
                        <TableRow
                            key={product.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3}>
                                        <Avatar
                                            alt=""
                                            src={product.images !== null ? product.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_-Ss-poGbiYUaFQVFP_0ZB27I2zFkClPuDgh2akMzMg&s'}
                                            sx={{ width: 70, height: 70 }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xl={6} md={6} alignItems="center" paddingTop={3}>
                                        {product.name}
                                    </Grid>
                                </Grid>

                            </TableCell>
                            <CustomRuleTableAvatar
                                compl={JSON.parse(rule.rule).items}
                            />
                            <TableCell align="right">
                                <Link
                                    to='/website/custom/selectProducts'
                                    style={{

                                        textDecoration: "none",
                                        color: "inherit"
                                    }}
                                    state={{
                                        target: product,
                                        compl: compls,
                                        ruleId: rule._id
                                    }}
                                >
                                    <Tooltip title={t('Recommendation settings')}>
                                        <IconButton aria-label="settings" size="large">
                                            <Settings />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={t('Delete custom recomendations')}>
                                    <IconButton
                                        aria-label="delete"
                                        size="large"
                                        onClick={() => setOpen(true)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    :
                        <TableRow>
                            <TableCell component="th" scope="row"></TableCell>
                        </TableRow>

                }
            </TableBody>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete this custom rule?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('This personalized recommendation will be removed')}. {t('Proceed?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('Close')}</Button>
                    <Button onClick={() => {handleDeleteRule(); setOpen(false)}}>
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomRuleTableBody