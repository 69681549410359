import styled from '@emotion/styled'
import { Card, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import ChartSelection from './ChartSelection'

const ChartBox = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  /*backgroundColor: '#F8F3F3',*/
})

const Charts = ({ stats }) => {
  const { t, i18n } = useTranslation(['translation']);

  //const charts = JSON.parse(localStorage.getItem('widget'));
  const { currentUser } = useSelector((state) => state.user);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container justifyContent="center" rowSpacing={5} columnSpacing={5} sx={{ padding: '50px 10px' }}>
        {
          JSON.parse(currentUser.dashboardSettings).map((chart) =>
            <ChartSelection chartId={chart.id} stats={stats} />
          )
        }
        {
          /*
              <Grid item xs={12} md={12} lg={12}>
            <ChartBox>
              <WidgetInteractionsType data={stats.interactions} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <ChartBox>
              <WidgetRecType data={stats.recommendations}/>
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartDoughnut data={dataRevenuesChart(stats, t('Revenues summary'))} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartDoughnut data={dataInteractionsChart(stats, { views: t('Views'), carts: t('Carts'), purchases: t('Purchases'), aiinteractions: t('AI Interactions') })} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartBar data={dataRecommendationsChart(stats, { visualsearch: t('Visual Search'), visualsimilarity: t('Visual similarity'), itembased: t('Item based'), collaborativefiltering: t('Collaborative filtering'), popularitems: t('Popular items'), recommendationsbyalgorithm: t('Recommendations By Algorithm') })} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartBar data={dataSummaryChart(stats, { totalitems: t('Total items'), totalusers: t('Total users'), totalinteractions: t('Total Interactions'), websitedatasets: t('Website datasets') })} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartLine data={timeRecommendations(stats, t('Visidea recommendations'))} />
            </ChartBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <ChartBox>
              <ChartGrouped data={recbyalgo(stats, { visualsearch: t('Visual Search'), visualsimilarity: t('Visual similarity'), itembased: t('Item based'), collaborativefiltering: t('Collaborative filtering'), popularitems: t('Popular items') })} />
            </ChartBox>
          </Grid>
          */
        }
      </Grid>
    </Box>

  )
}

export default Charts

