import { createSvgIcon } from "@mui/material"


const ShopIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M36.32,8.48a3.43,3.43,0,0,0-4.7-1.12,3.41,3.41,0,0,0,1.8,6.32,3,3,0,0,0,.73-.09A15.52,15.52,0,0,1,7.45,29.13,15.53,15.53,0,0,1,24.82,5.23a1,1,0,0,0,.62-1.91A17.61,17.61,0,0,0,9.71,5.8,17.55,17.55,0,0,0,30.34,34.19a17.62,17.62,0,0,0,5.55-21.6A3.4,3.4,0,0,0,36.32,8.48ZM32.21,11a1.42,1.42,0,0,1,.46-1.95,1.48,1.48,0,0,1,.74-.21,1.86,1.86,0,0,1,.33,0,1.39,1.39,0,0,1,.87.64A1.41,1.41,0,1,1,32.21,11Z"/>
        <path d="M11.83,12.77v1.58l-1.64,2.94s0,0,0,.06l0,.08a1,1,0,0,0-.07.33h0v0a2.48,2.48,0,0,0,1.76,2.33v7a1,1,0,0,0,1,1H27.2a1,1,0,0,0,1-1v-7A2.49,2.49,0,0,0,30,17.81v0h0a1.3,1.3,0,0,0-.07-.33l0-.08,0-.06L28.2,14.35V12.77a1,1,0,0,0-1-1H12.83A1,1,0,0,0,11.83,12.77Zm4.09,2.84h.5l-.22,1.16h-.71Zm2.53,0H19v1.16h-.78Zm2.57,0h.56l.22,1.16H21Zm2.81,1.16-.21-1.16h.49l.44,1.16ZM13.42,15.61h.37l-.43,1.16h-.59Zm13.26,1.16-.43-1.16h.37l.64,1.16Zm-9.15,9.38h-1v-3.7h1Zm2,0v-4.7a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1v4.7h-.71V19.91h0a1.64,1.64,0,0,0,.22-.14l.06,0,.17.11.15.08a2.47,2.47,0,0,0,1.11.28A2.43,2.43,0,0,0,16.65,20l.15-.08L17,19.8l.05,0,.06,0,.18.11.15.08a2.43,2.43,0,0,0,1.11.28A2.47,2.47,0,0,0,19.64,20l.14-.08L20,19.8l.06,0,.05,0,.18.11.15.08a2.43,2.43,0,0,0,1.11.28A2.47,2.47,0,0,0,22.62,20l.15-.08L23,19.8l.05,0,.06,0,.17.11.15.08a2.47,2.47,0,0,0,1.11.28A2.43,2.43,0,0,0,25.6,20a.71.71,0,0,0,.15-.08l.18-.11.06,0,.21.14h0v6.24Z"/>
        <path d="M24.43,20.45H21.58a1,1,0,0,0-1,1V24.3a1,1,0,0,0,1,1h2.85a1,1,0,0,0,1-1V21.45A1,1,0,0,0,24.43,20.45Zm-1,2.85h-.85v-.85h.85Z"/>
    </svg>,
    'ShopIcon'
)

export default ShopIcon