import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import Error404 from '../../pages/Error404'
import Home from '../../pages/Home'
import LoadingStats from '../../pages/LoadingStats'
import SignIn from '../../pages/SignIn'
import Profile from '../../pages/Profile'
import CustomRule from '../CustomRule'
import SelectComplementaryproducts from '../SelectComplementaryproducts'
import WebsiteAdvancedConfigurations from '../WebsiteAdvancedConfigurations'
import WebsiteConfigurationMenu from '../WebsiteConfigurationMenu'
import WebsiteConfigurations from '../WebsiteConfigurations'
import WebsiteCssConfigurations from '../WebsiteCssConfiguration'
import SettingsWebsite from '../WebsiteSettings'
import Dashboard from '../../pages/Dashboard';

const TeamRoute = ({ loading, permission, website }) => {
    const routeAccess = JSON.parse(permission)[website.name];

    return (
        <Routes>
            <Route path="/">
                <Route index element={<Home />} />
                <Route path='/login' element={<Navigate to="/" />} />
                <Route path='/register' element={<Navigate to="/" />} />
                {
                    loading ?
                        routeAccess[0] === '1' ?
                            <Route path="website/kpi" element={<Dashboard />} />
                            :
                            <Route path="*" element={<Error404 />} />
                        :
                        <Route path="website/kpi" element={<LoadingStats />} />
                }
                {
                    routeAccess[2] === '1' ?
                        <Route path="website/settings" element={<SettingsWebsite />} />
                        :
                        <Route path="*" element={<Error404 />} />
                }
                {
                    routeAccess[1] === '1' ?
                        <>
                            <Route path="website/configurations" element={<WebsiteConfigurationMenu />} />
                            <Route path="website/configurations/:name" element={<WebsiteConfigurations />} />
                            <Route path="website/advancedConfigurations/visual" element={<WebsiteAdvancedConfigurations />} />
                            <Route path="website/advancedConfigurations/css" element={<WebsiteCssConfigurations />} />
                        </>
                        :
                        <Route path="*" element={<Error404 />} />
                }
                {
                    routeAccess[3] === '1' ?
                        <>
                            <Route path="website/custom" element={<CustomRule />} />
                            <Route path="website/custom/selectProducts" element={<SelectComplementaryproducts />} />
                        </>
                        :
                        <Route path="*" element={<Error404 />} />
                }
                <Route path="account" element={<Profile />} />
                <Route path="signin" element={<SignIn />} />
                <Route path="*" element={<Error404 />} />
            </Route>
        </Routes>
    )
}

export default TeamRoute