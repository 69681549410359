import { Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { Fragment, useState } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useSelector } from 'react-redux';
import { updateUserInformations } from '../../utilis/api';
import { getPaymentData, setPaymentField, updatePaymentData } from '../../utilis/paymentStorage';

import { useTranslation, Trans } from 'react-i18next';

const ue_countries = [
    "AT",
	"BE",
	"BG",
	"HR",
	"CY",
	"CZ",
	"DK",
	"EE",
	"FI",
	"FR",
	"DE",
	"GR",
	"HU",
	"IE",
	"IT",
	"LV",
	"LT",
	"LU",
	"MT",
	"NL",
	"PL",
	"PT",
	"RO",
	"SK",
	"SI",
	"ES",
	"SE",
	"RD"
]

const PaymentDataForm = (props) => {
    const { t, i18n } = useTranslation(['translation']);

    const paymentData = getPaymentData();

    const [isCompany, setIsCompany] = useState(paymentData['iscompany'])
    const [country, setCountry] = useState(paymentData['country']);

    const { currentUser } = useSelector((state) => state.user);
    localStorage.setItem('priceId', JSON.stringify(props.priceId));
    localStorage.setItem('planPrice', JSON.stringify(props.planPrice));

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);

        let data = {
            ...values,
            iscompany: isCompany,
            country: country.toUpperCase()
        }

        updatePaymentData(data);

        data = {
            ...data,
            username: props.email,
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
        }

        updateUserInformations(data, currentUser)
            .then(() => {
                document.formSubmitStripe.submit();
                setSubmitting(false);
            })
        /*
        let data = {};
        Object.keys(values).map((val) => {
          if (values[val]) data[val] = values[val];
        });
        console.log(data);
        setSubmitting(true);
        await onUserDataUpdateHook(data)
          .then((response) => {
            // user payment data are updated, submit to stripe
            document.formSubmitStripe.submit();
            setSubmitting(false);
            props.triggerSubmit()
          })
          .catch((error) => {
            // error in setting users payment data, show error and do nothing
            console.log(error);
            setSubmitting(false);
          });
          */
    };

    const onChangeCountry = (c) => {
        setCountry(c);
        setPaymentField('country', c);
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    country: paymentData['country'] !== "" ?
                        paymentData['country'] : currentUser.country,
                    state: paymentData['state'] !== "" ?
                        paymentData['state'] : currentUser.state,
                    city: paymentData['city'] !== "" ?
                        paymentData['city'] : currentUser.city,
                    zip: paymentData['zip'] !== "" ?
                        paymentData['zip'] : currentUser.zip,
                    companyname: paymentData['companyname'] !== "" ?
                        paymentData['companyname'] : currentUser.companyname,
                    vatid: paymentData['vatid'] !== "" ?
                        paymentData['vatid'] : currentUser.vatid,
                    fiscalcode: paymentData['fiscalcode'] !== "" ?
                        paymentData['fiscalcode'] : currentUser.fiscalcode,
                    sdi: paymentData['sdi'] !== "" ?
                        paymentData['sdi'] : currentUser.sdi,
                    cemail: paymentData['cemail'] !== "" ?
                        paymentData['cemail'] : currentUser.cemail,
                    phone: paymentData['phone'] !== "" ?
                        paymentData['phone'] : currentUser.phone,
                    address1: paymentData['address1'] !== "" ?
                        paymentData['address1'] : currentUser.address1,
                    address2: paymentData['address2'] !== "" ?
                        paymentData['address2'] : currentUser.address2
                }}
                onSubmit={onSubmitHook}

            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t('Are you a private or a company')}?</FormLabel>
                                    <RadioGroup
                                        aria-label={t('Private or company')}
                                        value={isCompany}
                                        name="iscompany"
                                        onChange={(event) => {
                                            setIsCompany(event.target.value);
                                            setPaymentField('iscompany', event.target.value)
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label={t('company')} />
                                        <FormControlLabel value={false} control={<Radio />} label={t('private')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider>{t('Location data')}</Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CountryDropdown
                                    value={country}
                                    valueType={"short"}
                                    required
                                    onChange={(val) => onChangeCountry(val)}
                                    style={{
                                        width: '100%',
                                        height: '56px',
                                        padding: '16.5px 14px',
                                        fontSize: 18
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="state"
                                    label={t('State')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="state"
                                    required
                                    onBlur={(event) =>
                                        setPaymentField('state', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="city"
                                    label={t('City')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="city"
                                    required
                                    onBlur={(event) =>
                                        setPaymentField('city', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="zip"
                                    label={t('Zip Code')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="zip"
                                    required
                                    onBlur={(event) =>
                                        setPaymentField('zip', event.target.value)
                                    }

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="address1"
                                    label={t('Address 1')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="address1"
                                    required
                                    onBlur={(event) =>
                                        setPaymentField('address1', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="address2"
                                    label={t('Address 2')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="address2"
                                    onBlur={(event) =>
                                        setPaymentField('address2', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider>{isCompany === 'true' ? t('Company data') : t('User data')}</Divider>
                            </Grid>
                            {
                                isCompany === 'true' ?
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            type="text"
                                            name="companyname"
                                            label={t('Company Name')}
                                            component={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="companyname"
                                            required
                                            onBlur={(event) =>
                                                setPaymentField('companyname', event.target.value)
                                            }
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                isCompany === 'true' ?
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            type="text"
                                            name="vatid"
                                            label={t('VAT Number')}
                                            component={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="vatid"
                                            required={ue_countries.includes(values.country) === true ? true : false}
                                            onBlur={(event) =>
                                                setPaymentField('vatid', event.target.value)
                                            }
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }

                            {
                                isCompany === 'false' &&  values.country === 'IT' ?
                                <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="fiscalcode"
                                    label={t('Social Security Number')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="fiscalcode"
                                    onBlur={(event) =>
                                        setPaymentField('fiscalcode', event.target.value)
                                    }
                                />
                            </Grid>
                                    :
                                    <></>
                                    

                            }
                            {
                                values.country === 'IT' && isCompany === 'true' ?
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            type="text"
                                            name="sdi"
                                            label={t('SDI')}
                                            component={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="sdi"
                                            onBlur={(event) =>
                                                setPaymentField('sdi', event.target.value)
                                            }
                                        />
                                    </Grid>
                                    :
                                    <Fragment />
                            }
                            {
                                values.country === 'IT' && isCompany === 'true' ?
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            type="text"
                                            name="cemail"
                                            label={t('PEC mail')}
                                            component={TextField}
                                            variant="outlined"
                                            fullWidth
                                            id="cemail"
                                            onBlur={(event) =>
                                                setPaymentField('cemail', event.target.value)
                                            }
                                        />
                                    </Grid>
                                    : <Fragment />
                            }
                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="phone"
                                    label={t('Phone number')}
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="phone"
                                    onBlur={(event) =>
                                        setPaymentField('phone', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        background: "linear-gradient(45deg, #C10016 30%, #004EA8 90%)",
                                    }}
                                >
                                    {t('Checkout')}
                                </Button>

                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <form
                name="formSubmitStripe"
                action='https://api.visidea.ai/accounts/create-checkout-session'
                method="POST"
            >
                <input type="hidden" name="priceId" value={props.priceId} />
                <input type="hidden" name="accountId" value={props.userId} />
                <input type="hidden" name="accountEmail" value={props.email} />
            </form>
        </>
    );


}

export default PaymentDataForm
