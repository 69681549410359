import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
};

const ChartBar = ({ data, stats }) => {
  return (
    <Box sx={{ width: '100%', height: '100%', padding: '30px 5px 30px 5px' }}>
        <Typography align='center' variant="h6">{data.datasets[0].label}</Typography>
        <Bar options={options} data={data} />
    </Box>
  );
}

export default ChartBar
