import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import { Box, Container } from '@mui/system'
import React from 'react'
import Header from './Header'
import PremiumIcon from '../components/icons/premium'
import { useParams } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

const CreationPaper = styled(Paper)({
  marginLeft: '10%',
  marginTop: '24px',
  padding: '8px',
  width: '80%',
  background: 'rgba(255, 99, 132, 0.2)',
});

const SubscriptionKo = () => {
  const { t, i18n } = useTranslation(['translation']);

  const { priceId } = useParams();

  if (JSON.parse(localStorage.getItem('priceId')) === priceId) {
    setTimeout(function () {
      JSON.parse(localStorage.getItem('priceId'));
      JSON.parse(localStorage.getItem('planPrice'));
      window.location.replace('/plan');
    }, 5500);
  } else {
    window.location.replace('/plan');
  }

  return (
      <Box flex={4} p={{ xs: 0 }}>
        <Header title={t('Subscription failed')} IconComponent={PremiumIcon} />
        <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <CreationPaper elevation={3}>
            <Typography variant='h3' align='center' paddingTop={3}>
              {t('the payment was not successful')}
            </Typography>
            <Typography variant='h5' align='center' paddingBottom={3} paddingTop={3}>
              {t('you will be redirected to the plan choice page')}
            </Typography>
          </CreationPaper>
        </Container>
      </Box>
  )
}

export default SubscriptionKo