import { Button, Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Header from './Header'
import WebsiteIcon from './icons/website'

import WebsiteVisualConfigurationForm from './Forms/WebsiteVisualConfigurationForm'
import VisualSearchCard from './ConfigurationCards/VisualSearchCard'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';

const WebsiteAdvancedConfigurations = () => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentWebsite } = useSelector((state) => state.website);

    return (

        <Box flex={4} p={{ xs: 0 }}>
            <Header title={t('Global settings')} IconComponent={WebsiteIcon} />
            <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} lg={9} marginBottom={7}>
                        <Typography align='left' variant='h6' marginBottom="10px">
                            {t('Global settings')}
                        </Typography>
                        <WebsiteVisualConfigurationForm
                            labelsNow={currentWebsite.visualsearch_classes}
                            hasVS={currentWebsite.has_visualsearch}
                            hasSS={currentWebsite.has_semanticsearch}
                            ssBind={currentWebsite.semanticsearch_bind}
                            vsShowafter={currentWebsite.visualsearch_showafter}
                            currency={currentWebsite.currency}
                            currency_format={currentWebsite.currency_format}
                            vsGenderFilter={currentWebsite.visualsearch_displaygenderfilter}
                            vsPriceFilter={currentWebsite.visualsearch_displaypricefilter}
                            vsCategoryFilter={currentWebsite.visualsearch_displaycategoryfilter}
                            vsBrandFilter={currentWebsite.visualsearch_displaybrandfilter}
                            visimg={currentWebsite.visualsearch_image}
                        />
                    </Grid>

                    <Grid item sm={3} sx={{ display: {xs: "block", lg: "none"} }}/>

                    <Grid item xs={12} sm={6} lg={3}>
                        <VisualSearchCard />
                    </Grid>

                </Grid>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginTop: '10px' }}>
                    <Link to='/website/configurations'>
                        <Button sx={{ mr: 1 }}>
                            {t('Back')}
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>

    )
}

export default WebsiteAdvancedConfigurations