import styled from '@emotion/styled'
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Typography, Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { persistor } from '../../redux/store'
import AccountIcon from '../icons/account'
import PremiumIcon from '../icons/premium'
import HelpIcon from '../icons/help'
import SettingsIcon from '../icons/settings'
import { useSelector } from 'react-redux'

import { useTranslation, Trans } from 'react-i18next';

const FooterBox = styled(Box)({
    width: '100%',
    position: 'absolute',
    bottom: '0',
    backgroundColor: '#ffffff'
})

const LogoutBox = styled(Box)({
    margin: "10px 10px 10px 10px",
    textAlign: "center"
})

const ProfIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.lightgreen
}));

const SetIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.darkgreen
}));

const PreIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.red
}));

const HeIcon = styled(SvgIcon)(({ theme }) => ({
    color: theme.palette.allColors.lightblue
}));

const Footer = ({ closeMenu }) => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentUser } = useSelector((state) => state.user);

    const purge = () => {
        persistor.purge();
        localStorage.removeItem('paymentData');
        window.location.replace('/');
    }

    const year = new Date().getFullYear();

    return (
        <FooterBox>
            <List>
                <Divider />
                <ListItem disablePadding>
                    <Link to="/account" style={{ textDecoration: "none", color: "inherit", width: '100%' }}>
                        <ListItemButton onClick={closeMenu}>
                            <ListItemIcon>
                                <SetIcon
                                    component={SettingsIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Account')}
                                primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                            />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link  
                        to={ currentUser.plan !== 'team' ? "/team" : '#'}
                        style={{ textDecoration: "none", color: "inherit", width: '100%' }}
                    >
                        <ListItemButton 
                            onClick={closeMenu}
                            disabled={currentUser.plan !== 'team' ? false : true}
                        >
                            <ListItemIcon>
                                <ProfIcon
                                    component={AccountIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Team members')}
                                primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                            />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link 
                        to={ currentUser.plan !== 'team' ? "/plan" : '#'}
                        style={{ textDecoration: "none", color: "inherit", width: '100%' }}
                    >
                        <ListItemButton 
                            onClick={closeMenu}
                            disabled={currentUser.plan !== 'team' ? false : true}
                        >
                            <ListItemIcon>
                                <PreIcon
                                    component={PremiumIcon}
                                    viewBox="0 0 100 100"
                                    fontSize='large'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Premium plan')}
                                primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                            />
                        </ListItemButton>
                    </Link>
                </ListItem >
                <ListItem disablePadding>
                    <ListItemButton onClick={() => window.open("https://docs.visidea.ai/docs/category/app/")}>
                        <ListItemIcon >
                            <HeIcon
                                component={HelpIcon}
                                viewBox="0 0 100 100"
                                fontSize='large'
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Help')}
                            primaryTypographyProps={{ fontSize: "19px", fontWeight: "600" }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
            <LogoutBox>
                <Button
                    fullWidth
                    variant='contained'
                    onClick={purge}
                    style={{
                        background: "linear-gradient(45deg, #ECE81A 30%, #FA4616 90%)"
                    }}
                >
                    {t('LOGOUT')}
                </Button>
            </LogoutBox>
            <Typography variant="subtitle2" align="center">
                Copyright {year} &copy; Visidea
            </Typography>
        </FooterBox>
    )
}

export default Footer