import styled from '@emotion/styled'
import { QuestionMarkRounded } from '@mui/icons-material';
import { Paper, Typography, Switch, Grid, FormControl, FormControlLabel, Radio, RadioGroup, IconButton, Input, Button, Tooltip, Snackbar, Alert } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsite } from '../../utilis/api';

import { useTranslation, Trans } from 'react-i18next';

const Item = styled(Grid)({
  margin: '25px'
})

const WebsiteConfigurationForm = ({ dataConf, index, route }) => {
  const { t, i18n } = useTranslation(['translation']);

  const [conf, setConf] = useState({});
  const [active, setActive] = useState(true);
  const [dots, setDots] = useState(true);
  const [arrows, setArrows] = useState(true);
  const [cartbtn, setCartbtn] = useState(true);
  const [algo, setAlgo] = useState('similarity');
  const [creation, setCreation] = useState(false);

  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  const defaultItems = dataConf.n.toString();

  useEffect(() => {
    setConf(dataConf);
    setActive(dataConf.show);
    setAlgo(dataConf.algo);
    setDots(dataConf.dots);
    setArrows(dataConf.arrows);
    setCartbtn(dataConf.cartbtn);
  }, [dataConf])

  const handleChangeActive = () => {
    setActive(!active);
    setConf({
      ...conf,
      show: !active
    });
  }

  const handleChangeArrows = () => {
    setArrows(!arrows);
    setConf({
      ...conf,
      arrows: !arrows
    });
  }

  const handleChangeDots = () => {
    setDots(!dots);
    setConf({
      ...conf,
      dots: !dots
    });
  }

  const handleChangeCartbtn = () => {
    setCartbtn(!cartbtn);
    setConf({
      ...conf,
      cartbtn: !cartbtn
    });
  }

  const handleChangeAlgo = (e) => {
    setAlgo(e.target.value);
    setConf({
      ...conf,
      algo: e.target.value
    });
  };

  const handleChangeTitle = (e) => {
    setConf({
      ...conf,
      title: e.target.value
    });
  };

  const handleChangeShowAfter = (e) => {
    setConf({
      ...conf,
      show_after: e.target.value
    });
  };

  const handleChangeNumberItems = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else {
      setConf({
        ...conf,
        n: e.target.value
      });
    }
  };

  const handleChangeRows = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else {
      setConf({
        ...conf,
        rows: e.target.value
      });
    }
  };

  const handleChangeSlidesXs = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else if (e.target.value > 12) {
      e.target.value = 12
    } else {
      setConf({
        ...conf,
        slides_xs: e.target.value
      });
    }
};

  const handleChangeSlidesSm = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else if (e.target.value > 12) {
      e.target.value = 12
    } else {
      setConf({
        ...conf,
        slides_sm: e.target.value
      });
    }
  };

  const handleChangeSlidesMd = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else if (e.target.value > 12) {
      e.target.value = 12
    } else {
      setConf({
        ...conf,
        slides_md: e.target.value
      });
    }
  };

  const handleChangeSlidesLg = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    } else if (e.target.value > 12) {
      e.target.value = 12
    } else {
      setConf({
        ...conf,
        slides_lg: e.target.value
      });
    }
  };

  const logNewConf = () => {
    const data = JSON.parse(currentWebsite.settings);

    data[route][index] = conf;

    let copyWeb = currentWebsite;

    copyWeb = {
      ...copyWeb,
      settings: JSON.stringify(data)
    }

    updateWebsite(currentWebsite.id, copyWeb, currentUser, dispatch)
      .then((res) => {
        setCreation(true);
      })
  }

  const deleteBlock = () => {
    const data = JSON.parse(currentWebsite.settings);

    if (index > 0) {
      data[route].splice(index, 1);
    }

    let copyWeb = currentWebsite;

    copyWeb = {
      ...copyWeb,
      settings: JSON.stringify(data)
    }

    updateWebsite(currentWebsite.id, copyWeb, currentUser, dispatch)
      .then((res) => {
        //console.log(res);
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCreation(false);
  };

  return (
    <>
      <Paper elevation={3}>
        <Stack direction='column' >
          <Item container>
            <Grid item sm={3} xs={6}>
              <Typography align='left' variant='h6' marginTop="3px">
                {t('Active')}
                <Tooltip title={t('Display or hide the carousel')}>
                  <IconButton>
                    <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item sm={9} xs={6}>
              <Switch
                checked={active}
                onChange={handleChangeActive}
                name="active"
                color="primary"
              />
            </Grid>
          </Item>
          <Item container>
            <Grid item sm={3} xs={12}>
              <Typography align='left' variant='h6' marginTop="3px">
                {t('Algorithm')}
                <Tooltip title={t('Algorithm used in the widget (read more on the article linked in the right pane)')}>
                  <IconButton>
                    <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item sm={9} xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby=""
                  defaultValue="similarity"
                  name=""
                  value={algo}
                  onChange={handleChangeAlgo}
                >
                  <FormControlLabel value="user" control={<Radio />} label={t('Collaborative Filtering')} />
                  <FormControlLabel value="pop" control={<Radio />} label={t('Popular items')} />
                  <FormControlLabel value="history" control={<Radio />} label={t('Last seen')} />
                  <FormControlLabel value="similarity" control={<Radio />} label={t('Visual Similarity')} disabled={route!=='product'} />
                  <FormControlLabel value="item" control={<Radio />} label={t('Item based')} disabled={route!=='product'} />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Heading')}
                  <Tooltip title={t('The heading of the carousel')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="text"
                    value={conf.title}
                    placeholder={conf.title}
                    onChange={handleChangeTitle}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Items')}
                  <Tooltip title={t('Number of shown items in the carousel')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.n}
                    onChange={handleChangeNumberItems}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Rows')}
                  <Tooltip title={t('Number of rows in the carousel')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.rows}
                    onChange={handleChangeRows}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item container>
            <Grid item sm={3} xs={6}>
              <Typography align='left' variant='h6' marginTop="3px">
                {t('Arrows')}
                <Tooltip title={t('Display or hide arrows')}>
                  <IconButton>
                    <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item sm={9} xs={6}>
              <Switch
                checked={arrows}
                onChange={handleChangeArrows}
                name="arrows"
                color="primary"
              />
            </Grid>
          </Item>
          <Item container>
            <Grid item sm={3} xs={6}>
              <Typography align='left' variant='h6' marginTop="3px">
                {t('Dots')}
                <Tooltip title={t('Display or hide dots')}>
                  <IconButton>
                    <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item sm={9} xs={6}>
              <Switch
                checked={dots}
                onChange={handleChangeDots}
                name="dots"
                color="primary"
              />
            </Grid>
          </Item>
          <Item container>
            <Grid item sm={3} xs={6}>
              <Typography align='left' variant='h6' marginTop="3px">
                {t('Cart button')}
                <Tooltip title={t('Display or hide add to cart button (WordPress only)')}>
                  <IconButton>
                    <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item sm={9} xs={6}>
              <Switch
                checked={cartbtn}
                onChange={handleChangeCartbtn}
                name="cartbtn"
                color="primary"
              />
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Show after')}
                  <Tooltip title={t('*DEVELOPERS OPTION* Insert the Jquery handle of the element where the carousel must be placed')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="text"
                    id="show"
                    variant="standard"
                    value={conf.show_after}
                    onChange={handleChangeShowAfter}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>

          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Slides XS')}
                  <Tooltip title={t('Number of slides to display in smartphones')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.slides_xs}
                    onChange={handleChangeSlidesXs}
                    min="1"
                    max="12"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Slides SM')}
                  <Tooltip title={t('Number of slides to display in tablets')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.slides_sm}
                    onChange={handleChangeSlidesSm}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Slides MD')}
                  <Tooltip title={t('Number of slides to display in laptops')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.slides_md}
                    onChange={handleChangeSlidesMd}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>
          <Item>
            <Grid container>
              <Grid item sm={3} xs={12}>
                <Typography align='left' variant='h6' marginTop="3px">
                  {t('Slides LG')}
                  <Tooltip title={t('Number of slides to display in larger screens')}>
                    <IconButton>
                      <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl fullWidth>
                  <Input
                    type="number"
                    value={conf.slides_lg}
                    onChange={handleChangeSlidesLg}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Item>

          <Item container>
            <Grid item sm={6} xs={12}>
              <Button
                onClick={deleteBlock}
                disabled={JSON.parse(currentWebsite.settings)[route].length < 2}
              >
                {t('delete block')}
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button align='right' onClick={logNewConf}>
                {t('save configuration')}
              </Button>
            </Grid>
          </Item>
        </Stack>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={creation}
        autoHideDuration={5000}
        onClose={handleClose}
        message={t('Configuration saved')}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('Configuration saved')}
        </Alert>
      </Snackbar>
    </>

  )
}

export default WebsiteConfigurationForm