export const timeRecommendations = (data, label) => {
  let labels = [];
  let timeData = [];

  data.recommendations.timeseries.forEach((x, i) => 
    labels.push(x.date.split('T')[0])
  );

  data.recommendations.timeseries.forEach((x, i) => 
    timeData.push(x.count)
  );

  return {
    labels: labels,
    datasets: [
      {
        label: label,
        data: timeData,
        borderColor: 'rgba(250, 70, 22, 1)',
        backgroundColor: 'rgba(250, 70, 22, 0.5)',
      }
    ],
  }
};