import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fecthDispWebsite } from '../../utilis/api';
import { useDispatch } from 'react-redux';

const WebsiteConfigurationCard = ({ name, route, src }) => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);
    const dispatch = useDispatch();
  
    const reloadConfiguration = () => {
      fecthDispWebsite(currentWebsite.id, currentUser, dispatch)
        .then((res) => {})
    }

    return (
        <Card>
            <CardMedia
                component="img"
                src={src}
                alt=""
            />
            <CardContent>
                <Typography variant="h5" component="div">
                    {name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t('Configurations')}
                </Typography>
                <Typography variant="body2">
                    {t('Configure algorithm type, number of elements displayed and position on your')} {name}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    component={Link}
                    to={`/website/configurations/${route}`}
                    onClick={() => reloadConfiguration()}
                >
                    {t('setup')}
                </Button>
            </CardActions>
        </Card>
    )
}

export default WebsiteConfigurationCard