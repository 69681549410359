import { Add } from '@mui/icons-material'
import { Alert, Button, Fab, Grid, Snackbar, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { fetchWebsites, updateWebsite } from '../utilis/api'
import { block } from '../utilis/newblockSchema'
import AlgorithmCard from './ConfigurationCards/AlgorithmCard'
import WebsiteConfigurationForm from './Forms/WebsiteConfigurationForm'
import Header from './Header'
import WebsiteIcon from './icons/website'

import { useTranslation, Trans } from 'react-i18next';

const WebsiteConfigurations = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [data, setData] = useState({});
  const [blockCreation, setBlockCreation] = useState(false);

  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  const location = useLocation().pathname;
  const lastItem = location.substring(location.lastIndexOf('/') + 1)
  
  useEffect(() => {
    setData(JSON.parse(currentWebsite.settings)[lastItem])
  }, [currentWebsite, lastItem])
  
  const createNewBlock = () => {
    const confData = JSON.parse(currentWebsite.settings);

    confData[lastItem].push(block);

    let copyWeb = currentWebsite;

    copyWeb = {
      ...copyWeb,
      settings: JSON.stringify(confData)
    }

    updateWebsite(currentWebsite.id, copyWeb, currentUser, dispatch)
      .then((res) => {
        setBlockCreation(true);
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBlockCreation(false);
  };

  return (
    <>
      <Box flex={4} p={{ xs: 0 }}>
        <Header title={t('Algorithm configuration')} IconComponent={WebsiteIcon} />
        <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
            {Array.from(data).map((el, index) => (
              <Grid container spacing={2} key={"WebsiteConfigurationForm_"+index}>
                <Grid item xs={12} lg={9} marginBottom={7}>
                  <Typography align='left' variant='h6' marginBottom="10px">
                    {lastItem[0].toUpperCase() + lastItem.slice(1)} {t('configuration block')} {index + 1}
                  </Typography>
                  <WebsiteConfigurationForm
                    dataConf={el}
                    index={index}
                    route={lastItem}
                  />
                </Grid>

                <Grid item sm={3} sx={{ display: {xs: "block", lg: "none"} }}/>

                <Grid item xs={12} sm={6} lg={3} key={"AlgorithmCard_"+index} marginBottom={7}>
                  <AlgorithmCard />
                </Grid>
              </Grid>
            ))}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginTop: '10px' }}>
            <Link to='/website/configurations'>
              <Button sx={{ mr: 1 }}>
                {t('Back')}
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
      <Fab sx={{ position: 'fixed', bottom: 35, right: 55 }} variant="extended" onClick={createNewBlock}>
        {!isMobile ? <><Add sx={{ mr: 1 }} /> {t('New block')}</> :
          <Add />
        }
      </Fab>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={blockCreation}
        autoHideDuration={5000}
        onClose={handleClose}
        message={t('New block created')}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('New block created')}
        </Alert>
      </Snackbar>
    </>

  )
}

export default WebsiteConfigurations