import React, { useState, useEffect, useRef } from 'react';

const DetectionsDialog = ({ image }) => {
    const [width, setWidth] = useState(650);
    const [height, setHeight] = useState(0);
    const canvasRef = useRef();
    const containerRef = useRef();
    const myImage = useRef(new Image());

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.clientWidth);
            }
        };

        handleResize(); // Set initial width

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        myImage.current.onload = () => {
            const ratio = myImage.current.naturalWidth / myImage.current.naturalHeight;
            setHeight(width / ratio);
        };
        myImage.current.src = image.image;
    }, [image, width]);

    useEffect(() => {
        if (height > 0) {
            drawRectangle();
        }
    }, [height]);

    const drawRect = (ctx, x, y, w, h, fill, stroke, strokeWidth) => {
        ctx.save();
        ctx.fillStyle = fill;
        ctx.strokeStyle = stroke;
        ctx.lineWidth = strokeWidth;
        ctx.beginPath();
        ctx.roundRect(x, y, w, h, 10);
        ctx.stroke();
        ctx.fill();
        ctx.restore();
    };

    const drawRectangle = () => {
        const context = canvasRef.current.getContext('2d');
        const detections = JSON.parse(image.results).detections;
        context.drawImage(myImage.current, 0, 0, width, height);
        context.strokeStyle = 'white';
        context.lineWidth = 2;
        const resizeFactor = myImage.current.width / width;
        if (detections) {
            detections.forEach(element => {
                drawRect(
                    context,
                    (element.xcenter - element.width / 2) / resizeFactor,
                    (element.ycenter - element.height / 2) / resizeFactor,
                    element.width / resizeFactor,
                    element.height / resizeFactor,
                    'rgba(0, 0, 0, .33)',
                    'white',
                    2
                );
            });
        }
    };

    return (
        <div ref={containerRef} style={{ width: '100%', height: 'auto' }}>
            {height > 0 ? (
                <canvas ref={canvasRef} width={width} height={height} />
            ) : null}
        </div>
    );
};

export default DetectionsDialog;
