import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartDoughnut = ({ data }) => {

  return (
    <Box sx={{ width: '70%', height: '100%', padding: '15px 25px 15px 25px' }}>
        <Typography align='center' variant="h6">{data.datasets[0].label}</Typography>
        <Doughnut data={data} />
    </Box>
    );
}

export default ChartDoughnut;