import { EuroOutlined, PsychologyOutlined, ShoppingCartOutlined, Visibility } from '@mui/icons-material'
import { Avatar, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'

const WidgetInteractionsType = ({ data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Grid
            container
            direction='row'
            justify="center"
            alignItems='center'
            minHeight='150px'
            spacing={2}
        >
            <Grid item xs={12} marginLeft='20px'>
                <Typography variant='h6'>
                    {t('Interactions type')}
                </Typography>
            </Grid>
            <Grid item md={3} lg={3} xl={3} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(76, 159, 200)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <PsychologyOutlined fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.total}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Total
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={3} lg={3} xl={3} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px', }}>
                    <Grid container spacing={1} >
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(120, 190, 32)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <Visibility fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.views}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Views
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={3} lg={3} xl={3} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px', }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(250, 70, 22)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <ShoppingCartOutlined fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.carts}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Cart
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={3} lg={3} xl={3} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px', }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(236, 232, 26)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <EuroOutlined fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.purchases}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Purchase
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default WidgetInteractionsType