import { ArrowUpward, People } from '@mui/icons-material'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { numberWithCommas } from '../../utilis/utils'

import { useTranslation, Trans } from 'react-i18next';

const Users = ({ period, data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="secondary"
                            gutterBottom
                            variant="overline"
                        >
                            {t('TOTAL CUSTOMERS')}
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h4"
                        >
                            {numberWithCommas(data.total)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'rgb(236, 232, 26)',
                                height: 40,
                                width: 40
                            }}
                        >
                            <People />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        pt: 2
                    }}
                >
                    <ArrowUpward color="success" sx={{ marginBottom: 1 }}/>
                    <Typography
                        variant="body2"
                        color="success"
                        sx={{
                            mr: 1
                        }}
                    >
                        {data.currentperiod}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        { period === 'week' ? t('New user from last week') : t('New user from last day') }
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default Users