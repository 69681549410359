import { Box, Stack, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Sidebar from "./components/Sidebar";
import WebsiteNew from "./components/WebsiteNew";
import WebsiteSettings from "./components/WebsiteSettings";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard";
import WebsiteConfigurations from "./components/WebsiteConfigurations";
import WebsiteConfigurationMenu from "./components/WebsiteConfigurationMenu";
import theme from "./utilis/theme";
import WebsiteAdvancedConfigurations from "./components/WebsiteAdvancedConfigurations";
import PremiumPlan from "./pages/PremiumPlan";
import WebsiteCssConfigurations from "./components/WebsiteCssConfiguration";
import Recover from "./pages/Recover";
import Checkout from "./components/Checkout";
import dayjs from "dayjs";
import ResetPassword from "./pages/ResetPassword";
import SubscriptionOk from "./components/SubscriptionOk";
import SubscriptionKo from "./components/subscriptionKo";
import LoadingStats from "./pages/LoadingStats";
import Error404 from "./pages/Error404";
import { Navigate } from 'react-router-dom';
import CustomRule from "./components/CustomRule";
import SelectComplementaryproducts from "./components/SelectComplementaryproducts";
import TeamMembers from "./components/TeamMembers";
import TeamMemberCreation from "./components/TeamMemberCreation";
import TeamMemberConfigurationPermission from "./components/Forms/TeamMemberConfigurationPermission";
import TeamRoute from "./components/route/TeamRoute";
import DragUiTest from "./components/DragUiTest";
import VsGallery from "./components/VsGallery";

function App() {
  const [plan, setPlan] = useState(true);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  useEffect(() => {
    const checkPlan = () => {
      if ((currentUser && currentUser.plan !== 'trial' && dayjs().isAfter(currentUser.planEnds)) ||
        (currentUser && currentUser.plan === 'trial' && dayjs().isAfter(currentUser.trialEnds))) {
        setPlan(false);
      }
      if (currentWebsite && (dayjs().subtract(1, 'day').isBefore(currentWebsite.createdDate))) {
        setLoading(false);
      }
    };
    checkPlan();
  }, [currentUser, currentWebsite]);

  return (
    currentUser ?
      <ThemeProvider theme={theme}>
        <Router>
          <Box bgcolor={"background.default"} color={"text.primary"}>
            <Stack direction="row">
              <Sidebar plan={plan} />
              <Box sx={{ marginTop: { xs: '64px', md: '0' }, width: '100%' }}>

                {
                  currentUser.plan === 'team' && currentWebsite
                    ?
                    <>
                      <TeamRoute
                        loading={loading}
                        permission={currentUser.acl}
                        website={currentWebsite}
                      />
                    </>
                    :
                    plan ?
                      <Routes>
                        <Route path="/">
                          <Route index element={<Home />} />
                          <Route path='/login' element={<Navigate to="/" />} />
                          <Route path='/register' element={<Navigate to="/" />} />
                          <Route path="newWebsite" element={<WebsiteNew />} />
                          {/* {
                            loading ?
                              <Route path="website/kpi" element={<Dashboard />} />
                              :
                              <Route path="website/kpi" element={<LoadingStats />} />
                          } */}
                          <Route path="website/kpi" element={<Dashboard />} />
                          <Route path="website/settings" element={<WebsiteSettings />} />
                          <Route path="website/configurations" element={<WebsiteConfigurationMenu />} />
                          <Route path="website/configurations/:name" element={<WebsiteConfigurations />} />
                          <Route path="website/advancedConfigurations/visual" element={<WebsiteAdvancedConfigurations />} />
                          <Route path="website/advancedConfigurations/css" element={<WebsiteCssConfigurations />} />
                          <Route path="website/custom" element={<CustomRule />} />
                          <Route path="website/custom/selectProducts" element={<SelectComplementaryproducts />} />
                          <Route path="website/vs-photos" element={<VsGallery />} />
                          <Route path="account" element={<Profile />} />
                          <Route path="team" element={<TeamMembers />} />
                          <Route path="team/new" element={<TeamMemberCreation />} />
                          <Route path="team/configuration" element={<TeamMemberConfigurationPermission />} />
                          <Route path="plan" element={<PremiumPlan />} />
                          <Route path="checkout" element={<Checkout />} />
                          <Route path="signin" element={<SignIn />} />
                          <Route path="subscriptionOk/:priceId" element={<SubscriptionOk />} />
                          <Route path="subscriptionKo/:priceId" element={<SubscriptionKo />} />
                          <Route path="*" element={<Error404 />} />
                          <Route path="drag" element={<DragUiTest />} />
                        </Route>
                      </Routes>
                      :
                      <Routes>
                        <Route path="/">
                          <Route index element={<PremiumPlan />} />
                          <Route path="plan" element={<PremiumPlan />} />
                          <Route path="account" element={<Profile />} />
                          <Route path="checkout" element={<Checkout />} />
                          <Route path="subscriptionOk/:priceId" element={<SubscriptionOk />} />
                          <Route path="subscriptionKo/:priceId" element={<SubscriptionKo />} />
                          <Route path="*" element={<Error404 />} />
                        </Route>
                      </Routes>
                }

              </Box>
            </Stack>
          </Box>
        </Router>
      </ThemeProvider>
      :
      <Router>
        <Routes>
          <Route exact path="/">
            <Route index element={<SignIn />} />
            <Route path="login" element={<SignIn />} />
            <Route path="register" element={<SignIn />} />
            <Route path="recover" element={<Recover />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="newWebsite" element={<WebsiteNew />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </Router>
  );
}

//resetpassword?username={var}&token={var}

export default App;
