import { Box, Button, Card, CardActionArea, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DetectionsDialog from './DetectionsDialog';

const StyledCard = styled(Card)(({ theme }) => ({
    '&:hover': {
        opacity: 0.9,
    },
}));

const splitDate = (date) => {
    if (date) {
        const parsedDate = new Date(date);
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        const hours = parsedDate.getHours().toString().padStart(2, '0');
        const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    } else {
        return '';
    }
}

const MansoryVSImages = ({ images }) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const openImage = (img) => {
        setImage(img)
        setOpen(true);
    }

    return (
        <ResponsiveMasonry columnsCountBreakPoints={{ 500: 2, 750: 3, 900: 3, 1100: 5, 1300: 5 }}>
            <Masonry
                columnsCount={5}
                gutter="20px"
                options={{
                    columnWidth: 1
                }}
            >
                {
                    images.map((item, i) => (
                        <StyledCard key={i}>
                            <CardActionArea onClick={() => openImage(item)}>
                                <CardMedia
                                    component="img"
                                    image={item.image}
                                />
                            </CardActionArea>
                        </StyledCard>
                    ))
                }
            </Masonry>

            <Dialog sx={{ margin:'15px auto', maxWidth: 600, width: '100%', '& .MuiDialog-paper': { maxWidth: '100%', width: '100%', maxHeight: '100%', height: '100%' } }} onClose={handleClose} open={open}>
                <DialogTitle>{splitDate(image.createdDate)}</DialogTitle>
                <DialogContent sx={{  }}>
                    <DetectionsDialog image={image} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

        </ResponsiveMasonry>
    )
}

export default MansoryVSImages;
