import styled from '@emotion/styled'
import { Button, Grid, Typography, Divider, Chip, Snackbar, Alert } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';

import LoginForm from '../components/Forms/LoginForm'
import RegistrationForm from '../components/Forms/RegistrationForm'
import { signInWithGoogle, signInWithFacebook, signInWithApple } from '../utilis/api'

import { useTranslation, Trans } from 'react-i18next';

const Root = styled(Grid)({
    height: '100vh',
    overflow: 'auto'
});

const ImageGrid = styled(Grid)({
    backgroundImage: 'url("../../img/login_background.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const FormGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px'
})

const SocialButton = styled(Button)({
    margin: "20px 0 0 0 ",
    color: '#000',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#fff',
    }
});


const SignIn = () => {
    const { t, i18n } = useTranslation(['translation']);
    
    const [login, setLogin] = useState(true);
    const [errorProvider, setErrorProvider] = useState(false);
    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        setLogin(location.pathname !== '/register');
    }, [location.pathname]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setErrorProvider(false);
    };

    const handleClickScroll = () => {
        const element = document.getElementById('formContainer');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Root container component="main">
            {/* <ImageGrid item xs={false} sm={6} display={{ xs: "none", md: "flex" }}> */}
            <ImageGrid item xs={12} sm={6}>
                <Grid container xs={12} md={10} lg={8}>
                    <img src={'../../img/register/desktop-'+i18n.language.substring(0,2)+'.png'} width={"100%"} className={"register-image__desktop"} />
                    <img src={'../../img/register/mobile-'+i18n.language.substring(0,2)+'.png'} width={"100%"} className={"register-image__mobile"} />
                    <img src="../../img/register/arrows.png" className={"register-arrows"} onClick={handleClickScroll} />

                    {/* <Grid item xs={12} sx={{backgroundColor:"white",padding:"60px 30px",opacity:"0.9"}}>
                        <Typography variant="h6" content="h6" sx={{marginBottom:"30px"}}>
                            {t('Personalize your e-commerce shopping experience and make it memorable')}!
                        </Typography>
                        <Typography variant="div" content="div" sx={{fontWeight:"bold", marginBottom:"30px"}}>
                            {t('Improve product discovery, increase conversion rates and enhance cross-selling and upselling')}
                        </Typography>
                        <ol style={{marginTop:"30px"}}>
                            <li style={{marginBottom:"15px"}}>{t('Register with the form that you find on this page')}</li>
                            <li style={{marginBottom:"15px"}}>{t('Download the plugin, install it and configure it on your CMS by following the instructions you will visualize once registered')}</li>
                            <li>{t('Try Visidea for 14 days, see how it boosts your conversion rate and decide if it\'s the right fit for your business')}</li>
                        </ol>
                    </Grid> */}
                </Grid>
            </ImageGrid>
            <FormGrid item xs={12} sm={6}>

                <Grid container xs={12} md={6}>

                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "15px" }}>
                        <img src="../../img/logo_positive.png" alt="Visidea" width={"55%"} />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography component="h2" variant="h7">{!login || location.pathname === '/register' ? t('Register') : t('Login')}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }} id="formContainer">
                        {
                            login ?
                                <Link to="/register" style={{ color: "inherit" }}>
                                    {t('Register')}
                                </Link>
                                :
                                <Link to="/login" style={{ color: "inherit" }}>
                                    {t('Login')}
                                </Link>
                        }
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <SocialButton
                            variant="contained"
                            color="info"
                            fullWidth
                            sx={{ height: 33 }}
                            onClick={() => {
                                signInWithGoogle(dispatch)
                                    .then((res) => {
                                        setErrorProvider(res)
                                    })
                            }}
                        >
                            {t('Sign in with')} Google
                        </SocialButton>
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: "5px" }}>
                        <SocialButton
                            variant="contained"
                            color="info"
                            fullWidth
                            sx={{ height: 33 }}
                            onClick={() => {
                                signInWithFacebook(dispatch)
                                    .then((res) => {
                                        setErrorProvider(res)
                                    })
                            }}
                        >
                            Facebook
                        </SocialButton>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right", paddingLeft: "5px" }}>
                        <SocialButton
                            variant="contained"
                            color="info"
                            fullWidth
                            sx={{ height: 33 }}
                            onClick={() => {
                                signInWithApple(dispatch)
                                    .then((res) => {
                                        setErrorProvider(res)
                                    })
                            }}
                        >
                            Apple
                        </SocialButton>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Divider>
                            <Chip label={t('or')} />
                        </Divider>
                    </Grid>

                    <Grid item xs={12}>
                        {login ? <LoginForm /> : <RegistrationForm />}
                    </Grid>

                </Grid>
            </FormGrid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={errorProvider}
                onClose={handleClose}
                autoHideDuration={5000}
                message={t('Error with the selected provider')}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {t('Error with the selected provider')}
                </Alert>
            </Snackbar>
        </Root>
    )
}

export default SignIn