import { AvatarGroup, TableCell } from '@mui/material'
import React from 'react'
import CustomRuleAvatar from './CustomRuleAvatar';

const CustomRuleTableAvatar = ({ compl }) => {

  var i=1;
  return (
    <TableCell align="right">
        <AvatarGroup max={4}>
          {
            compl.map((prod) => 
              <CustomRuleAvatar prod={prod} key={i++} />
            ) 
          }
        </AvatarGroup>
  </TableCell>
  )
}

export default CustomRuleTableAvatar