import { CheckCircle, Close, Delete, Settings, Visibility } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteMember } from '../utilis/api';

import { useTranslation, Trans } from 'react-i18next';

const formatPermissionData = (data) => {
    let final = [];

    //data.map((el) => {
        for (const [key, value] of Object.entries(data)) {
            let permission = [];
            value.split('').map((e) => {
                if (e === '1') {
                    permission.push(true);
                } else {
                    permission.push(false);
                }
            })
            final.push({ name: key, permission: permission });
        }
    //})

    return final;
}

const TeamMembersTableBody = ({ row, removeMember }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const { currentUser } = useSelector((state) => state.user);

    const sitePermission = formatPermissionData(JSON.parse(row.acl));

    const handleDelete = () => {
        deleteMember(row._id, currentUser)
            .then(() => {
                removeMember(row._id);
                setOpenDelete(false);
            })
    }

    return (
        <>
            <TableRow
                key={row.firstname}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    {row.firstname}
                </TableCell>
                <TableCell>{row.lastname}</TableCell>
                <TableCell>{row.username}</TableCell>

                <TableCell align="center">
                    <IconButton
                        aria-label="setting"
                        size="large"
                        onClick={() => setOpen(true)}
                    >
                        <Visibility />
                    </IconButton>
                </TableCell>
                <TableCell align="right">
                    <Link to="/team/configuration" state={{
                       data: row
                    }}>
                        <Tooltip title={t('Menage member permission')}>
                            <IconButton
                                aria-label="setting"
                                size="large"
                            >
                                <Settings />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip title={t('Delete team member')}>
                        <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={() => setOpenDelete(true)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <Dialog
                //onClose={handleClose} 
                maxWidth='md'
                fullWidth
                open={open}
            >
                <DialogTitle>{t('Websites & Auths')}</DialogTitle>
                <DialogContent>
                    <Table aria-label="website table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Website name')}</TableCell>
                                <TableCell align="center">{t('Dashboard')}</TableCell>
                                <TableCell align="center">{t('Configuration')}</TableCell>
                                <TableCell align="center">{t('Settings')}</TableCell>
                                <TableCell align="center">{t('Custom recs')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sitePermission.map((site) =>
                                    <TableRow
                                        key={site.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {site.name}
                                        </TableCell>
                                        {
                                            site.permission.map((p) =>
                                                p === true
                                                    ?
                                                    <TableCell align="center">
                                                        <CheckCircle color='success' />
                                                    </TableCell>
                                                    :
                                                    <TableCell align="center">
                                                        <Close color='error' />
                                                    </TableCell>
                                            )
                                        }

                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        {t('close')}
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                open={openDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">
                    {"Are you sure you want to delete the team member?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">
                        {t('This team member will be removed')}. {t('Proceed?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)}>{t('Close?')}</Button>
                    <Button onClick={handleDelete}>
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TeamMembersTableBody