import { DeleteOutline } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearWebsiteState } from '../../redux/websiteSlice'
import { deleteWebsite, fetchWebsitesFirst } from '../../utilis/api'

import { useTranslation, Trans } from 'react-i18next';

const DeleteWebsite = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [open, setOpen] = useState(false);
    const [block, setBlock] = useState(true);
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const handleClickOpen = () => {
        setOpen(true);
        setBlock(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCapture = (event) => {
        if(currentWebsite.name === event.target.value)  {
            setBlock(false);
        }
    }

    const handleDelete = () => {
        handleClose();
        deleteWebsite(currentWebsite.id, currentUser)
            .then(() => {
                dispatch(clearWebsiteState);
                fetchWebsitesFirst(currentUser, dispatch)
                    .then(() => {
                        window.location.replace('/');
                        //window.location.reload();
                    });
            })
    }
    return (
        <>
            <Button
                fullWidth
                variant='contained'
                style={{
                    background: "linear-gradient(45deg, #ECE81A 30%, #FA4616 90%)",
                    width: "50%"
                }}
                onClick={handleClickOpen}
                startIcon={<DeleteOutline />}>
                {t('Delete')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete the site?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('All information will be deleted')}. {t('To re-enter this site you will need to repeat the installation procedure from plug in (or manually in case of a custom site)')}.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t('website name')}
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder={t('write website name')}
                        onChange={(event) => handleCapture(event)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button 
                        disabled={block}
                        onClick={handleDelete}
                    >
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default DeleteWebsite