import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react'
import RecoverPassword from '../components/Forms/RecoverPassword';

const Root = styled(Grid)({
    height: '100vh',
    overflow: 'hidden'
});

const ImageGrid = styled(Grid)({
    backgroundImage: 'url("../../img/login_background.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
})

const FormGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px'
})

const Recover = () => {
  return (
    <Root container component="main">
        <ImageGrid item xs={false} sm={6} />
        <FormGrid item xs={12} sm={6}>
            <Grid container xs={12} md={6}>
                <Grid item xs={12} style={{ textAlign: "center", marginBottom: "15px" }}>
                    <img src="../../img/logo_positive.png" alt="Visidea" width={"55%"}/>
                </Grid>
                <Grid item xs={12}>
                    <RecoverPassword />
                </Grid>
            </Grid>
        </FormGrid>
    </Root>
  )
}

export default Recover