import { createSvgIcon } from "@mui/material"


const AddIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M34,5.91a3.52,3.52,0,0,0-4.94-.38,3.48,3.48,0,0,0-1.21,2.39,3.53,3.53,0,0,0,.83,2.55,3.48,3.48,0,0,0,2.39,1.21h.28a3.42,3.42,0,0,0,1.51-.36A15.54,15.54,0,1,1,22.45,4.66a1,1,0,0,0,.31-2A17.56,17.56,0,1,0,34.37,10,3.49,3.49,0,0,0,34,5.91ZM31.26,9.69a1.52,1.52,0,0,1-1-.52,1.5,1.5,0,0,1-.35-1.09,1.48,1.48,0,0,1,.52-1,1.5,1.5,0,1,1,.86,2.64Z"/><path d="M19.87,31.69a1,1,0,0,0,1-1v-10h9a1,1,0,0,0,0-2h-9v-9a1,1,0,0,0-2,0v9h-9a1,1,0,0,0,0,2h9v10A1,1,0,0,0,19.87,31.69Z"/>
    </svg>,
    'AddIcon'      
)

export default AddIcon