import styled from '@emotion/styled'
import { Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from './Header'
import PremiumIcon from '../components/icons/premium'
import { useEffect } from 'react'
import { getUserInformations } from '../utilis/api'
import { useParams } from 'react-router-dom'
import Error404 from '../pages/Error404'

import { useTranslation, Trans } from 'react-i18next';

const CreationPaper = styled(Paper)({
  marginLeft: '10%',
  marginTop: '24px',
  padding: '8px',
  width: '80%',
  background: 'rgba(120,190,32,0.2)',
});

const SubscriptionOk = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [errorPage, setErrorPage] = useState(true);

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);
  const dispatch = useDispatch();

  const { priceId } = useParams();

  useEffect(() => {
    const getUserInfo = () => {
      getUserInformations(currentUser, dispatch)
        .then(() => {
          trackPurchase();
          localStorage.removeItem('priceId');
          localStorage.removeItem('planPrice');
        })
    }
    if (JSON.parse(localStorage.getItem('priceId')) === priceId) {
      getUserInfo();
      setErrorPage(false);

      setTimeout(function () {
        window.location.replace('/');
      }, 2000);
    }
  }, [currentUser, priceId, dispatch])

  const trackPurchase = () => {
    let account_id = currentUser._id;
    let website_id = 'no_website';
    if (typeof currentWebsite !== 'undefined' && typeof currentWebsite._id !== undefined)
      website_id = currentWebsite._id;
    let category = 'not_set';
    if (typeof currentWebsite !== 'undefined' && typeof currentWebsite.type !== undefined)
      category = currentWebsite.type;
    if (JSON.parse(localStorage.getItem('planPrice')) > 0) {
      let payload = {
        'id': account_id + '_' + website_id,
        'userId': account_id,
        'currencyCode': 'EUR',
        'revenue': JSON.parse(localStorage.getItem('planPrice')),
        'category': category,
        'event': 'purchase'
      };
      console.log(payload)
      window.dataLayer.push(payload);
    }
  }

  return (

    errorPage ?
      <Error404 />
      :
      <Box flex={4} p={{ xs: 0 }}>
        <Header title={t('Subscription success')} IconComponent={PremiumIcon} />
        <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
          <CreationPaper elevation={3}>
            <Typography variant='h3' align='center' paddingTop={3}>
            {t('Thank you')}, {currentUser.firstname}
            </Typography>
            <Typography variant='h5' align='center' paddingBottom={3} paddingTop={3}>
            {t('The payment was successful, the subscription was extended')}.
            </Typography>
          </CreationPaper>
        </Container>
      </Box>

  )
}

export default SubscriptionOk