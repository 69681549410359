import { Category, Group, Layers } from '@mui/icons-material';
import { Avatar, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useTranslation } from 'react-i18next';

const WidgetWebsiteDatasets = ({ data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Grid
            container
            direction='row'
            justify="center"
            alignItems='center'
            minHeight='150px'
            spacing={2}
        >
            <Grid item xs={12} marginLeft='20px'>
                <Typography variant='h6'>
                    {t('Website datasets')}
                </Typography>
            </Grid>
            <Grid item md={4} lg={4} xl={4} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '100px', }}>
                    <Grid container spacing={1} >
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(120, 190, 32)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <Category fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.items}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Items
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={4} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '100px', }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(250, 70, 22)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <Group fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.users}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Users
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={4} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '100px', }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(236, 232, 26)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                <Layers fontSize='medium' />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '25%' }}>
                                <Typography>
                                    {data.interactions.total}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Interactions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default WidgetWebsiteDatasets