import { Button, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchRules, fetchRulesV2 } from '../utilis/api';
import CustomRuleTableBody from './CustomRuleTableBody';
import Header from './Header';

import { useTranslation, Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const CustomRule = () => {
  const { t, i18n } = useTranslation(['translation']);
  const numberEl = 4

  const [page, setPage] = useState(1);
  const [rules, setRules] = useState(null);

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  const handleDeleteRule = (id) => {
    let newRules = rules.data.filter(function(el) { return el._id !== id; });
    setRules({ count: newRules.length, data: newRules });

    if(rules.count === 1 && rules.data[0]._id === id) {
      setRules(null)
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  var i = 0;
  
  useEffect(() => {
    const fetchAllRules = () => {
      fetchRulesV2(currentWebsite.id, currentUser, numberEl, numberEl * (page - 1))
        .then((res) => {
          console.log(res.data)
          setRules(res.data)
        })
    };
    fetchAllRules();
  }, [currentUser, currentWebsite, page]);

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Custom recommendations')} />
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container>
          <Grid item lg={6} xl={6} md={6} xs={0} sm={0} paddingBottom={2}>
            <Typography variant="h4" content="h2">
              {t('Custom recommendations')}
            </Typography>
            <Typography variant="span" content="span">
              {t('Choose products to recommend on product page')}
            </Typography>
          </Grid>
          <Grid item lg={6} xl={6} md={6} paddingTop={2}>
            <Box display="flex" justifyContent="flex-end">
              <Link
                to='/website/custom/selectProducts'
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
                state={{
                  target: null,
                  compl: [],
                  ruleId: null
                }}
              >
                <Button>
                  {t('Add recommendations')}
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Products')}</TableCell>
                <TableCell align="right">{t('Recommendations')}</TableCell>
                <TableCell align="right">{t('Actions')}</TableCell>
              </TableRow>
            </TableHead>
            {
              rules !== null && rules.count > 0
                ?
                rules.data.map((rule) =>
                  <CustomRuleTableBody rule={rule} handleRule={handleDeleteRule} key={i++}/>
                )
                :
                <TableBody>
                  <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row" align='center'>{t('No recommendations')}</TableCell>
                  </TableRow>
                </TableBody>
            }
          </Table>
        </TableContainer>
        <Grid container justifyContent="center" paddingTop={2}>
          <Pagination
            count={rules == null ? 1 : Math.ceil(rules.count / numberEl)}
            page={page}
            size={isMobile ? 'medium' : 'large'}
            onChange={handleChange}
          />
        </Grid>
      </Container>
    </Box>
  )
}

export default CustomRule