import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1D252D',
    },
    secondary: {
      main: '#FA4616',
      hover: '#004EA8',
    },
    menu: {
      main: '#40064a',
      contrastText: '#fff',
    },
    success: {
      main: '#78BE20',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#fff',
    },
    allColors: {
      red: '#C10016',
      orange: '#FA4616',
      yellow: '#ECE81A',
      lightgreen: '#78BE20',
      darkgreen: '#257226',
      lightblue: '#4C9FC8',
      blue: '#004EA8',
      gray: '#1D252D',
      violet: '#40064a',
    },
  },
  components: {
    MouiCssBaseline: {
      syleOverrides: `
        @font-face {
          font-color: '#1D252D',
        }
      `
    }
  }
});

export default responsiveFontSizes(theme);
