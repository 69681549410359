import styled from '@emotion/styled'
import { Save } from '@mui/icons-material'
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Link, OutlinedInput, Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Header from '../../components/Header'
import WebsiteIcon from '../../components/icons/website'

import { createWebsite, validateWebsite } from '../../utilis/api'
import { labelsEn } from '../../utilis/labelsEn'
import { labelsIt } from '../../utilis/labelsIt'

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
}));

const CreationPaper = styled(Paper)({
  padding: '8px',
  background: 'rgba(120,190,32,0.2)',
});

const TokenControl = styled(FormControl)({
  left: '25%',
  width: '50%'
})

const SuccessButton = styled(Button)({
  float: 'right',
  background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
})

const WebsiteFromPlugin = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [errorName, setErrorName] = useState();
  const [creationAnswer, setCreationAnswer] = useState();

  let stateLab = {}

  var labels = labelsEn;
  const lng = localStorage.getItem("lng");
  if (lng.substring(0, 2) === 'it')
    labels = labelsIt;

  labels.map((lab) => {
    stateLab[lab] = false;
  })

  const [check, setCheck] = useState(stateLab);

  const getLabels = () => {
    let labels = []
    for (let [key, value] of Object.entries(check)) {
      if (value) {
        labels.push(key);
      }
    }

    return labels;
  }

  const handleChange = (event) => {
    setCheck({
      ...check,
      [event.target.name]: event.target.checked,
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const platform = searchParams.get("platform");
  const website = searchParams.get("website");
  const publicToken = searchParams.get("public_token");
  const privateToken = searchParams.get("private_token");
  const items = searchParams.get("items_file");
  const users = searchParams.get("users_file");
  const interactions = searchParams.get("interactions_file");

  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const onSubmitHook = (values, { setSubmitting }) => {

    setSubmitting(true);

    var labelsJson = JSON.stringify(getLabels());
    if (lng.substring(0, 2) === 'it') {
        for (var i = labelsEn.length - 1; i >= 0; i--) {
            labelsJson = labelsJson.replace(labelsIt[i], labelsEn[i]);
        }
    }
    var labelsNew = JSON.parse(labelsJson);

    const data = {
      name: website,
      type: platform,
      public_token: publicToken,
      private_token: privateToken,
      items_url: items,
      users_url: users,
      interactions_url: interactions,
      visualsearch_classes: labelsNew
    }

    validateWebsite(website, currentUser)
      .then((res) => {
        if (res.data.unique) {
          createWebsite(currentUser, data, dispatch)
            .then((res) => {
              setCreationAnswer(res.data)
            });
        } else {
          setErrorName(true);
        }
      })

    setSubmitting(false);
  }

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title='Add website' IconComponent={WebsiteIcon} />
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>

        <Box flex={6}>
          <Container component="section" maxWidth="md" sx={{ marginTop: "50px", marginBottom: "50px" }}>
            <Formik
              enableReinitialize={true}
              initialValues={{ webname: website, platform: platform }}
              onSubmit={onSubmitHook}
            >
              {({ values, errors, touched }) => (
                <Form>

                  <Grid container spacing={2}>
                    <Grid item xs={12} marginBottom={3}>
                      <Typography variant="h4">
                        {t('Connect a new website to Visidea')}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h7" marginBottom={3}>
                        {t('Website name')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="webname"
                        component={TextField}
                        variant="outlined"
                        disabled
                        fullWidth
                        id="name"
                        label={t('Website URL')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h7" marginBottom={3}>
                        {t('Platform type')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="platform"
                        component={TextField}
                        variant="outlined"
                        disabled
                        fullWidth
                        id="plaform"
                        label={t('Platform type')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h7" marginBottom={5}>
                        {t('Select one or more product categories')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      
                      <FormGroup>
                      {
                          Object.keys(check).map(key => 
                            <FormControlLabel
                          control={
                            <Checkbox 
                              checked={check[key]} 
                              onChange={handleChange} 
                              name={key}
                              key={key}
                            />
                          }
                          label={key}
                        />
                          )
                        }
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <Submit
                        type="submit"
                        fullWidth
                        variant="contained"
                        startIcon={<Save />}
                      >
                        {t('Connect')}
                      </Submit>

                      <Grid container justifyContent="center">
                        <Grid item>

                          <div style={{ visibility: errorName ? 'visible' : 'hidden', color: 'red', paddingTop: '15px' }}>
                            <Alert severity="error">
                              {errors ? t('There is already a website registered with this name') : ""}
                            </Alert>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                </Form>
              )}
            </Formik>
            {
              creationAnswer ?
                <CreationPaper elevation={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {t('Creation successfull')}!
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                          <Typography variant="h6">
                            Now let's finish the configuration:
                          </Typography>
                        </Grid> */}
                    <Grid item xs={12}>
                      <Typography variant="h7">
                        {t('You have completed the integration of Visidea with your website')}. {t('Please check the website configuration to ensure the algorithms in every sections of your website corresponds to your business needs')}.
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                          <TokenControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-pubtoken">Public Token</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-pubtoken"
                              type="text"
                              value={creationAnswer.public_token}
                              disabled
                              label="Public Token"
                            />
                          </TokenControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TokenControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-privtoken">Private Token</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-privtoken"
                              type="text"
                              value={creationAnswer.private_token}
                              disabled
                              label="Private Token"
                            />
                          </TokenControl>
                        </Grid> */}
                    <Grid item xs={12}>
                      <Typography variant="h7">
                      {t('All done')}, {creationAnswer.name} {t('is up and running, let\'s check it out')}!
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SuccessButton
                        variant="contained"
                        href={'/website/kpi'}
                      >
                        {t('Go to dashboard')}
                      </SuccessButton>
                    </Grid>
                  </Grid>
                </CreationPaper>
                :
                <></>
            }
          </Container>
        </Box>

      </Container>
    </Box>

  )
}

export default WebsiteFromPlugin