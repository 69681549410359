import styled from '@emotion/styled';
import { Alert, Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react'
import { passwordRequest } from '../../utilis/api';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)({
    margin: "24px 0px 16px",
    width: '50%',
    left: '50%',
});

const RecoverPassword = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [errors, setErrors] = useState({});

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);
        
        setErrors(await passwordRequest(values.username));
        
        setSubmitting(false);
    }

    return (
        <Formik
            initialValues={{ username: ''}}
            validate={values => {
                // Your client-side validation logic
            }}
            onSubmit={onSubmitHook}
        >
            {({ isSubmitting }) => (
                <Form style={{
                    width: '100%',
                    margin: '24px 0px'
                }}>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <Field
                                type="email"
                                name="username"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label={t('E-mail Address')}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="left" sx={{marginTop: "8px"}}>
                        <Grid item>
                            <Link to="/login" style={{ color: "inherit" }}>
                                {t('Back to login')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <Submit
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{
                                    background: "linear-gradient(45deg, #78BE20 30%, #257226 90%)"
                                }}
                            >
                                {t('Send e-mail')}
                            </Submit>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={8}>
                            <div style={{ visibility: errors.status === 'error' ? 'visible' : 'hidden', color: 'red' }}>
                                <Alert severity="error">
                                    {errors.status ? t('Incorrect email') : ""}
                                </Alert>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div style={{ visibility: errors.done ? 'visible' : 'hidden', color: 'green' }}>
                                <Alert severity="success">
                                    {errors.done ? t('Password reset email sent') : ""}
                                </Alert>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default RecoverPassword