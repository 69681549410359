import { createSvgIcon } from "@mui/material"


const HelpIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M20.21,7.92a12.68,12.68,0,0,0-9.62,4.32,1.87,1.87,0,0,0-.5,1.33,1.69,1.69,0,0,0,.56,1.22l2.17,2.13A1.74,1.74,0,0,0,15,17a7,7,0,0,0,.79-.63,5,5,0,0,1,3.55-1.56c1.54,0,2.62.84,2.62,2,0,1-1.18,2-2.43,3.05-1.72,1.46-3.86,3.27-3.86,6v1.9a1.86,1.86,0,0,0,1.86,1.86H21.2a1.87,1.87,0,0,0,1.91-1.95V26.29c0-.64,1.11-1.51,2.17-2.36,2-1.57,4.71-3.72,4.71-7.41C30,11.7,25.69,7.92,20.21,7.92ZM24,22.37c-1.51,1.19-2.93,2.32-2.93,3.92v1.44H17.68V26c0-1.85,1.53-3.14,3.15-4.51S24,18.81,24,16.88s-1.59-4-4.62-4a7,7,0,0,0-4.87,2.06c-.16.14-.31.27-.46.38l-1.86-1.81a10.73,10.73,0,0,1,8.05-3.56c4.36,0,7.78,2.9,7.78,6.6C28,19.25,25.89,20.9,24,22.37Z"/>
        <path d="M20,1.25A17.52,17.52,0,0,0,16.66,36a3.38,3.38,0,0,0,3.08,2.76H20a3.41,3.41,0,0,0,.25-6.82A3.35,3.35,0,0,0,16.88,34a15.53,15.53,0,1,1,12.25-2.64,1,1,0,0,0-.22,1.4A1,1,0,0,0,30.3,33,17.53,17.53,0,0,0,20,1.25Zm-.92,33a1.41,1.41,0,0,1,.92-.34h.11a1.41,1.41,0,0,1,1.3,1.51,1.41,1.41,0,1,1-2.82-.21A1.41,1.41,0,0,1,19.08,34.26Z"/>
    </svg>,
    'HelpIcon'
)

export default HelpIcon