import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useTranslation, Trans } from 'react-i18next';
import { convertTZ } from '../../utilis/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardChart = ({ data, period }) => {
  const { t, i18n } = useTranslation(['translation']);

  let labels = [];
  let timeData = [];

  data.forEach((x, i) => {
    let tzDate = convertTZ(x.date, Intl.DateTimeFormat().resolvedOptions().timeZone)

    if (period === 'day') {

      let date = (tzDate.split('T')[1]).split(':');
      labels.push(date[0] + ':' + date[1]);
    } else {

      let date = (tzDate.split('T')[0]).split('-');
      labels.push(date[1] + '/' + date[2]);
    }
  });

  data.forEach((x, i) =>
    timeData.push(x.total)
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t('Total by period'),
      },
    },
  };

  let dataChart = {
    labels,
    datasets: [
      {
        label: t('revenues'),
        data: timeData,
        borderColor: 'rgb(0, 78, 168)',
        backgroundColor: 'rgba(0, 78, 168, 0.5)',
      },
    ],
  };

  return <Line options={options} data={dataChart} />;
}

export default DashboardChart