import { Button } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import PaymentDataForm from './Forms/PaymentDataForm'
import Header from './Header'

import PremiumIcon from './icons/premium'

import { useTranslation, Trans } from 'react-i18next';

const Checkout = () => {
    const { t, i18n } = useTranslation(['translation']);

    const location = useLocation();
    const { priceId } = location.state;
    const { title } = location.state;
    const { planPrice } = location.state;
    
    const { currentUser } = useSelector((state) => state.user);

    return (
        <Box flex={4} p={{ xs: 0 }}>
            <Header title={t('Premium plan')} IconComponent={PremiumIcon} />
            <Container component="section" maxWidth="md" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <PaymentDataForm 
                    priceId={priceId}
                    userId={currentUser._id}
                    email={currentUser.username}
                    planPrice={planPrice}
                />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginTop: '10px' }}>
                    <Link to='/plan'>
                        <Button sx={{ mr: 1 }}>
                            {t('Back')}
                        </Button>
                    </Link>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* <Button>
                        Next
                    </Button> */}
                </Box>
            </Container>
        </Box>
    )
}

export default Checkout