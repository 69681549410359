import { Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import DeleteWebsite from './Forms/WebsiteDelete'
import SettingsWebsiteForm from './Forms/WebsiteSettingsForm'
import Header from './Header'

import SettingsIcon from './icons/settings'

import { useTranslation, Trans } from 'react-i18next';

const SettingsWebsite = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Settings')} IconComponent={SettingsIcon}/>
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item sm={12}>
            <Typography variant="h4" content="h2">
              {t('Website settings')}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <SettingsWebsiteForm />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h4" content="h2">
              {t('Danger zone')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
           <Typography align='center' marginTop={"7px"}>
                {t('Delete the website and remove completely all the data')}.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
          </Grid>
          <Grid item xs={12} md={6} align='center'>
            <DeleteWebsite />
          </Grid>
        </Grid>
        </Container>
    </Box>
  )
}

export default SettingsWebsite