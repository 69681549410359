import axios from "axios";
import { signInWithPopup } from "firebase/auth";

import { loginFailure, loginStart, loginSuccess, updateUser } from "../redux/userSlice";
import { fetchStart, fetchSuccess, fetchFailure, changeCurrentWebsite } from "../redux/websiteSlice";
import { authHeader } from "./auth";
import { auth, goProvider, fbProvider, apProvider } from "./firebase";

const API = 'https://api.visidea.ai/';
// const API = 'https://test-api.visidea.ai/';


export const login = async (data, dispatch) => {
    dispatch(loginStart);
    try {
        const res = await axios.post(`${API}accounts/authenticate`, data);
        window.dataLayer.push({ 'event': 'login', 'userId': res.data._id });
        dispatch(loginSuccess(res.data))
        return false;
    } catch (err) {
        console.log(err);
        dispatch(loginFailure);
        return true;
    }
}


export const signInWithGoogle = async (dispatch) => {
    dispatch(loginStart);
    try {
        const res = signInWithPopup(auth, goProvider)
            .then((result) => {
                //console.log(result)
                let displayName = "Name Surname"
                if (result.user.displayName)
                    displayName = result.user.displayName;
                else if (result.user.providerData[0].displayName)
                    displayName = result.user.providerData[0].displayName;
                let name = displayName.split(" ");
                axios.post(`${API}accounts/googleauth`, {
                        firstname: name[0],
                        lastname: name[1],
                        username: result.user.email,
                        phone: result.user.phoneNumber,
                    })
                    .then((res) => {
                        if (res.data.register)
                            window.dataLayer.push({ 'event': 'sign_up', 'userId': res.data._id });
                        else
                            window.dataLayer.push({ 'event': 'login', 'userId': res.data._id });
                        dispatch(loginSuccess(res.data));
                    });
            })
            .catch((err) => {
                console.log(err);
                return true;
            });
        return res;
    } catch (err) {
        console.log(err);
        dispatch(loginFailure);
        return true;
    }
  };

export const signInWithFacebook = async (dispatch) => {
    dispatch(loginStart);
    try {
        const res = signInWithPopup(auth, fbProvider)
            .then((result) => {
                //console.log(result)
                let displayName = "Name Surname"
                if (result.user.displayName)
                    displayName = result.user.displayName;
                else if (result.user.providerData[0].displayName)
                    displayName = result.user.providerData[0].displayName;
                let name = displayName.split(" ");
                axios.post(`${API}accounts/googleauth`, {
                        firstname: name[0],
                        lastname: name[1],
                        username: result.user.email,
                        phone: result.user.phoneNumber,
                    })
                    .then((res) => {
                        if (res.data.register)
                            window.dataLayer.push({ 'event': 'sign_up', 'userId': res.data._id });
                        else
                            window.dataLayer.push({ 'event': 'login', 'userId': res.data._id });
                        dispatch(loginSuccess(res.data));
                    });
            })
            .catch((err) => {
                console.log(err);
                return true;
            });
        return res;
    } catch (err) {
        console.log('login error:' + err);
        dispatch(loginFailure);
        return true;
    }
  };

export const signInWithApple = async (dispatch) => {
    dispatch(loginStart);
    try {
        apProvider.addScope('name');
        const res = signInWithPopup(auth, apProvider)
            .then((result) => {
                //console.log(result)
                let displayName = "Name Surname"
                if (result.user.displayName)
                    displayName = result.user.displayName;
                else if (result.user.providerData[0].displayName)
                    displayName = result.user.providerData[0].displayName;
                let name = displayName.split(" ");
                axios.post(`${API}accounts/googleauth`, {
                        firstname: name[0],
                        lastname: name[1],
                        username: result.user.email,
                        phone: result.user.phoneNumber,
                    })
                    .then((res) => {
                        if (res.data.register)
                            window.dataLayer.push({ 'event': 'sign_up', 'userId': res.data._id });
                        else
                            window.dataLayer.push({ 'event': 'login', 'userId': res.data._id });
                        dispatch(loginSuccess(res.data));
                    });
            })
            .catch((err) => {
                console.log(err);
                return true;
            });
        return res;
    } catch (err) {
        console.log(err);
        dispatch(loginFailure);
        return true;
    }
  };

export const register = async (data, dispatch) => {
    try {
        await axios.post(`${API}accounts/register`, data)
            .then((res) => {
                const dataLog = {
                    username: data.username,
                    password: data.password
                }
                window.dataLayer.push({ 'event': 'sign_up', 'userId': res.data._id });
                window.po('customers', 'signup', {
                    data: {
                        key: res.data._id,
                        name: data.firstname,
                        surname: data.lastname,
                        email: data.username
                    }
                });    
                login(dataLog, dispatch);
            })
        return false;
    } catch (err) {
        console.log(err);
        return true;
    }
}

export const updateUserInformations = async (data, currentUser) => {
    try {
        const res = await axios.put(`${API}accounts/current`, data, { headers: authHeader(currentUser) })
        //console.log(res.data);
    } catch (err) {
        console.log(err);
    }
}

export const getUserInformations = async (currentUser, dispatch) => {
    try {
        const res = await axios.get(`${API}accounts/current`, { headers: authHeader(currentUser) })
        dispatch(updateUser(res.data))
        return res;
    } catch (err) {
        console.log(err);
    }
}

export const passwordRequest = async (data) => {
    try {
        const res = await axios.post(`${API}accounts/resetpassword`, { username: data })

        return {
            status: res.data.status,
            done: res.data.status === 'error' ? false : true
        };
    } catch (err) {
        console.log(err);
        return true;
    }
}

export const passwordReset = async (data) => {
    try {
        const res = await axios.post(`${API}accounts/resetpasswordsave`, data)
        return {
            status: res.data.status,
            done: res.data.status === 'error' ? false : true
        };
    } catch (err) {
        console.log(err);
        return true;
    }
}

export const createWebsite = async (currentUser, data, dispatch) => {
    dispatch(fetchStart);
    try {
        const website = await axios.post(`${API}websites`, data, { headers: authHeader(currentUser) });
        window.dataLayer.push({ 'event': 'save_new_website', 'userId': website.data.account_id });
        dispatch(fetchSuccess(website.data));

        return website;
    } catch (err) {
        dispatch(fetchFailure);
    }
}

export const fetchWebsitesFirst = async (currentUser, dispatch) => {
    dispatch(fetchStart);
    try {
        const websites = await axios.get(`${API}websites`, { headers: authHeader(currentUser) });
        dispatch(fetchSuccess(websites.data[0]));
        return websites;
    } catch (err) {
        dispatch(fetchFailure);
    }
}

// Da eliminare quando si avrà campo "sito corrente" in mongo
export const fetchWebsites = async (currentUser) => {
    try {
        const res = await axios.get(`${API}websites`, { headers: authHeader(currentUser) });
        return res;
    } catch (err) {
        console.log(err);
    }
}

export const fecthWebsite = async (webId, currentUser) => {
    try {
        const res = await axios.get(`${API}websites/${webId}`, { headers: authHeader(currentUser) });
        return res;
    } catch (err) {
        console.log(err);
    }
}

export const fecthDispWebsite = async (webId, currentUser, dispatch) => {
    try {
        const res = await axios.get(`${API}websites/${webId}`, { headers: authHeader(currentUser) });
        dispatch(fetchSuccess(res.data))

        return res;
    } catch (err) {
        dispatch(fetchFailure);
        console.log(err);
    }
}

export const fetchStatWebsite = async (currentUser, webId, startDate, endDate) => {
    try {
        const res = await axios
            .get(`${API}websites/${webId}/dashboard?date_from=${endDate}&date_to=${startDate}`,
                { headers: authHeader(currentUser) });

        return res;
    } catch (err) {
        console.log(err)
    }
}

export const fetchStatBestseller = async (currentUser, webId, period) => {
    try {
        const res = await axios
            .get(`${API}websites/${webId}/bestsellers?period=${period}`,
                { headers: authHeader(currentUser) });

        return res;
    } catch (err) {
        console.log(err)
    }
}

export const updateWebsite = async (webId, data, currentUser, dispatch) => {
    try {
        const res = await axios.put(`${API}websites/${webId}`, data, { headers: authHeader(currentUser) })
        dispatch(changeCurrentWebsite(res.data))
    } catch (err) {
        console.log(err);
    }
}

export const deleteWebsite = async (webId, currentUser) => {
    try {
        await axios.delete(`${API}websites/${webId}`, { headers: authHeader(currentUser) })

        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const validateWebsite = async (webName, currentUser) => {
    try {
        const res = await axios.get(`${API}websites/isUnique/${webName}`, { headers: authHeader(currentUser) })

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const fetchProduct = async (webId, prodId, currentUser) => {
    try {
        const res = await axios.get(`${API}items/${webId}/${prodId}`, { headers: authHeader(currentUser) })

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const fetchComplProducts = async (webId, complsId, currentUser) => {
    try {
        const promiseArray = complsId.map(c => fetchProduct(webId, c, currentUser));
        /*
        Promise.all(promiseArray)
            .then(ress => ress.map(res => console.log(res.data)))
        */
        const res = Promise.all(promiseArray);

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const fetchRules = async (webId, currentUser) => {
    try {
        const res = await axios.get(`${API}rules/${webId}`, { headers: authHeader(currentUser) })

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const fetchRulesV2 = async (webId, currentUser, limit, skip) => {
    try {
        const res = await axios.get(`${API}rules/v2/${webId}?limit=${limit}&skip=${skip}`, 
        { headers: authHeader(currentUser) })

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const addRule = async (webId, data, currentUser) => {
    try {
        const res = await axios.post(`${API}rules/${webId}`, data, { headers: authHeader(currentUser) })

        return res
    } catch (err) {

    }
}

export const updatesRule = async (webId, ruleId, data, currentUser) => {
    try {
        const res = await axios.put(`${API}rules/${webId}/${ruleId}`, data, { headers: authHeader(currentUser) })

        return res
    } catch (err) {

    }
}

export const deleteRule = async (webId, ruleId, currentUser) => {
    try {
        await axios.delete(`${API}rules/${webId}/${ruleId}`, { headers: authHeader(currentUser) })

        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const searchProducts = async (currentUser, webId, query) => {
    try {
        let res = null;
        if (query !== null) {
            res = await axios.get(`${API}items/${webId}/search?q=${query}&page=1`,
                { headers: authHeader(currentUser) })
        } else {
            res = await axios.get(`${API}items/${webId}/search?q=&page=1`,
                { headers: authHeader(currentUser) })
        }

        return res;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const fetchMembers = async (currentUser) => {
    try {
        const res = await axios.get(`${API}accounts/team/`,{ headers: authHeader(currentUser) })

        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const createMember = async (data, currentUser) => {
    try {
        const res = await axios.post(`${API}accounts/team/`, data, { headers: authHeader(currentUser) })

        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updateMember = async (accId, data, currentUser) => {
    try {
        const res = await axios.put(`${API}accounts/team/${accId}`, data, { headers: authHeader(currentUser) })

        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteMember = async (accId, currentUser) => {
    try {
        await axios.delete(`${API}accounts/team/${accId}`, { headers: authHeader(currentUser) })

        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}


export const fetchVsImages = async (currentUser, currentWebsite, limit, skip) => {
    try {
        const res = await axios.get(`${API}visualsearches/${currentWebsite}?limit=${limit}&skip=${skip}`,
            { headers: authHeader(currentUser) })

        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getInitProgress = async (website_id, currentUser) => {
    try {
        const res = await axios.get(`${API}websites/${website_id}/initprogress`, { headers: authHeader(currentUser) })

        return res;
    } catch (error) {
        console.log(error);
        return false;
    }
}