import { Search } from '@mui/icons-material'
import { Checkbox, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { searchProducts } from '../utilis/api';

import { useTranslation, Trans } from 'react-i18next';

const SelectComplementaryDialog = ({ open, complSel, handleClose, targetId }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [checked, setChecked] = useState([]);
    const [query, setQuery] = useState();
    const [products, setProducts] = useState([]);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleQuery = (event) => {
        setQuery(event.target.value);
        setChecked([]);
    }

    const submitTarget = () => {
        complSel(checked.map(x => products[x]));
        handleClose();
    }

    useEffect(() => {
        const fetchProds = () => {
        console.log(query)
          searchProducts(currentUser, currentWebsite.id, query)
            .then((res) => {
                setProducts(res.data.filter(prod => prod.id !== targetId));
                console.log(res.data)
            })
        }
        fetchProds();
      }, [currentWebsite, currentUser, query, targetId]);

    return (
        <Dialog
            open={open}
            key={targetId}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
            aria-labelledby="alert-dialog-title-complementary"
            aria-describedby="alert-dialog-description-complementary"
        >
            <DialogTitle id="alert-dialog-title-complementary">
                {t('Select recommendations')}
            </DialogTitle>
            <DialogContent style={{paddingTop: '10px'}}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('Search')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-complementary"
                        type='text'
                        value={query}
                        onChange={(event) => handleQuery(event)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search recommended product"
                                    //onClick={handleClickShowPassword}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('Search complementary')}
                    />
                </FormControl>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {/*
                        <List dense sx={{ width: '100%', maxWidth: '75%', bgcolor: 'background.paper' }}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                                <ListItem
                                    key={value}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={checked.indexOf(value) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Avatar n°${value + 1}`}
                                                src={`/static/images/avatar/${value + 1}.jpg`}
                                                sx={{ width: 65, height: 65 }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText sx={{ paddingLeft: '10px' }} id={labelId} primary={`Line item ${value + 1}`} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    */}
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {products.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                                <ListItem
                                    key={value.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(index)}
                                            checked={checked.indexOf(index) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={handleToggle(index)}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Avatar n°${index + 1}`}
                                                src={value.images !== null ? value.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_-Ss-poGbiYUaFQVFP_0ZB27I2zFkClPuDgh2akMzMg&s'}
                                                sx={{ width: 65, height: 65 }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText sx={{ paddingLeft: '10px' }} id={labelId} primary={value.name} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <Button onClick={submitTarget}>{t('Select')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SelectComplementaryDialog