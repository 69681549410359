import { Button, Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Header from './Header'
import WebsiteIcon from './icons/website'

import WebsiteCssConfigurationForm from './Forms/WebsiteCssConfigurationForm'
import VisualSearchCard from './ConfigurationCards/VisualSearchCard'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';

const WebsiteCssConfigurations = () => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentWebsite } = useSelector((state) => state.website);

    return (

        <Box flex={4} p={{ xs: 0 }}>
            <Header title={t('CSS settings')} IconComponent={WebsiteIcon} />
            <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} lg={9} marginBottom={7}>
                        <Typography align='left' variant='h6' marginBottom="10px">
                            {t('CSS settings')}
                        </Typography>
                        <WebsiteCssConfigurationForm
                            code={JSON.parse(currentWebsite.settings)['css']}
                        />
                    </Grid>

                    <Grid item sm={3} sx={{ display: {xs: "block", lg: "none"} }}/>

                    <Grid item xs={12} sm={6} lg={3}>
                        <VisualSearchCard />
                    </Grid>

                </Grid>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2, marginTop: '10px' }}>
                    <Link to='/website/configurations'>
                        <Button sx={{ mr: 1 }}>
                            {t('Back')}
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>

    )
}

export default WebsiteCssConfigurations