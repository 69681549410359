import { Container, FormControl, Grid, InputLabel, MenuItem, Typography, Select, Fab, Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import WebsiteNewForm from './Forms/WebsiteNewForm'
import Header from './Header'
import WebsiteCreateStepper from './WebsiteCreateStepper'
import WebsiteIcon from './icons/website'
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';


const NewWebsite = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [platform, setPlatform] = useState('');

  const handleChange = (event) => {
    setPlatform(event.target.value);
  };

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('New website')} IconComponent={WebsiteIcon} />
      <Container component="section" maxWidth="md" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item sm={12}>
            <Typography variant="h4" content="h2">
              {t('Add a new website')}
            </Typography>
            <Typography sx={{ paddingTop: "7px" }}>
              {t('Select your website platform to start')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>{t('newWebsiteSelectLabel')}</InputLabel>
              <Select
                labelId=""
                id=""
                value={platform}
                label={t('newWebsiteSelectLabel')}
                onChange={handleChange}
              >
                <MenuItem value={'shopify'}>Shopify</MenuItem>
                <MenuItem value={'prestashop'}>Prestashop</MenuItem>
                <MenuItem value={'magento'}>Magento</MenuItem>
                <MenuItem value={'woocommerce'}>WordPress/WooCommerce</MenuItem>
                <MenuItem value={'madcommerce'}>Madcommerce</MenuItem>
                <MenuItem value={'custom'}>Custom</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>

              {platform === 'custom' || platform === 'madcommerce' ? <WebsiteNewForm /> : <></> }
              {platform === 'shopify' || platform === 'prestashop' || platform === 'magento' || platform === 'woocommerce' ? 
                <>
                  <Typography variant="h6" sx={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    {t('Watch the tutorial or follow the instructions below')}
                  </Typography>

                  {platform === 'shopify' ?
                    <Grid container spacing={3} alignItems="stretch">
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" content="h5" sx={{ paddingTop: "15px" }}>
                          {t('Get more information')}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/UcAjeMzln4g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <a href="https://docs.visidea.ai/docs/plugins/shopify" target="_blank" style={{color:'#000000'}}>{t('Read more on the documentation website')}</a>
                      </Grid> */}
                    </Grid>
                  :
                    <></>
                  }
                  {platform === 'prestashop' ?
                    <Grid container spacing={3} alignItems="stretch">
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" content="h5" sx={{ paddingTop: "15px" }}>
                          {t('Get more information')}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/a1sVXvxxQOU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <a href="https://docs.visidea.ai/docs/plugins/prestashop" target="_blank" style={{color:'#000000'}}>{t('Read more on the documentation website')}</a>
                      </Grid> */}
                    </Grid>
                  :
                    <></>
                  }
                  {platform === 'magento' ?
                    <Grid container spacing={3} alignItems="stretch">
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" content="h5" sx={{ paddingTop: "15px" }}>
                          {t('Get more information')}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/BdPL6HIXXOE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <a href="https://docs.visidea.ai/docs/plugins/magento" target="_blank" style={{color:'#000000'}}>{t('Read more on the documentation website')}</a>
                      </Grid> */}
                    </Grid>
                  :
                    <></>
                  }
                  {platform === 'woocommerce' ?
                    <Grid container spacing={3} alignItems="stretch">
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" content="h5" sx={{ paddingTop: "15px" }}>
                          {t('Get more information')}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ehp_cbnbcXM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <a href="https://docs.visidea.ai/docs/plugins/wordpress" target="_blank" style={{color:'#000000'}}>{t('Read more on the documentation website')}</a>
                      </Grid> */}
                    </Grid>
                  :
                    <></>
                  }
                  <WebsiteCreateStepper platform={platform} />
                </>
              :
                <></>
              }
          </Grid>
        </Grid>

        <Fab sx={{ position: 'fixed', bottom: 35, right: 55 }} variant="extended" >
          <Button 
            size="small" 
            href='https://tidycal.com/visideaai/30min'
            target="_blank"
          >
            {t('Book your demo call')}
          </Button>
        </Fab>

      </Container>
    </Box>
  )
}

export default NewWebsite