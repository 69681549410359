const data = {
    state: '',
    city: '',
    zip: '',
    companyname: '',
    vatid: '',
    fiscalcode: '',
    sdi: '',
    cemail: '',
    phone: '',
    iscompany: 'true',
    country: '',
    address1: '',
    address2: ''
}

export const initStorage = () => {
    if (JSON.parse(localStorage.getItem('paymentData')) == null) {
        localStorage.setItem('paymentData', JSON.stringify(data));
    }
}

export const setPaymentField = (key, value) => {
    let data = JSON.parse(localStorage.getItem('paymentData'));
    data[key] = value;

    localStorage.setItem('paymentData', JSON.stringify(data));
}

export const getPaymentField = (key) => {
    const data = JSON.parse(localStorage.getItem('paymentData'));
    return data[key];
}

export const getPaymentData = () => {
    const data = JSON.parse(localStorage.getItem('paymentData'));
    return data;
}

export const updatePaymentData = (data) => {
    localStorage.setItem('paymentData', JSON.stringify(data));
}

export const printPaymentData = () => {
    console.log(JSON.parse(localStorage.getItem('paymentData')));
}