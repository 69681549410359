import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

import { useTranslation, Trans } from 'react-i18next';

const AlgorithmCard = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Card>
      <CardMedia
        component="img"
        src="https://www.protocol.com/media-library/lines-of-code.jpg?id=29746573&width=1245&height=700&quality=85&coordinates=0%2C1156%2C0%2C1156"
        alt=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t('Widget configuration')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('Read our guide to learn how to setup the algorithms and other topics related to the widget')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
          size="small" 
          href='https://docs.visidea.ai/docs/app/configuration/#widget-configuration'
          target="_blank"
        >
          {t('Learn More')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default AlgorithmCard