import { Cancel } from '@mui/icons-material'
import { Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const ProductCard = ({ prod, cancel }) => {
  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            {prod.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {prod.price}€
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <IconButton 
              aria-label="cancel"
              onClick={cancel}
          >
            <Cancel />
          </IconButton>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 175 }}
        image={prod.images !== null ? prod.images[0] : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_-Ss-poGbiYUaFQVFP_0ZB27I2zFkClPuDgh2akMzMg&s'}
      />
    </Card>
  )
}

export default ProductCard