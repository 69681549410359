import { Box, Button, ButtonGroup, CircularProgress, Fab, Grid, TextField, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import dayjs from 'dayjs';
import Charts from '../components/charts/Charts';
import SummaryTable from '../components/tables/SummaryTable';
import { useSelector } from 'react-redux';
import { fetchStatWebsite, getInitProgress } from '../utilis/api';

import StatsIcon from '../components/icons/stats'
import ChartsDrag from '../components/charts/ChartsDrag';
import SidebarKpiConf from '../components/kpi/SidebarKpiConf'

import { useTranslation, Trans } from 'react-i18next';
import { Settings } from '@mui/icons-material';
import { checkModelsStatus } from '../utilis/utils';
import NewWebsiteLoading from '../components/Loading/NewWebsiteLoading';

const Dashboard = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().subtract(7, 'day'));

    const [disable, setDisable] = useState(true);
    const [endError, setEndError] = useState(false);
    const [startError, setStartError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(null)

    const [stats, setStats] = useState(null);
    const [open, setOpen] = useState(false);

    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const handleClose = () => {
        setOpen(false);
    }

    const handleLastPeriod = (period) => {
        var now = dayjs();
        setStartDate(now);
        setEndDate(now.subtract(period, 'day'));
    }

    const handleEndDate = (date) => {
        if (!date.isAfter(startDate)) {
            setEndDate(date);
            setEndError(false);
        } else {
            setEndError(true);
        }
    }

    const handleStartDate = (date) => {
        if (date.isAfter(endDate)) {
            setStartDate(date);
            setStartError(false);
        } else {
            setStartError(true);
        }
    }

    useEffect(() => {
        const fetchStats = () => {
            if (startDate !== null && endDate !== null) {
                setLoading(true);
                fetchStatWebsite(currentUser, currentWebsite.id,
                    startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
                    .then((res) => {
                        console.log(res.data);
                        setStats(res.data);
                        setLoading(false);
                    })
            }
        }
        fetchStats();
    }, [currentWebsite, currentUser, startDate, endDate]);

    useEffect(() => {
        getInitProgress(currentWebsite._id, currentUser)
            .then((res) => { setProgress(res.data) })

        const intervalID = setInterval(() => {
            getInitProgress(currentWebsite._id, currentUser)
                .then((res) => { setProgress(res.data) })
        }, 10000);

        return () => clearInterval(intervalID);
    }, []);

    return (
        <Box flex={4} p={{ xs: 0 }}>
            <Header title='KPIs' IconComponent={StatsIcon} />
            {
                !checkModelsStatus(progress, currentWebsite) ?
                        <NewWebsiteLoading progress={progress} />
                    :
                    <>
                        <Grid container marginTop={5}>
                            <Grid item xs={12} lg={6} padding={1}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <ButtonGroup variant="outlined" size="large" aria-label="large button group">
                                        <Button onClick={() => handleLastPeriod(7)}>{t('Last Week')}</Button>
                                        <Button onClick={() => handleLastPeriod(30)}>{t('Last Month')}</Button>
                                        <Button onClick={() => handleLastPeriod(365)}>{t('Last Year')}</Button>
                                    </ButtonGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6} padding={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('Start Date')}
                                                    value={endDate}
                                                    // disabled={disable}
                                                    onChange={(newValue) => {
                                                        handleEndDate(newValue)
                                                    }}
                                                    renderInput={(params) => <TextField {...params}

                                                        error={endError}
                                                        helperText={endError ? t('select a date in the past') : ''} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('End Date')}
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        handleStartDate(newValue)
                                                        setDisable(false);
                                                    }}
                                                    renderInput={(params) => <TextField {...params}

                                                        error={startError}
                                                        helperText={startError ? t('choose a date in the future than the start date') : ''} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {loading || !stats ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} paddingTop='15%'>
                                <CircularProgress size={150} />
                            </Box>) : (
                            <>
                                <SidebarKpiConf open={open} close={handleClose} />
                                <Charts stats={stats} />
                                {/*<ChartsDrag stats={stats} />*/}


                            </>
                        )}
                        <Fab sx={{ position: 'fixed', bottom: 35, right: 55 }} onClick={() => setOpen(true)}>

                            <Tooltip title='settings KPI'>
                                <Settings />
                            </Tooltip>
                        </Fab>
                    </>
            }
        </Box>
    )
}

export default Dashboard