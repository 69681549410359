
export const dataRecommendationsChart = (data, labels) => {
  
  return {
    labels: [labels.visualsearch, labels.visualsimilarity, labels.itembased, labels.collaborativefiltering, labels.popularitems],
    datasets: [
      {
        label: labels.recommendationsbyalgorithm,
        data: [
          data.recommendations.visualsearch,
          data.recommendations.similarity,
          data.recommendations.items,
          data.recommendations.users,
          data.recommendations.pops,
        ],
        backgroundColor: [
          'rgb(250, 70, 22)',
          'rgb(236, 232, 26)',
          'rgb(120, 190, 32)',
          'rgb(76, 159, 200)',
          'rgb(0, 78, 168)',
        ],
      }
    ],
  };
}