import styled from '@emotion/styled';
import { QuestionMarkRounded } from '@mui/icons-material';
import { Button, Chip, FormControl, Switch, Grid, MenuItem, OutlinedInput, Paper, Typography, Snackbar, Alert, Tooltip, IconButton, Select, Input, InputLabel } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsite } from '../../utilis/api';
import CurrencyList from 'currency-list'
import { labelsEn } from '../../utilis/labelsEn'
import { labelsIt } from '../../utilis/labelsIt'

import { useTranslation, Trans } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Item = styled(Grid)({
    margin: '25px'
})

const WebsiteVisualConfigurationForm = ({ labelsNow, hasVS, hasSS, ssBind, vsShowafter, currencyValue, currencyFormatValue,
                                            vsGenderFilter, vsPriceFilter, vsCategoryFilter, vsBrandFilter, visimg }) => {

    const { t, i18n } = useTranslation(['translation']);

    const lng = localStorage.getItem("lng");

    const [activeVs, setActiveVs] = useState(hasVS);
    const [activeSs, setActiveSs] = useState(hasSS);
    const [visualsearchShowafter, setVisualsearchShowafter] = useState(vsShowafter);
    const [semanticsearchBind, setSemanticsearchBind] = useState(ssBind)
    const [labels, setLabels] = useState(labelsNow);
    const [currency, setCurrency] = useState(currencyValue);
    const [currencyFormat, setCurrencyFormat] = useState(currencyFormatValue);
    const [visualSrc, setVisualSrc] = useState(visimg)
    const [creation, setCreation] = useState(false);
    const [genderFilter, setGenderFilter] = useState(vsGenderFilter);
    const [priceFilter, setPriceFilter] = useState(vsPriceFilter);
    const [categoryFilter, setCategoryFilter] = useState(vsCategoryFilter);
    const [brandFilter, setBrandFilter] = useState(vsBrandFilter);

    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);
    console.log(currentWebsite)

    var names = labelsEn;
    if (lng.substring(0, 2) === 'it')
        names = labelsIt;

    useEffect(() => {
        var labelsJson = JSON.stringify(currentWebsite.visualsearch_classes);

        if (lng.substring(0, 2) === 'it') {
            for (var i = labelsEn.length - 1; i >= 0; i--) {
                labelsJson = labelsJson.replace(labelsEn[i], labelsIt[i]);
            }
        }
        var labelsNew = JSON.parse(labelsJson);

        setLabels(labelsNew);
        setActiveVs(currentWebsite.has_visualsearch);
        setActiveSs(currentWebsite.has_semanticsearch)
        setCurrency(currentWebsite.currency);
        setCurrencyFormat(currentWebsite.currency_format);
        setGenderFilter(currentWebsite.visualsearch_displaygenderfilter);
        setPriceFilter(currentWebsite.visualsearch_displaypricefilter);
        setCategoryFilter(currentWebsite.visualsearch_displaycategoryfilter);
        setBrandFilter(currentWebsite.visualsearch_displaybrandfilter);
    }, [currentWebsite])

    const handleChangeActiveVs = () => {
        setActiveVs(!activeVs);
    }

    const handleChangeActiveSs = () => {
        setActiveSs(!activeSs);
    }

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    }

    const handleCurrencyFormatChange = (event) => {
        setCurrencyFormat(event.target.value);
    }

    const handleChangeShowAfter = (event) => {
        setVisualsearchShowafter(event.target.value);
    }

    const handleChangeSsBind = (event) => {
        setSemanticsearchBind(event.target.value);
    }

    const handleChangeGenderFilter = () => {
        setGenderFilter(!genderFilter);
    }

    const handleChangePriceFilter = () => {
        setPriceFilter(!priceFilter);
    }

    const handleChangeCategoryFilter = () => {
        setCategoryFilter(!categoryFilter);
    }

    const handleChangeBrandFilter = () => {
        setBrandFilter(!brandFilter);
    }

    const handleChangeVisImg = (event) => {
        setVisualSrc(event.target.value);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setLabels(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = () => {
        let data = currentWebsite;

        var labelsJson = JSON.stringify(labels);
        if (lng.substring(0, 2) === 'it') {
            for (var i = labelsEn.length - 1; i >= 0; i--) {
                labelsJson = labelsJson.replace(labelsIt[i], labelsEn[i]);
            }
        }
        var labelsNew = JSON.parse(labelsJson);

        data = {
            ...data,
            has_visualsearch: activeVs,
            has_semanticsearch: activeSs,
            visualsearch_showafter: visualsearchShowafter,
            semanticsearch_bind :semanticsearchBind,
            visualsearch_classes: labelsNew,
            currency: currency,
            currency_format: currencyFormat,
            visualsearch_displaygenderfilter: genderFilter,
            visualsearch_displaypricefilter: priceFilter,
            visualsearch_displaycategoryfilter: categoryFilter,
            visualsearch_displaybrandfilter: brandFilter,
            visualsearch_image: visualSrc
        }

        updateWebsite(currentWebsite.id, data, currentUser, dispatch)
            .then((res) => {
                setCreation(true);
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCreation(false);
    };

    const allCurrencies = CurrencyList.getAll('en_US');

    return (
        <>
            <Paper elevation={3} spacing={2}>
                <Stack direction='column'>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Display VS')}
                                    <Tooltip title={t('Display or hide the Visual Search icon')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6} paddingLeft={1}>
                                <Switch
                                    checked={activeVs}
                                    onChange={handleChangeActiveVs}
                                    name="activeVs"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Show after')}
                                    <Tooltip title={t('Enter the CSS selector of the element after which you want to insert the Visual Search icon')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <FormControl fullWidth>
                                    <Input
                                        type="text"
                                        id="visualsearch-showafter"
                                        variant="standard"
                                        value={visualsearchShowafter}
                                        onChange={handleChangeShowAfter}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Plugin image')}
                                    <Tooltip title={t('The image that appears when opening the plugin')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <FormControl fullWidth>
                                    <Input
                                        type="text"
                                        id="visualsearch-img"
                                        placeholder='https://www.websitename.com/path/img'
                                        variant="standard"
                                        value={visualSrc}
                                        onChange={handleChangeVisImg}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Display SS')}
                                    <Tooltip title={t('Display or hide the Semantic Search icon')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6} paddingLeft={1}>
                                <Switch
                                    checked={activeSs}
                                    onChange={handleChangeActiveSs}
                                    name="activeSs"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Semantic search binding')}
                                    <Tooltip title={t('Enter the CSS selector of the element that activates the Semantic Search feature on click')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <FormControl fullWidth>
                                    <Input
                                        type="text"
                                        id="semantic-bind"
                                        variant="standard"
                                        value={semanticsearchBind}
                                        onChange={handleChangeSsBind}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Gender filter')}
                                    <Tooltip title={t('Display or hide the gender filter')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6}>
                                <Switch
                                    checked={genderFilter}
                                    onChange={handleChangeGenderFilter}
                                    name="genderFilter"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Price filter')}
                                    <Tooltip title={t('Display or hide the price filter')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6}>
                                <Switch
                                    checked={priceFilter}
                                    onChange={handleChangePriceFilter}
                                    name="priceFilter"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Category filter')}
                                    <Tooltip title={t('Display or hide the category filter')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6}>
                                <Switch
                                    checked={categoryFilter}
                                    onChange={handleChangeCategoryFilter}
                                    name="categoryFilter"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Brand filter')}
                                    <Tooltip title={t('Display or hide the brand filter')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={6}>
                                <Switch
                                    checked={brandFilter}
                                    onChange={handleChangeBrandFilter}
                                    name="brandFilter"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Product categories')}
                                    <Tooltip title={t('Website product categories, allows the AI to discover your products in Visual Search')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="currency-label" sx={{ background: "white", paddingRight: "8px" }}>{t('Product categories')}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={labels}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Currency')}
                                    <Tooltip title={t('Currency of your website')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="currency-label">{t('Currency')}</InputLabel>
                                        <Select
                                            labelId="currency-label"
                                            id="currency"
                                            value={currency || ''}
                                            label={t('Currency')}
                                            onChange={handleCurrencyChange}
                                        >
                                            {Object.keys(allCurrencies).map((currency) => (
                                                <MenuItem value={currency} key={currency}>{allCurrencies[currency].name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Format')}
                                    <Tooltip title={t('The format of the prices in your website')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12} paddingLeft={1}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="currency-format-label">{t('Format')}</InputLabel>
                                        <Select
                                            labelId="currency-format-label"
                                            id="currency-format"
                                            value={currencyFormat || ''}
                                            label={t('Format')}
                                            onChange={handleCurrencyFormatChange}
                                        >
                                            <MenuItem value="amountwithcomma_currency">1900,00 Euro</MenuItem>
                                            <MenuItem value="amountwithdot_currency">1900.00 Euro</MenuItem>
                                            <MenuItem value="currency_amountwithcomma">Euro 1900,00</MenuItem>
                                            <MenuItem value="currency_amountwithdot">Euro 1900.00</MenuItem>
                                            <MenuItem value="amountwithcomma_symbol">1900,00 €</MenuItem>
                                            <MenuItem value="amountwithdot_symbol">1900.00 €</MenuItem>
                                            <MenuItem value="symbol_amountwithcomma">€ 1900,00</MenuItem>
                                            <MenuItem value="symbol_amountwithdot">€ 1900.00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item>
                        <Grid container>
                            <Grid item sm={6} xs={12}>
                                <Button align='right' onClick={handleSubmit}>
                                    {t('save configuration')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Item>
                </Stack>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={creation}
                autoHideDuration={5000}
                onClose={handleClose}
                message={t('Configuration saved')}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('Configuration saved')}
                </Alert>
            </Snackbar>
        </>
    )
}

export default WebsiteVisualConfigurationForm