import styled from '@emotion/styled'
import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom';

import Header from '../components/Header'
import TimeLine from '../components/TimeLine'

import DashboardIcon from '../components/icons/dashboard'
import WebsiteFromPlugin from '../components/Forms/WebsiteFromPlugin'
import HomeDashboard from './HomeDashboard'
import WebsiteNew from "../components/WebsiteNew";
import { useSelector } from 'react-redux'

import { useTranslation, Trans } from 'react-i18next';

const Hr = styled("hr")({
  margin: "15px 0px",
  border: "0.5px solid #f5f5f5"
});

const LogoImage = styled("img")({
  width: '75%',
  marginTop: "24px"
})

const Home = () => {
  const { t, i18n } = useTranslation(['translation']);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("private_token");

  const { currentWebsite } = useSelector((state) => state.website);

  return (
    token || !currentWebsite ?
      <>
        {!token ?
          <WebsiteNew />
        :
          <WebsiteFromPlugin />
        }
      </>
      :
      <HomeDashboard />
  )
}

export default Home