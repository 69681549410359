import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import { array } from 'prop-types';

var quotes = ["Patience is a virtue; your AI companion is preparing to dazzle you!", 
                "Rome wasn't built in a day, and neither is the perfect AI response. Hang tight!", 
                "Loading dreams into reality, pixel by pixel.",
            "Behind every loading screen lies the promise of innovation. Get ready to be amazed!",
        "We are warming up the engines and pumping up the AI models!"]

const RandomQuote = () => {
    const [quoteAct, setQuote] = useState(quotes[Math.floor(Math.random()*quotes.length)])
    const [checked, setChecked] = useState(true);

    const quote = (
        <Paper sx={{ m: 1, width: 450, height: 350 }} elevation={0}>
            <Typography fontStyle='italic' variant='h3' align="center">{quoteAct}</Typography>
        </Paper>
    );

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    useEffect(() => {
        const intervalID = setInterval(() => {
            //setTimeout(() => {}, 2000);
            handleChange()
            setTimeout(() => {}, 700)
            setQuote(quotes[Math.floor(Math.random()*quotes.length)])
            
        }, 4500);

        return () => clearInterval(intervalID);
    },[])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex' }}>
                <Zoom in={checked} style={{ transitionDelay: checked ? '100ms' : '0ms' }}>
                    {quote}
                </Zoom>
            </Box>
        </Box>
    )
}

export default RandomQuote