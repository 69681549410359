import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import ProfileChangeInformation from '../components/Forms/ProfileChangeInformation'
import ProfileChangePassword from '../components/Forms/ProfileChangePassword'

import Header from '../components/Header'
import AccountIcon from '../components/icons/account'

import { useTranslation, Trans } from 'react-i18next';

const Account = () => {
  const { t, i18n } = useTranslation(['translation']);

  const { currentUser } = useSelector((state) => state.user);

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={`${currentUser.firstname}${t('\'s account')}`} IconComponent={AccountIcon}/>
      <Container component="section" maxWidth="md" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Grid container spacing={2} alignItems="stretch">

          <Grid item sm={12}>
            <Typography variant="h4" content="h2">
              {t('User\'s information')}
            </Typography>
          </Grid> 

          <ProfileChangeInformation user={currentUser} />

          <Grid item sm={12} sx={{ marginTop: "50px" }}>
            <Typography variant="h4" content="h2">
              {t('Password')}
            </Typography>
          </Grid> 

          <ProfileChangePassword user={currentUser}/>

        </Grid>
      </Container>
    </Box>
  )
}

export default Account