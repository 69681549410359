import { Chip, Divider, Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import Header from './Header'
import WebsiteConfigurationCard from './ConfigurationCards/WebsiteConfigurationsCard'
import AdvancedOptionCard from './ConfigurationCards/AdvancedOptionCard'

import WebsiteIcon from './icons/website';

import { useTranslation, Trans } from 'react-i18next';

const WebsiteConfigurationMenu = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Configuration')} IconComponent={WebsiteIcon}/>
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        <Typography variant="h6" marginBottom={5}>
          {t('Configures recommendation algorithms on specific pages of your site')}
        </Typography>
        <Box marginTop={5} marginBottom={5}>
          <Divider>
            <Chip label={t('GENERAL SETTINGS')} />
          </Divider>
        </Box>
        <Grid container spacing={2} direction='row'>
          <Grid item sm={6}>
            <AdvancedOptionCard
              title={t('Global settings')}
              src='../../img/settings/card_vs.png'
              desc={t('Visual Search control and label selection')}
              css={false}
            />
          </Grid>
          <Grid item sm={6}>
            <AdvancedOptionCard
              title={t('CSS setting')}
              src='../../img/settings/card_css.png'
              desc={t('Settings for custom CSS')}
              css={true}
            />
          </Grid>
        </Grid>
        <Box marginTop={5} marginBottom={5}>
          <Divider>
            <Chip label={t('RECOMMENDATION SETTINGS')} />
          </Divider>
        </Box>
        <Grid container spacing={2} direction='row'>
          <Grid item sm={6}>
            <WebsiteConfigurationCard 
              name={t('Home Page')} 
              route='home' 
              src='../../img/settings/card_home.png'
            />
          </Grid>
          <Grid item sm={6}>
            <WebsiteConfigurationCard 
              name={t('Product Detail Page')} 
              route='product' 
              src='../../img/settings/card_product.png'
            />
          </Grid>
          <Grid item sm={6}>
            <WebsiteConfigurationCard 
              name={t('Shopping Cart Page')} 
              route='cart' 
              src='../../img/settings/card_bag.png'
            />
          </Grid>
          <Grid item sm={6}>
            <WebsiteConfigurationCard 
              name={t('Thank You Page')} 
              route='order'
              src='../../img/settings/card_order.png' 
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WebsiteConfigurationMenu