export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const topProducts = (prod, limit) => {
  prod.map((p, index) => {
    p.rank = (p.count * p.price);
  })

  return prod.sort((a, b) => b.rank - a.rank).slice(0, limit);
}

export const checkModelsStatus = (progress, website) => {
  let check = false

  if (progress === null || website === null) {
    return check
  }

  if (progress['cfcb']['completition'] === 1) {
    check = true
  }

  if (progress['irvs']['vs']['completition'] < 1) {
      check = false
  }

  return check
}

// export const convertTZ = (date, tzString) => {
//   return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString})).toISOString();   
// }

export const convertTZ = (date) => {
  var b = date.split(/\D+/);
  var utcDate =  new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  var tzoffset = utcDate.getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(utcDate - tzoffset)).toISOString().slice(0, -1);
}