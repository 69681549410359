import styled from '@emotion/styled'
import { QuestionMarkRounded } from '@mui/icons-material';
import { Alert, Button, FormControl, Grid, Paper, Snackbar, Stack, Typography, Tooltip, IconButton } from '@mui/material'
import React, { useState } from 'react'
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsite } from '../../utilis/api';

import { useTranslation, Trans } from 'react-i18next';

const Item = styled(Grid)({
    margin: '25px'
})

const WebsiteCssConfigurationForm = ({ code }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [Css, setCss] = useState(code);
    const [creation, setCreation] = useState(false);

    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => state.user);
    const { currentWebsite } = useSelector((state) => state.website);

    const handleChange = (value) => {
        setCss(value);
    }

    const handleSubmit = () => {
        const data = JSON.parse(currentWebsite.settings);

        data['css'] = Css;

        let copyWeb = currentWebsite;

        copyWeb = {
            ...copyWeb,
            settings: JSON.stringify(data)
        }

        updateWebsite(currentWebsite.id, copyWeb, currentUser, dispatch)
            .then((res) => {
                setCreation(true);
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCreation(false);
    };

    return (
        <>
            <Paper elevation={3}>
                <Stack direction='column' >
                    <Item>
                        <Grid container>
                            <Grid item sm={3} xs={12}>
                                <Typography align='left' variant='h6' marginTop="3px">
                                    {t('Custom CSS')}
                                    <Tooltip title={t('You can insert custom CSS rules that will be injected into your website to customize the look and feel')}>
                                        <IconButton>
                                            <QuestionMarkRounded sx={{ marginBottom: '5px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <FormControl fullWidth>
                                    <CodeMirror
                                        value={Css}
                                        height="250px"
                                        extensions={[css()]}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Item>

                    <Item container>
                        <Grid item sm={6} xs={12}>
                            <Button align='right' onClick={handleSubmit}>
                                {t('save configuration')}
                            </Button>
                        </Grid>

                    </Item>
                </Stack>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={creation}
                autoHideDuration={5000}
                onClose={handleClose}
                message={t('Configuration saved')}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('Configuration saved')}
                </Alert>
            </Snackbar>
        </>
    )
}

export default WebsiteCssConfigurationForm