export const dataInteractionsChart = (data, labels) => {
    return {
        labels: [labels.views, labels.carts, labels.purchases],
        datasets: [
            {
                label: labels.aiinteractions,
                data: [
                    data.interactions.views, 
                    data.interactions.carts, 
                    data.interactions.purchases
                ],
                backgroundColor: [
                    'rgba(0, 78, 168, 1)',
                    'rgba(76, 159, 200, 1)',
                    'rgba(120, 190, 32, 1)',
                ],
                borderColor: [
                    'rgba(0, 78, 168, 1)',
                    'rgba(76, 159, 200, 1)',
                    'rgba(120, 190, 32, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
};