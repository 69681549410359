import styled from '@emotion/styled'
import { QuestionMarkRounded, Save } from '@mui/icons-material'
import { Alert, Button, Grid, IconButton, InputAdornment } from '@mui/material'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { updateWebsite } from '../../utilis/api'

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
    // margin: "24px 0px 16px",
    // width: isMobile ? "50%" : "25%",
    // left: isMobile ? "50%" : "75%",
    backgroundColor: '#78BE20',
    '&:hover': {
        backgroundColor: '#004EA8',
    }
}));

const isValidURL = (urlString) => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}

const SettingsWebsiteForm = () => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentWebsite } = useSelector((state) => state.website);
    const { currentUser } = useSelector((state) => state.user);
    const [ urlError, setUrlError ] = useState(false)
    const [ success, setSuccess] = useState(false)

    const dispatch = useDispatch();

    const onSubmitHook = (values, { setSubmitting }) => {
        setSubmitting(true);
        setUrlError(false)
        setSuccess(false)

        // Per non creare confusione fra form e dati sito correnti
        const data = {
            name: values.websiteName,
            users_url: values.usersFile,
            items_url: values.itemsFile,
            interactions_url: values.interactionsFile,
            public_token: values.publicToken,
            private_token: values.privateToken
        }

        if (isValidURL(values.interactionsFile) && isValidURL(values.usersFile) && isValidURL(values.itemsFile)) {
            console.log('VALIDO')
            updateWebsite(currentWebsite.id, data, currentUser, dispatch)
            .then((res) => {
                setSuccess(true)
            })
        } else {
            console.log('NON VALIDO')
            setUrlError(true)
        }

        setSubmitting(false);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                websiteName: currentWebsite.name,
                usersFile: currentWebsite.users_url,
                itemsFile: currentWebsite.items_url,
                interactionsFile: currentWebsite.interactions_url,
                publicToken: currentWebsite.public_token,
                privateToken: currentWebsite.private_token
            }}
            onSubmit={onSubmitHook}

        >
            {({ values, errors, touched }) => (
                <Form style={{
                    width: '100%',
                    //maxWidth: maxWidth,
                    // margin: '24px 8px 0px 8px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="websiteName"
                                component={TextField}
                                variant="outlined"
                                disabled={true}
                                fullWidth
                                id="websiteName"
                                label={t('Website name')}
                                autoFocus
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="itemsFile"
                                component={TextField}
                                variant="outlined"
                                disabled={currentWebsite.type !== 'custom'}
                                fullWidth
                                id="itemsFile"
                                label={t('Items file url')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="usersFile"
                                component={TextField}
                                variant="outlined"
                                disabled={currentWebsite.type !== 'custom'}
                                fullWidth
                                id="usersFile"
                                label={t('Users file url')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="interactionsFile"
                                component={TextField}
                                variant="outlined"
                                disabled={currentWebsite.type !== 'custom'}
                                fullWidth
                                id="interactionsFile"
                                label={t('Interactions file url')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="publicToken"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                required
                                disabled={true}
                                id="publicToken"
                                label={t('Public Token')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="privateToken"
                                component={TextField}
                                variant="outlined"
                                required
                                disabled={true}
                                fullWidth
                                id="privateToken"
                                label={t('Private Token')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton aria-label={t('Information')}>
                                                <QuestionMarkRounded />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Submit
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={currentWebsite.type !== 'custom'}
                                color="primary"
                                style={{
                                    background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
                                }}
                                startIcon={<Save />}
                            >
                                {t('Save new settings')}
                            </Submit>
                        </Grid>

                    </Grid>
                    <Grid container justifyContent="center" paddingTop={3}>
                        <Grid item>
                            <div style={{ visibility: urlError ? 'visible' : 'hidden', color: 'red', paddingTop: 10 }}>
                                <Alert severity="error">
                                    {t('Invalid URL')}
                                </Alert>
                            </div>
                            <div style={{ visibility: success ? 'visible' : 'hidden', color: 'green', paddingTop: 10 }}>
                                <Alert severity="success">
                                    {t('settings changed correctly!')}
                                </Alert>
                            </div>
                        </Grid>
                    </Grid>

                </Form>
            )}
        </Formik>
    )
}

export default SettingsWebsiteForm