import { Box, Button, Card, CardActions, CardContent, CircularProgress, Divider, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LinearBuffer from './LinearBuffer';
import { isMobile } from 'react-device-detect';
import { getInitProgress } from "../../utilis/api";
import LinearBufferIrVs from './LinearBufferIrVs';
import StepperLoad from './Stepper';
import RandomQuote from './RandomQuote';

const NewWebsiteLoading = ({ progress }) => {
    const { t, i18n } = useTranslation(['translation']);
    const [startLoaders, setStartLoader] = useState(false)

    console.log(progress)

    useEffect(() => {
        const checkProgress = () => {
            var sum = 0.0

            sum += progress['cfcb']['completition']

            for (let key in progress['irvs']) {
                sum += progress['irvs'][key]['completition']
            }

            if (sum > 0.0) {
                setStartLoader(true)
            }
        }

        if (progress) {
            checkProgress()
        }
    }, [progress])

    return (
        <Box flex={4} p={2} textAlign="center" paddingTop={5}>
            {
                progress && startLoaders ?

                    <Box flex={4} p={1} textAlign="center" sx={{paddingTop:{xs: '0', md: '24px'}}}>
                        <Box sx={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='h3' sx={{ paddingTop:{xs: '0', md: '20px'} }}>
                                {t('Artificial intelligence is training on your website')}!
                            </Typography>
                            <Box sx={{ paddingLeft: isMobile ? 1 : 5 }}><img src="/img/gif/data.gif" alt="loading data..." /></Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft:{xs: '0', md: '10%', lg: '20%'}, paddingRight:{xs: '0', md: '10%', lg: '20%'}, paddingTop:'1%' }}>
                            <Typography variant={isMobile ? 'body' : 'h6'} textAlign={"left"}>Visual Search training</Typography>
                            <LinearBufferIrVs color_prog="#1D252DCC" prog={progress['irvs']} />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft:{xs: '0', md: '10%', lg: '20%'}, paddingRight:{xs: '0', md: '10%', lg: '20%'}, paddingTop:'3%' }}>
                            <Typography variant={isMobile ? 'body' : 'h6'} textAlign={"left"}>Collaborative Filtering training</Typography>
                            <LinearBuffer color_prog="#004EA8" prog={progress['cfcb']} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft:{xs: '0', md: '10%', lg: '20%'}, paddingRight:{xs: '0', md: '10%', lg: '20%'}, paddingTop:'5%' }}>
                            <Card sx={{ minWidth: 275 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <CardContent sx={{width:"50%"}}>
                                        <Typography variant="h6" component="div">
                                            Visual Search
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontSize: 15 }} color="text.secondary">
                                            Time to complete training on your e-commerce
                                        </Typography>
                                        <Typography align='justify' variant="body2">
                                            The visual search training time is closely linked to the number of products in the ecommerce and the average number of images characterising a product
                                            <br />
                                        </Typography>
                                    </CardContent>
                                    <CardContent sx={{width:"50%"}}>
                                        <Typography variant="h6" component="div">
                                            Collaborative Filtering
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontSize: 15 }} color="text.secondary">
                                            Time to complete training on your e-commerce
                                        </Typography>
                                        <Typography align="justify" variant="body2">
                                            The training time of collaborative filtering is related to the number of interactions (purchases, cart additions and views) and the number of periodically active users on the site.
                                            <br />
                                        </Typography>
                                    </CardContent>
                                </Box>
                                {/* <CardActions sx={{ justifyContent: 'right', textAlign: 'right' }}>
                                    <Button size="medium">Learn More</Button>
                                </CardActions> */}
                            </Card>
                        </Box>
                    </Box>
                    :
                    <></>
            }
            {
                progress && !startLoaders ?
                    <Box flex={4} p={1} textAlign="center" justifyContent="center" paddingTop={3}>
                        <Box sx={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='h4' sx={{ paddingTop: 5 }}>
                                We are looking for the machines and uploading the data!
                            </Typography>
                        </Box>
                        <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                            <Box ><img src="/img/gif/data.gif" alt="loading data..." /></Box>
                            <RandomQuote />
                            <Typography variant='h5' sx={{ paddingTop: 5 }}>
                                The process can take up to 30 minutes!
                            </Typography>
                        </Box>
                    </Box> : <></>
            }
        </Box>
        //   <Box flex={4} p={2} textAlign="center" paddingTop={15}>
        //   <Typography variant='h3'>
        //     {t('Artificial intelligence is training on your website!')}
        //   </Typography>
        //   <Box sx={{ display: 'flex', justifyContent: 'center' }} paddingTop='5%'>
        //     <CircularProgress size={145} />
        //   </Box>
        //   <Typography variant='h6' paddingTop={5}>
        //     {t('You will get your analytics tomorrow!')}
        //   </Typography>
        // </Box>
    )
}

export default NewWebsiteLoading