import { Box, Container, Grid, Pagination, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from './Header';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import ImageVsGallery from './ImageVSList';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { fetchVsImages } from '../utilis/api';
import MansoryVSImages from './MansoryVSImages';

const VsGallery = () => {
  const [page, setPage] = useState(1);
  const numberEl = 25
  const [imagesData, setImagesData] = useState(null)
  const [loading, setLoading] = useState(true)

  const { currentUser } = useSelector((state) => state.user);
  const { currentWebsite } = useSelector((state) => state.website);

  const { t, i18n } = useTranslation(['translation']);

  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0)
  };

  useEffect(() => {
    console.log(page)
    console.log(currentWebsite)
    const fetchImages = () => {
      fetchVsImages(currentUser, currentWebsite.id, numberEl, numberEl * (page - 1))
        .then((res) => {
          console.log(res.data)
          setImagesData(res.data);
        })
    }

    fetchImages();
    console.log('change loading')
    setLoading(false)
  }, [page])

  return (
    <Box flex={4} p={{ xs: 0 }}>
      <Header title={t('Visual Search images')} />
      <Container component="section" maxWidth="lg" sx={{ marginTop: "50px", marginBottom: "50px" }}>
        {
          !loading && imagesData !== null && imagesData.count > 0 ?
            // <ImageVsGallery images={imagesData.data} />
            <MansoryVSImages images={imagesData.data}/>
            :
            <></>
        }
        {
          !loading && imagesData !== null && imagesData.count > 0 ?
            <Grid container justifyContent="center">
              <Pagination
                count={imagesData == null ? 1 : Math.ceil(imagesData.count / numberEl)}
                page={page}
                size={isMobile ? 'medium' : 'large'}
                onChange={handleChange}
              />
            </Grid>
            :
            <></>
        }
        {
          !loading && imagesData !== null && imagesData.count === 0 ?
            // <Box>
            //   <Typography>NO IMAGES</Typography>
            // </Box>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '50vh' }}
            >
              <Typography align='left' variant='h4'>{t('No Visual Search images!')}</Typography>
            </Grid>
            :
            <></>
        }

      </Container>
    </Box>
  )
}

export default VsGallery