export const planEn = {
    standard: {
        title: "Standard",
        monthlyPrice: 19.99,
        yearlyPrice: 199,
        upTo: [
            "Visual Search & Discovery",
            "Visual Recommendations",
            "Product Recommendations",
            "Advanced dashboard",
            "Support by ticket",
            "1K items",
            "1M recommendations"
        ],
        productId: {
            monthly: "prod_Hmq0PrX7kJhaJs",
            yearly: "prod_LJtBWPhjbyWbK6"
        },
        priceId: {
            monthly: "price_1M2e5UGyRFo307OA7XZGQgSC",
            // monthly: "price_1HDGNkGyRFo307OA7UuF3sCW",
            yearly: "price_1M2e6eGyRFo307OAESqwvsqB"
        }
    },
    pro: {
        title: "Pro",
        monthlyPrice: 99.99,
        yearlyPrice: 999,
        upTo: [
            "Visual Search & Discovery",
            "Visual Recommendations",
            "Product Recommendations",
            "Advanced dashboard",
            "Support by ticket/email",
            "5K items",
            "5M recommendations"
        ],
        productId: {
            monthly: "prod_Hmq1sHv5cBL98f",
            yearly: "prod_LJtCBQMhoFmSuM"
        },
        priceId: {
            monthly: "price_1M2e7tGyRFo307OAVxrTr0Z2",
            yearly: "price_1M2eAsGyRFo307OA3ed6Y6ly"
        }
    },
    corporate: {
        title: "Corporate",
        monthlyPrice: 349.99,
        yearlyPrice: 3499,
        upTo: [
            "Visual Search & Discovery",
            "Visual Recommendations",
            "Product Recommendations",
            "Advanced dashboard",
            "Support by ticket/email",
            "20K items",
            "30M recommendations"
        ],
        productId: {
            "monthly": "prod_Hmq2xLMd9nu2D5",
            "yearly": "prod_LJtCHEakBiiRRw"
        },
        priceId: {
            "monthly": "price_1M2e94GyRFo307OARqaLEHsu",
            "yearly": "price_1M2e9mGyRFo307OA2zq4IMt5"
        }
    }
}
