import { CheckCircle } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, List, ListItem, ListItemIcon, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next';

const Pricing = ({ plan, period }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} textAlign='center' color="text.secondary" gutterBottom>
                    {plan.title}
                </Typography>
                <Typography variant="h3" component="div" textAlign='center' color='red'>
                    {period ? plan.monthlyPrice : plan.yearlyPrice} €
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" textAlign='center'>
                    /{period ? t('month') : t('year')} + {t('vat')}
                </Typography>
                <List>
                    {
                        plan.upTo.map((feat) => (
                            <ListItem key={feat}>
                                <ListItemIcon>
                                    <CheckCircle color='success' />
                                </ListItemIcon>
                                <Typography sx={{ fontSize: 17 }} >
                                    {feat}
                                </Typography>
                            </ListItem>
                        ))
                    }

                </List>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Link to='/checkout'
                    style={{ 
                        width: '100%',
                        textDecoration: "none", 
                        color: "inherit" 
                    }}
                    state={{
                        title: plan.title,
                        priceId: period ? plan.priceId.monthly : plan.priceId.yearly,
                        planPrice: period ? plan.monthlyPrice : plan.yearlyPrice
                    }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            background: "linear-gradient(45deg, #C10016 30%, #004EA8 90%)",
                        }}
                    >
                        {t('BUY PLAN')}
                    </Button>
                </Link>
            </CardActions>
        </Card>
    )
}

export default Pricing