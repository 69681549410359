import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';

const Container = styled("div")({
    height: 168,
    width: '100%',
    position: 'relative',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80)',
})

const HeadText = styled(Typography)({
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    padding: "48px 0px 0px 48px",
})

const HeadIcon = styled(SvgIcon)({
    color: 'white',
    height: '150px',
    width: '150px',
    position: 'absolute',
    right: "48px",
    top: "8px"
})

const Header = ({ title, IconComponent }) => {

    return(
        !isMobile ? <Container>
            <HeadText 
                component="h6" 
                variant="h2"

                //className={classes.text}
            >
                {/*props.title*/}
                {title}
            </HeadText>
        
            <HeadIcon 
                component={IconComponent} 
                viewBox="0 0 100 100" 
            />
        </Container> : <></>
    )
}

export default Header
