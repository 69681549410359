import { createSvgIcon } from "@mui/material"


const PremiumIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M34.53,10.2a3.29,3.29,0,0,0,.59-1.64,3.43,3.43,0,0,0-.81-2.49h0A3.43,3.43,0,1,0,33,11.46a15.53,15.53,0,1,1-10.52-6.8,1,1,0,0,0,.31-2A17.57,17.57,0,1,0,34.53,10.2Zm-3.89-1a1.43,1.43,0,0,1,.16-2,1.41,1.41,0,0,1,.92-.34h.11a1.35,1.35,0,0,1,1,.49h0a1.37,1.37,0,0,1,.34,1,1.45,1.45,0,0,1-.49,1A1.42,1.42,0,0,1,30.64,9.22Z"/>
        <path d="M20,8.91a2.39,2.39,0,0,0-2.17,1.35L16,14.12a.48.48,0,0,1-.32.23L11.39,15a2.42,2.42,0,0,0-1.34,4.13l3.08,3a.4.4,0,0,1,.12.37l-.72,4.25A2.41,2.41,0,0,0,16,29.25l3.81-2a.44.44,0,0,1,.39,0l3.81,2a2.31,2.31,0,0,0,1.13.28,2.41,2.41,0,0,0,2.38-2.82l-.73-4.25a.46.46,0,0,1,.12-.37l3.09-3A2.42,2.42,0,0,0,28.69,15l-4.26-.61a.45.45,0,0,1-.32-.23l-1.9-3.86A2.39,2.39,0,0,0,20,8.91Zm4.1,7.41,4.26.62a.43.43,0,0,1,.34.29.41.41,0,0,1-.11.43l-3.08,3a2.44,2.44,0,0,0-.7,2.14L25.58,27a.4.4,0,0,1-.16.41.39.39,0,0,1-.44,0l-3.82-2A2.44,2.44,0,0,0,20,25.2a2.52,2.52,0,0,0-1.13.28l-3.81,2a.41.41,0,0,1-.6-.44l.72-4.24a2.41,2.41,0,0,0-.69-2.14l-3.08-3a.39.39,0,0,1-.11-.43.41.41,0,0,1,.34-.29l4.26-.62A2.38,2.38,0,0,0,17.76,15l1.91-3.86a.39.39,0,0,1,.37-.23.39.39,0,0,1,.37.23L22.32,15A2.38,2.38,0,0,0,24.14,16.32Z"/>
        <path d="M17.55,20.12A2.49,2.49,0,1,0,20,17.63,2.5,2.5,0,0,0,17.55,20.12Zm3,0a.49.49,0,1,1-.49-.49A.49.49,0,0,1,20.53,20.12Z"/>
    </svg>,
    'PremiumIcon'
)

export default PremiumIcon