import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import App from './App';
import { persistor, store } from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store= {store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}><CircularProgress size={145} sx={{color: '#1D252D'}}/></Box>}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

