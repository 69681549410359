import { ArrowDropDown, ArrowDropUp, Close, Inbox, Publish, RemoveCircle } from '@mui/icons-material'
import { Chip, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getUserInformations, updateUserInformations } from '../../utilis/api'

import chart from '../../utilis/charts.json'

const getDisabledElement = (activeCharts) => {
    let activeIds = []
    let disbaledCharts = []

    activeCharts.map((chart) => {
        activeIds.push(chart.id)
    })

    chart.map((c) => {
        if (!activeIds.includes(c.id)) {
            disbaledCharts.push(c)
        }
    })

    return disbaledCharts;
}

const ListKpiConf = ({ close }) => {
    const { t, i18n } = useTranslation(['translation']);

    const { currentUser } = useSelector((state) => state.user);

    const [items, setItems] = useState(JSON.parse(currentUser.dashboardSettings));
    const [DisabledItems, setDisabledItems] = useState(getDisabledElement(JSON.parse(currentUser.dashboardSettings)));
    //const [items, setItems] = useState([]);
    //const [DisabledItems, setDisabledItems] = useState([]);

    console.log(items)
    const dispatch = useDispatch();

    const updateUserSettings = (newData) => {
        const data = {
            dashboardSettings: JSON.stringify(newData)
        }

        updateUserInformations(data, currentUser)
            .then(() => {
                getUserInformations(currentUser, dispatch)
            })
    }

    const upInState = (index) => {
        let tmp = items;
        if (index > 0) {
            [tmp[index], tmp[index - 1]] = [tmp[index - 1], tmp[index]];
            setItems(tmp);
            updateUserSettings(tmp);
        }
    }

    const downInState = (index) => {
        let tmp = items;
        if (index < (tmp.length - 1)) {
            [tmp[index], tmp[index + 1]] = [tmp[index + 1], tmp[index]];
            setItems(tmp);
            updateUserSettings(tmp);
        }
    }

    const addInActive = (item, index) => {
        let tmp = items;
        let tmpDisabled = DisabledItems;

        tmp.push(item);
        tmpDisabled.splice(index, 1);

        setItems(tmp);
        setDisabledItems(tmpDisabled)

        updateUserSettings(tmp);
    }

    const addInDisabled = (item, index) => {
        let tmp = items;
        let tmpDisabled = DisabledItems;

        tmpDisabled.push(item);
        tmp.splice(index, 1);

        setItems(tmp);
        setDisabledItems(tmpDisabled)

        updateUserSettings(tmp);
    }

    return (
        <Box sx={{ width: isMobile ? 350 : 500 }}>
            <List>
                <ListItem sx={{ backgroundColor: 'white', margin: '0 0 5px 0' }}>
                    <ListItemText>
                        <Typography variant='h6'>
                            {t('Widgets configuration menu')}
                        </Typography>
                    </ListItemText>
                    <IconButton onClick={close}>
                        <Close />
                    </IconButton>
                </ListItem>
            </List>
            <Divider>
                <Chip label={t('ACTIVE WIDGETS')} />
            </Divider>
            <List>
                {
                    items.length > 0 ?
                        items.map((item, index) => (
                            <ListItem
                                key={item.name}
                                disablePadding
                                sx={{ backgroundColor: 'white', margin: '5px 0' }}
                            >
                                <Box sx={{ flexDirection: 'column' }}>
                                    <ListItemButton
                                        sx={{ "&:hover": { backgroundColor: "transparent" } }}
                                        disableRipple
                                        onClick={() => upInState(index)}
                                    >
                                        <ListItemIcon>
                                            <ArrowDropUp />
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemButton
                                        sx={{ "&:hover": { backgroundColor: "transparent" } }}
                                        disableRipple
                                        onClick={() => downInState(index)}
                                    >
                                        <ListItemIcon>
                                            <ArrowDropDown />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Box>
                                <Box sx={{ flexDirection: 'column' }}>
                                    <ListItemText>
                                        <Typography variant='overline'>
                                            {item.name}
                                        </Typography>
                                    </ListItemText>
                                    <ListItemText>
                                        <Typography variant='body2'>
                                            {item.category}
                                        </Typography>
                                    </ListItemText>
                                </Box>
                                <Box sx={{ display: 'block', marginRight: '0px', marginLeft: 'auto' }}>
                                    <ListItemButton
                                        sx={{ "&:hover": { backgroundColor: "transparent" } }}
                                        disableRipple
                                        onClick={() => addInDisabled(item, index)}
                                    >
                                        <ListItemIcon>
                                            <RemoveCircle />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Box>
                            </ListItem>
                        )) :
                        <></>
                }
            </List>
            <Divider>
                <Chip label={t('DISABLED WIDGETS')} />
            </Divider>
            <List>
                {DisabledItems.map((item, index) => (
                    <ListItem
                        key={item.name}
                        disablePadding
                        sx={{ backgroundColor: 'white', margin: '5px 0' }}
                    >
                        <Box sx={{ flexDirection: 'column' }}>
                            <ListItemButton
                                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                                disableRipple
                                onClick={() => addInActive(item, index)}
                            >
                                <ListItemIcon>
                                    <Publish />
                                </ListItemIcon>
                            </ListItemButton>
                        </Box>
                        <Box sx={{ flexDirection: 'column' }}>
                            <ListItemText>
                                <Typography variant='overline'>
                                    {item.name}
                                </Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant='body2'>
                                    {item.category}
                                </Typography>
                            </ListItemText>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default ListKpiConf