import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
   
  },
};

const ChartLine = ({ data }) => {
  return (
    <Box sx={{ width: '100%', height: '100%', padding: '15px 0px 15px 0px' }}>
      <Typography align='center' variant="h6">{data.datasets[0].label}</Typography>
      <Line options={options} data={data} />
    </Box>
  )
}

export default ChartLine;