import { Avatar, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next';

const WidgetRecType = ({ data }) => {
    const { t, i18n } = useTranslation(['translation']);

    return (
        <Grid
            container
            direction='row'
            justify="center"
            alignItems='center'
            minHeight='150px'
            spacing={2}
        >
            <Grid item xs={12} marginLeft='20px'>
                <Typography variant='h6'>
                    {t('Recommendations type')}
                </Typography>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(76, 159, 200)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                TO
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.total}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Recommendations
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(120, 190, 32)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                VS
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.visualsearch}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Visual search
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(250, 70, 22)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                VSM
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.similarity}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Similarity
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(236, 232, 26)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                IB
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.items}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Item based
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(236, 232, 26)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                CF
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.users}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Collaborative
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={4} lg={4} xl={2} xs={6} sm={6} marginBottom='35px'>
                <Box sx={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Avatar
                                sx={{
                                    backgroundColor: 'rgb(236, 232, 26)',
                                    height: 55,
                                    width: 55
                                }}
                            >
                                PI
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Box sx={{ flexDirection: 'column', paddingTop: '10%' }}>
                                <Typography>
                                    {data.pops}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Populars
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

        </Grid>
    )
}

export default WidgetRecType