import styled from '@emotion/styled'
import { Alert, Button, FormControlLabel, Grid, Snackbar } from '@mui/material'
import { Field, Formik, Form } from 'formik'
import { Switch, TextField } from 'formik-mui'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { register } from '../../utilis/api'
import { Link } from 'react-router-dom';
import PhoneInputField from '../inputs/PhoneInputField';

import { useTranslation, Trans } from 'react-i18next';

import sha512 from '../../utilis/sha512';

const Submit = styled(Button)(({ theme }) => ({
    margin: "24px 0px 16px",
    width: '50%',
    left: '50%',
}));

const RegistrationForm = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [errors, setErrors] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const dispatch = useDispatch();
    //const { error } = useSelector((state) => state.user);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setErrors(false);
        setInvalidPhone(false);
    };

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);

        if(validPhone(values.telephone)) {
            const data = {
                firstname: values.firstname,
                lastname: values.lastname,
                username: values.username,
                phone: values.telephone,
                password: await sha512(values.password),
            }

            setInvalidPhone(false)
    
            setErrors(await register(data, dispatch));
        } else {
            setInvalidPhone(true);
        }

        setSubmitting(false);
    }

    const validPhone = (number) => {
        if(typeof number === 'undefined')
            return false;
        //var urlPattern = new RegExp('/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im')
        //return !!urlPattern.test(number);
        return number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
    }

    return (
        <Formik
            initialValues={{ firstname: '', lastname: '', username: '', telephone: '',
                                password: '', acceptTerms: false, acceptPrivacy: false }}
            validate={values => {
                // Your client-side validation logic
            }}
            onSubmit={onSubmitHook}
        >
            {({ isSubmitting }) => (
                <Form style={{
                    width: '100%',
                    margin: '24px 0 0 0'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="firstname"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="firstname"
                                label={t('First Name')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="lastname"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="lastname"
                                label={t('Last Name')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="email"
                                name="username"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label={t('E-mail Address')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="text"
                                name="telephone"
                                component={PhoneInputField}
                                country={'us'}
                                className="form-control__phone"
                                variant="outlined"
                                required
                                fullWidth
                                id="telephone"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="password"
                                name="password"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="password"
                                label={t('Password')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        type="checkbox"
                                        name="acceptTerms"
                                        component={Switch}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="acceptTerms"
                                    />
                                }
                                label={<span>{t('Accept')} <Link to="//visidea.ai/terms-of-service/" target="_blank" style={{ color: "inherit" }}>{t('terms & conditions')}</Link></span>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        type="checkbox"
                                        name="acceptPrivacy"
                                        component={Switch}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="acceptPrivacy"
                                    />
                                }
                                label={<span>{t('Accept')} <Link to="//visidea.ai/privacy-policy/" target="_blank" style={{ color: "inherit" }}>{t('privacy policy')}</Link></span>}
                            />
                        </Grid>
                    </Grid>
                    <Submit
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            background: "linear-gradient(45deg, #78BE20 30%, #257226 90%)"
                        }}
                    >
                        {t('Register')}
                    </Submit>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={errors}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        message={t('This email is already used')}
                    >
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {t('This email is already used')}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={invalidPhone}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        message={t('Invalid phone number format')}
                    >
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {t('Invalid phone number format')}
                        </Alert>
                    </Snackbar>
                </Form>
            )}
        </Formik>
    )
}

export default RegistrationForm