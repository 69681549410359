import { Add, Money } from '@mui/icons-material'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { numberWithCommas } from '../../utilis/utils'

import { useTranslation, Trans } from 'react-i18next';

const Total = ({ period, data }) => {
    const { t, i18n } = useTranslation(['translation']);

    const percentageIncrease = () => {
        if(data.recommendify < 1) {
            return 0
        } else {
            return ((
                (data.total - (data.total - data.recommendify))
                /(data.total - data.recommendify)
            )*100).toFixed(2)
        }
    }

    return (
        <Card
            sx={{ height: '100%' }}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="secondary"
                            gutterBottom
                            variant="overline"
                        >
                            { period === 'week' ? t('WEEKLY REVENUES') : t('DAILY REVENUES') }
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h4"
                        >
                            {numberWithCommas(data.total.toFixed(0))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'rgb(76, 159, 200)',
                                height: 40,
                                width: 40
                            }}
                        >
                            <Money />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Add fontSize="small" color="success" />
                    <Typography
                        color="success"
                        sx={{
                            mr: 1
                        }}
                        variant="body2"
                    >
                        {percentageIncrease()}%
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                       {t('From visidea')}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default Total