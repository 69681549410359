import styled from '@emotion/styled'
import { Save } from '@mui/icons-material'
import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, Snackbar, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createMember, fetchWebsites } from '../../utilis/api'

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)(({ theme }) => ({
    background: "linear-gradient(45deg, #257226 30%, #ECE81A 90%)"
}));

/*
const getPermission = (data) => {
    let permission = []
    for (let [key, value] of Object.entries(data)) {
        if (value === true) {
            permission.push(1);
        } else {
            permission.push(0);
        }
    }

    return permission;
}
*/

const createData = (values, sites) => {
    let initialPermission = {}
    sites.map((site) => {
        initialPermission = {
            ...initialPermission,
            [site.name]: '0000'
        }
    })

    return {
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        acl: JSON.stringify(initialPermission),
        //acl: ''
    }
}

const TeamMemberForm = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [creation, setCreation] = useState(false);
    const [error, setError] = useState(false);
    const [sites, setSites] = useState([]);

    let navigate = useNavigate();

    const { currentUser } = useSelector((state) => state.user);

    const onSubmitHook = (values, { setSubmitting }) => {
        setSubmitting(true);

        const data = JSON.stringify(createData(values, sites));
        console.log(data);
        createMember(JSON.parse(data), currentUser)
            .then((res) => {
                if (res !== false) {
                    console.log(res);
                    navigate('/team/configuration/', {
                        state: {
                            data: res.data
                        }
                    })
                    setCreation(true)
                } else {
                    setError(true);
                    setCreation(true)
                }
            })
            
        setSubmitting(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCreation(false);
    };

    useEffect(() => {
        const fetchSites = () => {
            fetchWebsites(currentUser)
                .then((res) => {
                    setSites(res.data);
                })
        }

        fetchSites();
    }, [currentUser])

    return (
        <>
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    username: ''
                }}
                validate={values => {
                    // Your client-side validation logic
                }}
                onSubmit={onSubmitHook}
            >
                <Form style={{
                    width: '100%',
                    //maxWidth: maxWidth,
                    margin: '24px 8px 0px 8px'
                }}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="firstname"
                                required
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                id="firstname"
                                label={t('First Name')}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                type="text"
                                name="lastname"
                                required
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                id="lastname"
                                label={t('Last Name')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Field
                                type="email"
                                name="username"
                                required
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                id="username"
                                label={t('E-mail Address')}

                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Submit
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon={<Save />}
                            >
                                {t('CREATE MEMBER')}
                            </Submit>
                        </Grid>
                    </Grid>

                </Form>
            </Formik>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={creation}
                autoHideDuration={5000}
                onClose={handleClose}
                message={error ? t('Email already used') : t('Member created')}
            >
                {
                    error ?
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {t('Email already used')}
                        </Alert>
                        :
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            {t('Member created')}
                        </Alert>
                }
            </Snackbar>
        </>
    )
}

export default TeamMemberForm