import styled from '@emotion/styled';
import { Alert, Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { passwordReset } from '../../utilis/api';
import sha512 from '../../utilis/sha512';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

const Submit = styled(Button)({
    margin: "24px 0px 16px",
    width: '50%',
    left: '50%',
});

const ResetPasswordForm = () => {
    const { t, i18n } = useTranslation(['translation']);

    const [errors, setErrors] = useState({});
    const [blockSub, setBlockSub] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const username = searchParams.get("username");
    const token = searchParams.get("token");

    const onSubmitHook = async (values, { setSubmitting }) => {
        setSubmitting(true);

        if(values.pass === values.check) {
            const data = {
                username: username,
                token: token,
                password: await sha512(values.pass)
            }

            setErrors(await passwordReset(data));
        }

        setSubmitting(false);

        setTimeout(function () {
            window.location.replace('/');; 
         }, 3000);
         
    }

    return (
        <Formik
            initialValues={{ pass: '', check: '' }}
            validate={values => {
                // Your client-side validation logic
                if(values.pass === values.check && values.pass !== '' && values.pass !== ''){
                    setBlockSub(false);
                } else {
                    setBlockSub(true);
                }
            }}
            onSubmit={onSubmitHook}
        >
            {({ isSubmitting }) => (
                <Form style={{
                    width: '100%',
                    margin: '24px 0px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                            <Field
                                type="password"
                                name="pass"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="pass"
                                label={t('New password')}
                                autoFocus
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Field
                                type="password"
                                name="check"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                id="check"
                                label={t('Confirm new password')}
                            />
                        </Grid>
                    </Grid>
                    <Submit
                        type="submit"
                        fullWidth
                        disabled={blockSub}
                        variant="contained"
                        color="primary"
                        style={{
                            background: "linear-gradient(45deg, #78BE20 30%, #257226 90%)"
                        }}
                    >
                        Change password
                    </Submit>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <div style={{ visibility: errors.status === 'error' ? 'visible' : 'hidden', color: 'red' }}>
                                <Alert severity="error">
                                    {errors.status ? t('Password reset failed') : ""}
                                </Alert>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ visibility: errors.done ? 'visible' : 'hidden', color: 'green' }}>
                                <Alert severity="success">
                                    {errors.done ? t('Password reset done, redirect to login page') : ""}
                                </Alert>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm