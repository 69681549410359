import {
  Box,
  Card,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { useTranslation, Trans } from 'react-i18next';

const AlgoTable = ({ settings, visual }) => {
  const { t, i18n } = useTranslation(['translation']);

  let blocks = [];
  let labels = [];

  for (let [key, value] of Object.entries(settings)) {
    for (let index = 0; index < value.length; index++) {
      if(key !== 'css') {
        labels.push(key);
        blocks.push(value[index]);
      }
    }

  }

  return (
    <Card sx={{ overflowX: 'auto' }}>
      <CardHeader title={t('AI algorithm')} />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('Page')}
              </TableCell>
              <TableCell>
                {t('Algorithm')}
              </TableCell>

              <TableCell>
                {t('Status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover
              key={-1}
            >
              <TableCell>
                {t('header')}
              </TableCell>
              <TableCell>
                <b>{t('visual search')}</b>
              </TableCell>

              <TableCell>
                {
                  visual ?
                    <Chip label={t('active')} color="success" variant="outlined" />
                    :
                    <Chip label={t('paused')} color="error" variant="outlined" />
                }
              </TableCell>
            </TableRow>
            {blocks.map((block, index) => (
              <TableRow
                hover
                key={index}
              >
                <TableCell>
                  {labels[index]} {t('page')}
                </TableCell>
                <TableCell>
                  <b>{block.algo}</b>
                </TableCell>

                <TableCell>
                  {
                    block.show ?
                      <Chip label={t('active')} color="success" variant="outlined" />
                      :
                      <Chip label={t('paused')} color="error" variant="outlined" />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  )
}

export default AlgoTable