import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { SvgIcon } from '@mui/material';
import styled from '@emotion/styled';

import AccountIcon from './icons/account';
import ShopIcon from './icons/shop';
import SettingsIcon from './icons/settings';
import DashboardIcon from './icons/dashboard';

import { useTranslation, Trans } from 'react-i18next';

const TimeDot1 = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.allColors.orange
}));

const TimeDot2 = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.allColors.lightgreen
}));

const TimeDot3 = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.allColors.lightblue
}));

const TimeDot4 = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.allColors.blue
}));

const TimeLine = () => {
  const { t, i18n } = useTranslation(['translation']);

  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ 
            m: 'auto 0', 
            maxWidth: "5%", 
            paddingLeft: '0px', 
            paddingRight: '10px'
          }}
          align="right"
          variant="h6"
          
        >
          1.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimeDot1>
            <SvgIcon
              component={AccountIcon}
              viewBox="0 0 100 100"
              fontSize='large'
            />
          </TimeDot1>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0' }}>
          <Typography component="span">
            {t('Add your website in Visidea')}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
        >
          <Typography component="span">
            {t('Use our plugins in your preferred e-commerce platform')}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimeDot2>
            <SvgIcon
              component={ShopIcon}
              viewBox="0 0 100 100"
              fontSize='large'
            />
          </TimeDot2>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent 
          sx={{ 
            m: 'auto 0', 
            maxWidth: "5%", 
            paddingLeft: '0px', 
            paddingRight: '10px'
          }}
        >
          <Typography variant="h6">
            2.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ 
            m: 'auto 0', 
            maxWidth: "5%", 
            paddingLeft: '0px', 
            paddingRight: '10px'
          }}
          align="right"
          variant="h6"
        >
          3.
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimeDot3>
          <SvgIcon
              component={SettingsIcon}
              viewBox="0 0 100 100"
              fontSize='large'
            />
          </TimeDot3>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0' }}>
          <Typography component="span">
            {t('Setup your site with our tokens')}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"

        >
          <Typography component="span">
            {t('Use the dashboard to view your KPIs')}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimeDot4>
          <SvgIcon
              component={DashboardIcon}
              viewBox="0 0 100 100"
              fontSize='large'
            />
          </TimeDot4>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ 
            m: 'auto 0', 
            maxWidth: "5%", 
            paddingLeft: '0px', 
            paddingRight: '10px'
          }}>
          <Typography variant="h6" >
            4.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export default TimeLine