import { createSvgIcon } from "@mui/material"


const SettingsIcon = createSvgIcon(
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path d="M18.25,22.93a3.41,3.41,0,0,0,3.5-5.86,3.37,3.37,0,0,0-3.5,0,3.42,3.42,0,0,0,0,5.86Zm1-4.16a1.39,1.39,0,0,1,1.42,0,1.43,1.43,0,0,1,0,2.46,1.42,1.42,0,0,1-1.42,0,1.43,1.43,0,0,1,0-2.46Z"/>
        <path d="M14.23,28.3A1.52,1.52,0,0,0,15,30.05a11,11,0,0,0,4.25,1.14,1.52,1.52,0,0,0,1.56-1.12l.32-1.21a8.92,8.92,0,0,0,2.28-.6l.88.88a1.52,1.52,0,0,0,1.9.2,11.89,11.89,0,0,0,1.73-1.4,11.29,11.29,0,0,0,1.4-1.72,1.53,1.53,0,0,0-.2-1.91l-.88-.88a8.92,8.92,0,0,0,.6-2.28l1.21-.32a1.51,1.51,0,0,0,1.12-1.56A11,11,0,0,0,30.05,15a1.51,1.51,0,0,0-1.75-.79l-1.21.33a8.09,8.09,0,0,0-.77-.88,7.42,7.42,0,0,0-.89-.78l.32-1.21A1.51,1.51,0,0,0,25,9.94a11.1,11.1,0,0,0-4.26-1.13h-.1a1.52,1.52,0,0,0-1.45,1.13l-.33,1.21a8.77,8.77,0,0,0-2.26.6l-.88-.88a1.53,1.53,0,0,0-1.9-.2,11.38,11.38,0,0,0-1.73,1.4,10.91,10.91,0,0,0-1.4,1.73,1.52,1.52,0,0,0,.2,1.9l.88.88a8.77,8.77,0,0,0-.6,2.26l-1.21.33a1.49,1.49,0,0,0-1.12,1.55A11,11,0,0,0,9.94,25a1.5,1.5,0,0,0,1.75.79l1.21-.32a8.39,8.39,0,0,0,.78.9,8,8,0,0,0,.88.76Zm-.08-4.56a1,1,0,0,0-1.1-.42l-1.49.4A9.24,9.24,0,0,1,10.84,21l1.5-.4a1,1,0,0,0,.74-.92,6.74,6.74,0,0,1,.75-2.84,1,1,0,0,0-.18-1.17l-1.09-1.09a8.86,8.86,0,0,1,.92-1.08,8.05,8.05,0,0,1,1.08-.92l1.09,1.09a1,1,0,0,0,1.17.18,6.74,6.74,0,0,1,2.84-.75,1,1,0,0,0,.92-.74l.4-1.5a9.24,9.24,0,0,1,2.74.72l-.4,1.5a1,1,0,0,0,.42,1.1,6.74,6.74,0,0,1,1.17.93,7.06,7.06,0,0,1,.93,1.16,1,1,0,0,0,1.1.42l1.49-.4A8.91,8.91,0,0,1,29.16,19l-1.49.4a1,1,0,0,0-.74.92,6.92,6.92,0,0,1-.76,2.87,1,1,0,0,0,.18,1.16l1.09,1.09a7.48,7.48,0,0,1-.92,1.08,8.05,8.05,0,0,1-1.08.92l-1.09-1.09a1,1,0,0,0-1.16-.18,6.92,6.92,0,0,1-2.87.76,1,1,0,0,0-.92.74L19,29.16a8.83,8.83,0,0,1-2.73-.73l.4-1.49a1,1,0,0,0-.42-1.1,7.06,7.06,0,0,1-1.16-.93A7.3,7.3,0,0,1,14.15,23.74Z"/>
        <path d="M3.27,28.94a3.46,3.46,0,0,0,.41,2.59,3.41,3.41,0,0,0,4.7,1.11,3.33,3.33,0,0,0,1.53-2.11,3.4,3.4,0,0,0-2.53-4.11,3.08,3.08,0,0,0-.8-.1,3.56,3.56,0,0,0-.73.09,15.6,15.6,0,0,1,5-19A15.54,15.54,0,1,1,29.11,32.59a15.61,15.61,0,0,1-13.93,2.19,1,1,0,0,0-.62,1.9,17.6,17.6,0,0,0,15.73-2.47A17.55,17.55,0,0,0,22.72,2.68a18.71,18.71,0,0,0-2.78-.22A17.43,17.43,0,0,0,9.66,5.82,17.61,17.61,0,0,0,4.11,27.41,3.44,3.44,0,0,0,3.27,28.94Zm3.65-.58a1.35,1.35,0,0,1,.87.64A1.42,1.42,0,0,1,8,30.06a1.43,1.43,0,0,1-.64.88,1.38,1.38,0,0,1-.74.21,1.24,1.24,0,0,1-.33,0,1.4,1.4,0,0,1-.87-.63h0a1.41,1.41,0,0,1,1.53-2.12Z"/>
    </svg>,
    'SettingsIcon'
)

export default SettingsIcon