export const labelsIt = [
    "Animali & Accessori",
    "Abbigliamento & Accessori",
    "Arte & Intrattenimento",
    "Bambini & Lattanti",
    "Business & Industriali",
    "Fotocamere & Ottiche",
    "Elettronica",
    "Cibo, Bevande & Tabacco",
    "Arredamento",
    "Utensili",
    "Salute & Bellezza",
    "Casa & Giardino",
    "Gioielli",
    "Valigie",
    "Articoli per adulti",
    "Media",
    "Forniture per ufficio",
    "Religiosi & Cerimoniali",
    "Software",
    "Prodotti per lo sport",
    "Giochi",
    "Veicoli & Accessori"
]
